import {
  AddNewPageToChapterCommand,
  Command,
  CreateGuideWithTemplateCommand,
  DuplicatePageCommand,
  EditBackgroundColorInPageHeaderCommand,
  EditGuideAccessCommand,
  EditPageAccessCommand,
  EditPageHeaderTextColorCommand,
  MovePageCommand,
  PublishHomepageCommand,
  UnpublishGuideCommand,
  UnpublishPageCommand,
} from '../../types/commands';
import { EntityVisibility, GuideType } from '../../types';
import { LegacyModificationGroup } from '../permissions';

export const getCommand = (aggregateId: string, aggregateVersion: number) => ({
  publishHomepage: ({
    previousHomepageId,
  }: {
    previousHomepageId: string;
  }): PublishHomepageCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.PUBLISH_HOMEPAGE,
    payload: {
      from: previousHomepageId
        ? {
            guideId: previousHomepageId,
          }
        : {},
    },
  }),
  unpublishGuide: (): UnpublishGuideCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.UNPUBLISH_GUIDE,
  }),
  addNewPageToChapter: ({
    chapterId,
    pageId,
    pageTitle,
  }: {
    chapterId: string;
    pageId: string;
    pageTitle: string;
  }): AddNewPageToChapterCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.ADD_NEW_PAGE_TO_CHAPTER,
    payload: {
      chapterId,
      pageId,
      pageTitle,
    },
  }),

  duplicatePage: ({
    from,
    to,
    deletePage,
  }: {
    from: { chapterId: string; pageId: string };
    to: {
      guideId: string;
      guideVersion: number;
      chapterId: string;
      pageId: string;
    };
    deletePage: boolean;
  }): DuplicatePageCommand => ({
    aggregateId: to.guideId,
    aggregateVersion: to.guideVersion,
    command: Command.MOVE_PAGE_TO_GUIDE,
    payload: {
      deletePage,
      from: {
        guideId: aggregateId,
        guideVersion: aggregateVersion,
        chapterId: from.chapterId,
        pageId: from.pageId,
      },
      to: {
        chapterId: to.chapterId,
        pageId: to.pageId,
      },
    },
  }),
  movePageToIndex: (
    newIndex: number,
    pageId: string,
    fromChapterId: string,
    toChapterId: string,
  ): MovePageCommand => ({
    command: Command.MOVE_PAGE,
    aggregateId,
    aggregateVersion,
    payload: {
      pageId,
      from: { chapterId: fromChapterId },
      to: { chapterId: toChapterId, position: newIndex },
    },
  }),
  editPageAccess: ({
    chapterId,
    pageId,
    pageVisibility,
    editAccessChanges,
    viewAccessChanges,
  }: {
    chapterId: string;
    pageId: string;
    pageVisibility: EntityVisibility;
    viewAccessChanges: LegacyModificationGroup;
    editAccessChanges: LegacyModificationGroup;
  }): EditPageAccessCommand => ({
    command: Command.EDIT_PAGE_ACCESS,
    aggregateId,
    aggregateVersion,
    payload: {
      chapterId,
      pageId,
      viewAccessChanges,
      editAccessChanges,
      pageVisibility,
    },
  }),
  unpublishPage: ({
    pageId,
    chapterId,
  }: {
    pageId: string;
    chapterId: string;
  }): UnpublishPageCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.UNPUBLISH_PAGE,
    payload: {
      guideId: aggregateId,
      chapterId,
      pageId,
    },
  }),
  editBackgroundColorInPageHeader: ({
    chapterId,
    pageId,
    pageHeaderBackgroundColorId,
  }: {
    chapterId: string;
    pageId: string;
    pageHeaderBackgroundColorId: string | null;
  }): EditBackgroundColorInPageHeaderCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.EDIT_BACKGROUND_COLOR_IN_PAGE_HEADER,
    payload: {
      guideId: aggregateId,
      chapterId,
      pageId,
      pageHeaderBackgroundColorId,
    },
  }),
  editPageHeaderTextColor: ({
    chapterId,
    pageId,
    pageHeaderTextColorId,
  }: {
    chapterId: string;
    pageId: string;
    pageHeaderTextColorId: string | null;
  }): EditPageHeaderTextColorCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.EDIT_TEXT_COLOR_IN_PAGE_HEADER,
    payload: {
      guideId: aggregateId,
      chapterId,
      pageId,
      pageHeaderTextColorId,
    },
  }),
  editGuideAccess: ({
    viewAccessChanges,
    editAccessChanges,
    guideVisibility,
  }: {
    viewAccessChanges: LegacyModificationGroup;
    editAccessChanges: LegacyModificationGroup;
    guideVisibility: EntityVisibility;
  }): EditGuideAccessCommand => ({
    command: Command.EDIT_GUIDE_ACCESS,
    aggregateId,
    aggregateVersion,
    payload: {
      viewAccessChanges,
      editAccessChanges,
      guideVisibility,
    },
  }),
  createGuideWithTemplate: ({
    guideTitle,
    chapterId,
    chapterTitle,
    pageId,
    pageTitle,
    sectionId,
    guideType,
  }: {
    guideTitle: string;
    chapterId: string;
    chapterTitle: string;
    pageId: string;
    pageTitle: string;
    sectionId: string;
    guideType: GuideType;
  }): CreateGuideWithTemplateCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.CREATE_GUIDE_WITH_TEMPLATE,
    payload: {
      guideTitle,
      chapterId,
      chapterTitle,
      pageId,
      pageTitle,
      sectionId,
      guideType,
    },
  }),
});
