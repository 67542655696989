import { Router as CustomRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import ThemesetManager from './components/themesets/ThemesetManager';
import ThemeManager from './themes/ThemeManager';
import TypographiesManager from './themes/TypographiesManager';
import FontsManager from './themes/FontsManager';
import { LocalizationProvider } from '@bynder/localization';
import { fetchFallback, getLocale } from './helpers/i18n';
import { Notifications } from '@bynder/design-system';
import { AbilityContext } from './context/ability';
import { abilitiesRules } from './helpers/abilities';
import Router from './Router';
import ModalContainer from './components/modals/ModalContainer';
import { AssetDetailOverlay } from './components/pages/AssetDetailOverlay';
import { GlobalStyle } from './components/shared/styled-components/GlobalStyle';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { history, isDevelopment, queryClient } from './constants/app';
import ReactGA from 'react-ga4';
import { setAutoFreeze } from 'immer';
import { useAppStoreBase } from './stores/appStore';
import { CropperContainer } from '@bynder/react-image-cropper-lib';

if (!window.navigator.onLine) {
  useAppStoreBase.getState().setisOffline(true);
}

// To allow immer extensible object adding.
setAutoFreeze(false);

function App() {
  if (window.bynder?.guidelinesAnalyticsTrackingId) {
    const DEFAULT_CONFIG = {
      trackingId: window.bynder.guidelinesAnalyticsTrackingId,
    };

    ReactGA.initialize([DEFAULT_CONFIG]);
  }

  return (
    <CustomRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <ThemeManager>
          <ThemesetManager>
            <TypographiesManager>
              <FontsManager>
                <LocalizationProvider
                  locale={getLocale()}
                  fetchFallback={fetchFallback}
                  project="guidelines-frontend"
                >
                  <Notifications />
                  <>
                    <AbilityContext.Provider value={abilitiesRules}>
                      <Router />
                      {/* This is used for e2e tests to navigate back to overview.
                          TODO: Replace when we have proper breadcrumbs in place */}
                      <button
                        type="button"
                        style={{ display: 'none' }}
                        data-testid="backToOverviewButton"
                        onClick={() => history.push('/')}
                      >
                        back
                      </button>
                      <ModalContainer />
                      <CropperContainer />
                    </AbilityContext.Provider>

                    <AssetDetailOverlay />
                    <GlobalStyle />
                  </>
                </LocalizationProvider>
              </FontsManager>
            </TypographiesManager>
          </ThemesetManager>
        </ThemeManager>
        {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </CustomRouter>
  );
}

export default App;
