import { useQuery } from '@tanstack/react-query';
import { getCurrentHomepageApi } from '../helpers/api/portalApi';
import { isValidUUID } from '../helpers/uuid';

export const HOMEPAGE_KEYS = {
  HOMEPAGE: 'homepage',
};

export const useGetCurrentHomepage = () => {
  return useQuery([HOMEPAGE_KEYS.HOMEPAGE], () => getCurrentHomepageApi(), {
    cacheTime: 0,
    select: (data): string => {
      if (data?.data?.homepage) {
        const homepageId = data.data.homepage.split('/').filter(Boolean).pop();
        return homepageId && isValidUUID(homepageId) ? homepageId : '';
      }
      return '';
    },
  });
};
