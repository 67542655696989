export interface CurrentUserIdentity {
  id: string;
  name: string;
  full_name: string;
  avatar_url: string;
  email: string;
  date_created: string;
  preferred_language: string;
  public_user: 'True' | 'False';
  accounts: IdentityAccount[];
  groups: Identity[];
}
interface IdentityAccount {
  account_id: string;
  name: string;
  label: string;
  domain: string;
}

export interface Identity {
  id: string;
  full_name?: string;
  description?: string;
  name?: string;
  avatar_url?: string;
  email?: string;
  groups?: Identity[];
  identity: IdentityType;
}

export enum IdentityType {
  USER = 'USER',
  PROFILE = 'PROFILE',
  GROUP = 'GROUP',
}

export interface IdentityUserResponse {
  avatar_url: string;
  date_created: string;
  email: string;
  full_name: string;
  id: string;
  legacy_id: string;
  name: string;
  preferred_language: string;
  public_user: 'True' | 'False';
}

export interface IdentityGroupResponse {
  id: string;
  name: string;
}

export interface IdentityProfileResponse {
  id: string;
  is_default: boolean;
  name: string;
}
