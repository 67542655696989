import { findIndex } from 'lodash';
import { produce } from 'immer';
import { queryClient } from '../constants/app';
import { RawDraftContentState } from 'draft-js';
import {
  getOrderByFromLocalStorage,
  getOrderTypeFromLocalStorage,
} from '../helpers/browser';
import { GUIDE_KEYS, OVERVIEW_KEYS } from '../queries/guides';
import {
  ButtonWidget,
  CommandButtonWidget,
  CommandImageWidget,
  EntityVisibility,
  GuidesResponse,
  Guide,
  ImageWidget,
  Page,
  TextWidget,
  WIDGET_TYPE,
} from '../types';
import { ImageActions } from '../constants/widgets';
import { useSocketsStoreBase } from '../stores/socketsStore';
import { track } from '../helpers/analytics';
import { AxiosResponse } from 'axios';

export const updateGuideVersion = (guideId: string) => {
  queryClient.setQueryData(
    [
      OVERVIEW_KEYS.OVERVIEW_GUIDES,
      getOrderByFromLocalStorage(),
      getOrderTypeFromLocalStorage(),
    ],
    (old: GuidesResponse) => {
      if (old) {
        const guideIndex = findIndex(
          old?.allGuides,
          guide => guide.id === guideId,
        );

        return produce(old, draft => {
          draft.allGuides[guideIndex].version++;
        });
      }
    },
  );

  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.version++;
      });
    }

    return old;
  });
};

export const optimisticallyUpdateWidget = (
  guideId: string,
  pageId: string,
  sectionId: string,
  columnIndex: number,
  widgetId: string,
  widgetType: WIDGET_TYPE,
  payload: object,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );
      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      switch (widgetType) {
        case WIDGET_TYPE.TEXT: {
          const newWidget: TextWidget = {
            ...old.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ],
            text: payload as RawDraftContentState,
          };

          return produce(old, draft => {
            draft.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ] = newWidget;
          });
        }

        case WIDGET_TYPE.PERSONAL_FILE: {
          const widget =
            old.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ];
          const imageProps: CommandImageWidget = payload;

          const newWidget: ImageWidget = {
            id: widget.id,
            type: WIDGET_TYPE.IMAGE,
            assetExtension: imageProps.widgetAssetExtension,
            assetId: imageProps.widgetAssetId,
            assetName: imageProps.widgetAssetName,
            detailUrl: imageProps.widgetDetailUrl,
            imageUrl: imageProps.widgetImageUrl,
            imageAction: { type: ImageActions.NO_ACTION },
          };

          return produce(old, draft => {
            draft.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ] = newWidget;
          });
        }
        case WIDGET_TYPE.IMAGE: {
          const imageProps: CommandImageWidget = payload;
          const newWidget: ImageWidget = {
            ...old.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ],
            assetExtension: imageProps.widgetAssetExtension,
            assetId: imageProps.widgetAssetId,
            assetName: imageProps.widgetAssetName,
            detailUrl: imageProps.widgetDetailUrl,
            imageUrl: imageProps.widgetImageUrl,
          };

          return produce(old, draft => {
            draft.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ] = newWidget;
          });
        }

        case WIDGET_TYPE.BUTTON: {
          const buttonProps = payload as CommandButtonWidget;
          const newWidget: ButtonWidget = {
            ...old.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ],
            ...buttonProps,
          };
          return produce(old, draft => {
            draft.sections[sectionIndex].columns[columnIndex].widgets[
              widgetIndex
            ] = newWidget;
          });
        }

        default:
          break;
      }
    },
  );
};

export const handleMutationSuccess = (
  trackingMessage: string | { message: string; type: EntityVisibility } | null,
  responseData: AxiosResponse,
  entity: {
    entityId: string;
    queryPath?: string;
    message?: string;
    payload?: object;
  },
) => {
  const setChangingEntity = useSocketsStoreBase.getState().setChangingEntity;
  if (trackingMessage) {
    if (typeof trackingMessage === 'string') {
      track(trackingMessage);
    } else {
      track(trackingMessage.message, { type: trackingMessage.type });
    }
  }
  const correlationId = responseData.headers['x-api-correlation-id'];
  setChangingEntity(
    entity.entityId,
    entity?.message || null,
    correlationId,
    entity?.queryPath || null,
    entity?.payload || null,
  );
};
