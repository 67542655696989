import styled from 'styled-components';
import { Flex, token } from '@bynder/design-system';
import { VISUALS } from '../../../constants/styles';
import { getTypography } from './constants';
import { TypographyHeading } from '../../../types/typography';

export const StyledThemesetsContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: ${VISUALS.BYNDER_HEADER_HEIGHT} 0 0 0;
`;

export const StyledThemesetSection = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: ${token.spacing5} ${VISUALS.PADDING_XXXL};
`;

export const StyledThemesetContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const StyledContainer = styled(Flex)<{ $hasShadow?: boolean }>`
  height: ${VISUALS.THEMESET_HEADER_HEIGHT};
  padding: ${token.spacing6} ${token.spacing7};
  position: sticky;
  top: 0;
  z-index: ${VISUALS.ZINDEX_FLOATING_MENU};
  background: ${token.white};

  ${({ $hasShadow }) => $hasShadow && `box-shadow: ${token.elevation4};`}
`;

export const StyledThemesetTitle = styled.div`
  font-size: ${token.fontSize300};
  line-height: 25px;
`;

export const StyledThemesetSubtitle = styled.div`
  font-size: ${token.fontSize75};
  line-height: 20px;
  color: ${token.gray600};
`;

export const StyledThemesetList = styled.ul`
  flex: 1;
  margin: 0;
  list-style: none;
  max-width: 50rem;
  min-width: 224px;
`;

export const StyledThemesetListItem = styled.div`
  min-height: 40px;
  white-space: nowrap;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  cursor: pointer;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 125%;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTypographyExpandedContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${token.spacing5};
  margin-left: ${token.spacing5};
  margin-right: ${token.spacing5};
`;

export const StyledTypographyContentAction = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: ${token.spacing6} 0 ${token.spacing5} 0;

  > button {
    margin-left: ${token.spacing3};
  }
`;

export const StyledTypographyContentLabel = styled.p`
  line-height: 24px;
  font-size: ${token.fontSize100};
  color: ${token.gray500a};
`;

interface StyledTypographyContentValueProps {
  $notSet: boolean;
}
export const StyledTypographyContentValue = styled.p<StyledTypographyContentValueProps>`
  font-style: ${({ $notSet }) => ($notSet ? 'italic' : 'normal')};
  line-height: 24px;
  color: ${token.gray800a};
`;

interface StyledHeadingProps {
  $hasSize?: boolean;
}
interface StyledTextProps {
  $heading: TypographyHeading;
  $hasSize?: boolean;
}

export const StyledText = styled.div<StyledTextProps>`
  ${props => getTypography(props.$heading, props.theme, props.$hasSize)}
`;

// TODO reuse these for the text widget
export const StyledH1 = styled.h1<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.H1, props.theme, props.$hasSize, false)}
`;
export const StyledH2 = styled.h2<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.H2, props.theme, props.$hasSize, false)};
`;
export const StyledH3 = styled.h3<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.H3, props.theme, props.$hasSize, false)}
`;
export const StyledH4 = styled.h4<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.H4, props.theme, props.$hasSize, false)}
`;
export const StyledH5 = styled.h5<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.H5, props.theme, props.$hasSize, false)}
`;
export const StyledH6 = styled.h6<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.H6, props.theme, props.$hasSize, false)}
`;
export const StyledParagraph = styled.p<StyledHeadingProps>`
  ${props =>
    getTypography(TypographyHeading.NORMAL, props.theme, props.$hasSize, false)}
`;
