import {
  BG_APPCODE,
  GUIDELINES_PERMISSIONS,
  GuidelinesTiers,
  HOMEPAGE_APP_CODE,
  JWT_PERMISSIONS,
  PERMISSION_ENTITIES,
} from './permissions';
import { useCurrentUserIdentity, useDecodedToken } from '../queries/identity';
import { useState, useRef, useEffect, useContext } from 'react';
import { isDevelopment } from '../constants/app';
import { ActiveSplits, useReleaseToggle } from './releaseToggle';
import { AbilityContext } from '../context/ability';
import { DecodedAccessTokenPayload } from '@bynder/js-access-token-lib/dist/types';

export enum Tiers {
  BASIC = 'Basic',
  ADVANCED = 'Advanced',
  ENTERPRISE = 'Enterprise',
  FREE = 'Free',
}

export const useEllipsedTooltip = () => {
  const [hasEllipsedName, setHasEllipsedName] = useState(false);
  const tooltipRef = useRef<HTMLElement>();

  useEffect(() => {
    if (tooltipRef.current) {
      setHasEllipsedName(
        tooltipRef.current.scrollWidth > tooltipRef.current.offsetWidth,
      );
    }
  }, []);

  return { hasEllipsedName, tooltipRef };
};

export const useModuleTier = () => {
  const decodedToken = useDecodedToken();

  // TODO Checks against permissions will be deprecated in favor of app_codes
  const isEnterprise = decodedToken?.app_codes?.includes(
    GuidelinesTiers.ENTERPRISE,
  );
  const isAdvanced =
    !isEnterprise &&
    decodedToken?.app_codes?.includes(GuidelinesTiers.ADVANCED);

  const isBasic =
    !isEnterprise &&
    !isAdvanced &&
    decodedToken?.app_codes?.includes(GuidelinesTiers.BASIC);

  const tier = (() => {
    if (isEnterprise) return Tiers.ENTERPRISE;
    if (isAdvanced) return Tiers.ADVANCED;
    if (isBasic) return Tiers.BASIC;
    return Tiers.FREE;
  })();

  return { isBasic, isAdvanced, isEnterprise, tier };
};

export const useHasAllPermissions = (
  permissions: string[] | string,
): boolean => {
  const permissionCollection = Array.isArray(permissions)
    ? permissions
    : [permissions];

  const decodedToken = useDecodedToken();
  if (isDevelopment) return true;
  if (!decodedToken) return false;

  const { module_permissions } = decodedToken;
  return permissionCollection.every(permission =>
    module_permissions.includes(permission),
  );
};

export const useHasAnyPermission = (
  permissionCollection: string[],
): boolean => {
  const decodedToken = useDecodedToken();
  if (isDevelopment) return true;
  if (!decodedToken) return false;

  const { module_permissions } = decodedToken;
  return permissionCollection.some(permission =>
    module_permissions.includes(permission),
  );
};
export const usePortalHasModuleActive = () => {
  const decodedToken = useDecodedToken();
  if (isDevelopment) return true;
  if (!decodedToken) return false;

  const { app_codes } = decodedToken;
  return app_codes.includes(BG_APPCODE);
};

export const useHasHomepageAppCodeEnabled = () => {
  const decodedToken = useDecodedToken();
  if (isDevelopment) return true;
  if (!decodedToken) return false;

  const { app_codes } = decodedToken;
  return app_codes.includes(HOMEPAGE_APP_CODE);
};

export const useCanUseHomepage = () => {
  const hasHomepageAppCodeEnabled = useHasHomepageAppCodeEnabled();
  const { data: hasHomepageSplitEnabled } = useReleaseToggle(
    ActiveSplits.homepage,
  );
  const abilities = useContext(AbilityContext);

  // TODO: homepage permission can't be used yet as exisiting homepage users are not migrated
  // const isHomepageManage = useHasAllPermissions(HomepagePermissions.MANAGE);
  const isCXUCManage = abilities.can(
    GUIDELINES_PERMISSIONS.MANAGE,
    PERMISSION_ENTITIES.GUIDE,
  );
  // Split and app code are the same thing, so we can use either to determine if the user can use the homepage
  return (hasHomepageAppCodeEnabled || hasHomepageSplitEnabled) && isCXUCManage;
};

export enum UserType {
  PUBLIC = 'PUBLIC',
  OPEN_ASSET_BANK = 'OPEN_ASSET_BANK',
  LOGGED_IN = 'LOGGED_IN',
  UNKNOWN = 'UNKNOWN',
}

export enum CustomLinksPermissions {
  MANAGE = 'customlinks.link.manage',
  CREATE = 'customlinks.link.create',
  VIEW = 'customlinks.link.view',
}

export enum HomepagePermissions {
  MANAGE = 'homepage.page.manage',
  VIEW = 'homepage.page.view',
}

export const useUserType = (): {
  userType: UserType;
  isLoading: boolean;
} => {
  const { data: currentUser, isLoading, isError } = useCurrentUserIdentity();
  let userType = UserType.UNKNOWN;

  if (isError) userType = UserType.PUBLIC;

  if (!isLoading) {
    if (currentUser?.public_user === 'True')
      userType = UserType.OPEN_ASSET_BANK;
    else if (currentUser?.public_user === 'False')
      userType = UserType.LOGGED_IN;
  }

  return {
    userType,
    isLoading,
  };
};

export const isEnterprise = (token: DecodedAccessTokenPayload) => {
  return token.app_codes.includes(GuidelinesTiers.ENTERPRISE);
};

export const isAdvanced = (token: DecodedAccessTokenPayload) => {
  return (
    !isEnterprise(token) && token.app_codes.includes(GuidelinesTiers.ADVANCED)
  );
};

export const isBasic = (token: DecodedAccessTokenPayload) => {
  return (
    !isEnterprise(token) &&
    !isAdvanced(token) &&
    token.app_codes.includes(GuidelinesTiers.BASIC)
  );
};

export const isManage = (token: DecodedAccessTokenPayload) => {
  return token.module_permissions.includes(JWT_PERMISSIONS.GUIDELINES_MANAGE);
};

export const isView = (token: DecodedAccessTokenPayload) => {
  return token.module_permissions.includes(JWT_PERMISSIONS.GUIDELINES_VIEW);
};
export const isCreate = (token: DecodedAccessTokenPayload) => {
  return token.module_permissions.includes(JWT_PERMISSIONS.GUIDELINES_CREATE);
};
