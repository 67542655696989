import { create } from 'zustand';

interface GuideStore {
  activeGuideId: string | null;
  activePageId: string | null;
  activeChapterId: string | null;
  activeSectionId: string | null;
  activeGuideVersion: number | null;
  isEditMode: boolean;
  setActiveGuideId: (activeGuideId: string | null) => void;
  setActivePageId: (activePageId: string | null) => void;
  setActiveChapterId: (activeChapterId: string | null) => void;
  setActiveSectionId: (activeSectionId: string | null) => void;
  setActiveGuideVersion: (activeGuideVersion: number | null) => void;
  setIsEditMode: (isEditMode: boolean) => void;
}

export const useGuideStore = create<GuideStore>(set => ({
  activeGuideId: null,
  activePageId: null,
  activeChapterId: null,
  activeSectionId: null,
  activeGuideVersion: null,
  isEditMode: false,
  setActiveGuideId: activeGuideId => set(() => ({ activeGuideId })),
  setActivePageId: activePageId => set(() => ({ activePageId })),
  setActiveChapterId: activeChapterId => set(() => ({ activeChapterId })),
  setActiveSectionId: activeSectionId => set(() => ({ activeSectionId })),
  setIsEditMode: isEditMode => set(() => ({ isEditMode })),
  setActiveGuideVersion: (activeGuideVersion: number) =>
    set(() => ({ activeGuideVersion })),
}));
