import styled, { css } from 'styled-components';
import { token } from '@bynder/design-system';
import { COLOR, VISUALS } from '../../../constants/styles';

interface Props {
  $offsetY?: string;
  $offsetX?: string;
  $dark?: boolean;
  $isVisible?: boolean;
}

export default styled.div<Props>`
  display: ${({ $isVisible = true }) => (!$isVisible ? 'none' : 'flex')};
  position: absolute;
  z-index: ${VISUALS.ZINDEX_FLOATING_MENU};
  border-radius: ${token.radiusMedium};
  background-color: ${({ $dark }) => ($dark ? token.gray900a : token.white)};
  align-items: center;

  ${({ $dark }) =>
    !$dark &&
    css`
      box-shadow: ${COLOR.SHADOW};
    `}

  transform: ${({ $offsetY, $offsetX }) =>
    css`
      translateY(${$offsetY || '-50%'}) translateX(${$offsetX || '0'})
    `};
`;
