import React from 'react';
import { useLayer } from 'react-laag';
import { useTranslate } from '@bynder/localization';
import StyledColorPicker, { ColorDot } from '../../shared/ColorPicker';
import { Color } from '../../../types/color';
import { useGetThemesetColors } from '../../../queries/themesets';

interface Props {
  expanded: boolean;
  onExpandEvent: () => void;
  onChange: (property: string, hexCode: string) => void;
  currentState: { color: string };
}

const ColorPic: React.FC<Props> = ({
  expanded,
  onExpandEvent,
  onChange,
  currentState,
}) => {
  const { translate } = useTranslate();
  const customColors = useGetThemesetColors();

  const onChangeColor = (color: Color) => {
    onChange('color', color.hexCode);
  };

  const onSelectDefault = () => {
    onChange('color', currentState.color);
  };

  const activeColor = [...customColors].find(
    color => color.hexCode === currentState.color,
  );

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: expanded,
    placement: 'bottom-start',
    auto: true,
    onOutsideClick: onExpandEvent,
    triggerOffset: 4,
  });

  return (
    <div className="rdw-inline-wrapper">
      <div className="rdw-option-wrapper" {...triggerProps}>
        {activeColor ? (
          <ColorDot
            onClick={onExpandEvent}
            label={translate('RICH_TEXT_COLOR_PICKER.IMG_ALT')}
            color={activeColor}
          />
        ) : (
          <ColorDot
            isDefault
            label={translate('RICH_TEXT_COLOR_PICKER.IMG_ALT')}
            onClick={onExpandEvent}
          />
        )}
      </div>

      {expanded &&
        renderLayer(
          <StyledColorPicker
            data-testid="color-picker"
            $isVisible={expanded}
            $offsetY="10px"
            {...layerProps}
          >
            <ColorDot
              isDefault
              label="Default"
              isActive={String(currentState.color) === 'undefined'}
              onClick={onSelectDefault}
            />

            {[...customColors].map(color => {
              return (
                <ColorDot
                  key={color.id}
                  color={color}
                  isActive={currentState.color === color.hexCode}
                  onClick={() => onChangeColor(color)}
                />
              );
            })}
          </StyledColorPicker>,
        )}
    </div>
  );
};

export default ColorPic;
