import { useQuery } from '@tanstack/react-query';
import { retrieveFeatureTreatment } from './api/featureTreatmentApi';
import { UserType, useUserType } from './hooks';

export enum ActiveSplits {
  homepage = 'portal_new_homepage_rollout',
  q4Features = 'brandguidelines_q4_features',
  pageInPage = 'brandguidelines_page_in_page_poc',
  themedTemplates = 'brandguidelines_themed_templates',
  guidelinesQueryServiceRollout = 'brandguidelines_query_service_rollout',
  imageCropperRollout = 'brandguidelines_image_cropper_rollout',
  teaWidget = 'contentworkflow_cxuc_tea_widget',
}

export const useReleaseToggle = (splitName: ActiveSplits) => {
  const { userType } = useUserType();
  const isPublic =
    userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;

  return useQuery(
    [splitName, isPublic],
    () => retrieveFeatureTreatment(splitName, isPublic),
    {
      select: data => data === 'on',
    },
  );
};
