import { create } from 'zustand';
import { createSelectors } from './helpers';

interface ChangingEntity {
  entityId: string | null;
  message?: string | null;
  correlationId: string | null;
  queryPath?: string | null;
  payload?: object | null;
}
interface PageStore {
  changingEntities: ChangingEntity[];
  removeChangingEntity: (correlationId: string) => void;
  setChangingEntity: (
    entityId: string | null,
    message: string | null,
    correlationId: string | null,
    queryPath: string | null,
    payload?: object | null,
  ) => void;
  clearAllChangingEntities: () => void;
}

export const useSocketsStoreBase = create<PageStore>(set => ({
  changingEntities: [],
  removeChangingEntity: correlationId =>
    set(state => ({
      changingEntities: state.changingEntities.filter(
        entity => entity.correlationId !== correlationId,
      ),
    })),
  clearAllChangingEntities: () => set({ changingEntities: [] }),
  setChangingEntity(entityId, message, correlationId, queryPath, payload) {
    set(state => ({
      changingEntities: [
        ...state.changingEntities,
        {
          entityId,
          message,
          correlationId,
          queryPath,
          payload,
        },
      ],
    }));
  },
}));

export const useIsEntityChanging = (entityId: string | null) => {
  const changingEntities = useSocketsStoreBase(state => state.changingEntities);
  if (!entityId) return false;
  return changingEntities.some(entity => entity.entityId === entityId);
};

export const getChangingEntity = (entityId: string) => {
  const changingEntities = useSocketsStoreBase.getState().changingEntities;
  return changingEntities.find(entity => entity.entityId === entityId);
};

export const useSocketsStore = createSelectors(useSocketsStoreBase);
