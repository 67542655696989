import React from 'react';
import {
  IconUserGroup,
  IconLock,
  IconPublic,
  IconBuilding,
} from '@bynder/icons';
import { Dropdown, List, Thumbnail } from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { EntityVisibility, PermissionDocumentType } from '../../types';
import { useEntityPermission } from '../../queries/permissions';
import { getPermissionIcon } from '../shared/Permissions';

export const AccessModalDropdown: React.FC<{
  activeGuideId: string | undefined;
  entityVisibility: EntityVisibility;
  isListEmpty: boolean;
  handleChangeVisibility: (newVisibilty: EntityVisibility) => void;
  documentType?: PermissionDocumentType;
}> = ({
  activeGuideId,
  handleChangeVisibility,
  isListEmpty,
  entityVisibility,
  documentType,
}) => {
  const { data: activeGuidePermissions } = useEntityPermission(activeGuideId);
  const isSpecificUsers = entityVisibility === EntityVisibility.SPECIFIC;
  const isAllPortalUsers = entityVisibility === EntityVisibility.PORTAL;
  const isPublic = entityVisibility === EntityVisibility.PUBLIC;
  const isPrivate =
    isListEmpty && entityVisibility === EntityVisibility.SPECIFIC;
  const [isPublicLimited, setIsPublicLimited] = React.useState(false);
  const [isPortalLimited, setIsPortalLimited] = React.useState(false);
  const { documentVisibility: activeGuideVisibility } =
    activeGuidePermissions || {};

  React.useEffect(() => {
    if (
      documentType === PermissionDocumentType.CHAPTER ||
      documentType === PermissionDocumentType.PAGE
    ) {
      if (activeGuideVisibility === EntityVisibility.SPECIFIC) {
        setIsPublicLimited(true);
        setIsPortalLimited(true);
      }

      if (activeGuideVisibility === EntityVisibility.PORTAL) {
        setIsPublicLimited(true);
      }
    }
  }, [activeGuideVisibility, documentType]);

  return (
    <List>
      <Dropdown
        trigger={({ isOpen, ...triggerProps }) => (
          <List.Item
            thumbnail={
              <Thumbnail
                variant="clean"
                icon={getPermissionIcon(entityVisibility, isPrivate)}
                shape="square"
                size="m"
              />
            }
            subtext={getVisibilitySubtext(
              entityVisibility,
              documentType,
              isPrivate,
            )}
            isSelected={isOpen}
            rightElements={<Dropdown.Arrow />}
            {...triggerProps}
          >
            {getVisibilityText(entityVisibility)}
          </List.Item>
        )}
        position="bottom"
      >
        <Dropdown.ListItem
          onClick={() => handleChangeVisibility(EntityVisibility.SPECIFIC)}
          isChecked={isSpecificUsers}
          role="menuitemradio"
          subtext={
            isListEmpty ? (
              <Translate id="ACCESS_MODAL_DROPDOWN.PRIVATE_STATE_DESCRIPTION_TEXT" />
            ) : (
              <Translate
                id="ACCESS_MODAL_DROPDOWN.SPECIFIC_STATE_DESCRIPTION_TEXT"
                document={documentType?.toLowerCase() || ''}
              />
            )
          }
          thumbnail={
            <Thumbnail
              variant="clean"
              icon={isListEmpty ? <IconLock /> : <IconUserGroup />}
              shape="square"
              size="m"
            />
          }
        >
          <Translate id="ACCESS_MODAL_DROPDOWN.RESTRICTED_OPTION" />
        </Dropdown.ListItem>

        <Dropdown.ListItem
          isDisabled={isPortalLimited}
          onClick={() => handleChangeVisibility(EntityVisibility.PORTAL)}
          isChecked={isAllPortalUsers}
          role="menuitemradio"
          subtext={
            isPortalLimited ? (
              <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_STATE_LIMITED_DESCRIPTION_TEXT" />
            ) : (
              <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_STATE_DESCRIPTION_TEXT" />
            )
          }
          thumbnail={
            <Thumbnail
              variant="clean"
              icon={<IconBuilding />}
              shape="square"
              size="m"
            />
          }
        >
          <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_USERS_OPTION" />
        </Dropdown.ListItem>

        {documentType !== PermissionDocumentType.GROUP && (
          <Dropdown.ListItem
            isDisabled={isPublicLimited}
            onClick={() => handleChangeVisibility(EntityVisibility.PUBLIC)}
            isChecked={isPublic}
            role="menuitemradio"
            subtext={
              isPublicLimited ? (
                <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_STATE_LIMITED_DESCRIPTION_TEXT" />
              ) : (
                <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_STATE_DESCRIPTION_TEXT" />
              )
            }
            thumbnail={
              <Thumbnail
                variant="clean"
                icon={<IconPublic />}
                shape="square"
                size="m"
              />
            }
          >
            <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_OPTION" />
          </Dropdown.ListItem>
        )}
      </Dropdown>
    </List>
  );
};

const getVisibilityText = (entityVisibility: EntityVisibility) => {
  switch (entityVisibility) {
    case EntityVisibility.PORTAL:
      return <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_USERS_OPTION" />;
    case EntityVisibility.PUBLIC:
      return <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_OPTION" />;
    case EntityVisibility.SPECIFIC:
    default:
      return <Translate id="ACCESS_MODAL_DROPDOWN.RESTRICTED_OPTION" />;
  }
};

const getVisibilitySubtext = (
  entityVisibility: EntityVisibility,
  documentType: PermissionDocumentType | undefined,
  isPrivate: boolean,
) => {
  if (isPrivate && documentType) {
    return (
      <Translate
        id="ACCESS_MODAL_DROPDOWN.PRIVATE_STATE_DESCRIPTION_TEXT"
        document={documentType.toLowerCase()}
      />
    );
  }

  switch (entityVisibility) {
    case EntityVisibility.PORTAL:
      return (
        <Translate id="ACCESS_MODAL_DROPDOWN.PORTAL_STATE_DESCRIPTION_TEXT" />
      );
    case EntityVisibility.PUBLIC:
      return (
        <Translate id="ACCESS_MODAL_DROPDOWN.PUBLIC_STATE_DESCRIPTION_TEXT" />
      );
    case EntityVisibility.SPECIFIC:
    default:
      if (documentType) {
        return (
          <Translate
            id="ACCESS_MODAL_DROPDOWN.SPECIFIC_STATE_DESCRIPTION_TEXT"
            document={documentType.toLowerCase()}
          />
        );
      }
  }
};
