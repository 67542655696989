import React from 'react';
import Tooltip from 'rc-tooltip';
import { TooltipPlacement } from '../widgets/helpers';

interface Props {
  label: React.ReactElement | string;
  children: React.ReactElement;
  trigger?: Array<'hover' | 'click' | 'focus'>;
  align?: object;
  hideOnLeave?: boolean;
  placement?: TooltipPlacement;
  overlayClassName?: string;
  mouseEnterDelay?: number;
  hidden?: boolean;
}
const defaultOffset = { offset: [0, 4] };

const TooltipWrapper: React.FC<Props> = props => {
  const [visible, setVisible] = React.useState(false);

  const offset = props.align ? props.align : defaultOffset;
  const onLeave = (onMouseLeave?: () => void) => {
    if (props.hideOnLeave) {
      setVisible(false);
    }

    if (typeof onMouseLeave === 'function') {
      onMouseLeave();
    }
  };

  if (props.hidden) {
    return props.children;
  }

  return (
    <Tooltip
      prefixCls="tooltip"
      destroyTooltipOnHide
      placement={props.placement || 'top'}
      overlay={props.label}
      overlayClassName={props.overlayClassName || 'text-center'}
      align={offset}
      trigger={props.trigger || ['hover', 'click']}
      visible={visible}
      onVisibleChange={setVisible}
      mouseEnterDelay={props.mouseEnterDelay || 0.2}
    >
      {React.cloneElement(props.children, {
        onMouseLeave: () => onLeave(props.children.props.onMouseLeave),
      })}
    </Tooltip>
  );
};

export default TooltipWrapper;
