import { useMutation } from '@tanstack/react-query';
import { useGuideStore } from '../../stores/guideStore';
import { handleMutationSuccess, updateGuideVersion } from '../helpers';
import {
  WidgetAlignment,
  CardWidgetCard,
  CardWidget,
  Page,
  SectionBackground,
} from '../../types';
import {
  editStyleInCardWidgetApi,
  editCardLinkInCardWidgetApi,
  editCardHeadlineInCardWidgetApi,
  editCardAssetInCardWidgetApi,
  moveCardInCardWidgetApi,
  addCardToCardWidgetApi,
  removeCardFromCardWidgetApi,
  editWidgetStyleInCardWidgetApi,
} from '../../helpers/api/widgets/card';
import { useInspectorStore } from '../../stores/inspectorStore';
import { CONFIG, queryClient } from '../../constants/app';
import { GUIDE_KEYS, useActiveWidget } from '../../queries/guides';
import { findIndex, merge } from 'lodash';
import { produce } from 'immer';
import { retrieveAssetInfoApi } from '../../helpers/api/uploadApi';
import { useUploaderStore } from '../../stores/uploaderStore';
import { emptyCSSObject } from '../../constants/widgets';
import { generateUUID } from '../../helpers/utils';

export const useCardWidgetMutations = () => {
  const guideId = useGuideStore(s => s.activeGuideId) as string;
  const pageId = useGuideStore(s => s.activePageId) as string;
  const chapterId = useGuideStore(s => s.activeChapterId) as string;
  const guideVersion = useGuideStore(s => s.activeGuideVersion) as number;
  const sectionId = useGuideStore(s => s.activeSectionId) as string;
  const columnIndex = useInspectorStore(s => s.widgetSettings?.columnIndex) as number;
  const widgetId = useInspectorStore(s => s.widgetSettings?.widgetId) as string;
  const setActiveWidgetIndex = useInspectorStore(s => s.setWidgetSettings);
  const clearUploadingImage = useUploaderStore.use.clearUploadingImage();

  const activeWidget = useActiveWidget() as CardWidget;
  return {
    addCard: useMutation(
      ({ newCard }: { newCard: CardWidgetCard }) => {
        return addCardToCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          newCard,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);

          const lastIndex = activeWidget?.cards.length;
          setActiveWidgetIndex({ widgetIndex: lastIndex });
          optimisticallyAddCardToCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.newCard,
          );
        },
        onSuccess: response => {
          handleMutationSuccess('Card added to Card Widget', response, {
            entityId: guideId,
          });
        },
      },
    ),
    removeCard: useMutation(
      ({ cardId }: { cardId: string; cardIndex: number }) => {
        return removeCardFromCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          cardId,
        });
      },
      {
        onMutate: (variables: { cardIndex: number; cardId: string }) => {
          updateGuideVersion(guideId);
          optimisticallyRemoveCardFromCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.cardIndex,
          );
        },
        onSuccess: response => {
          handleMutationSuccess('Card removed from Card Widget', response, {
            entityId: guideId,
          });
        },
      },
    ),
    addAsset: useMutation(
      ({
        asset,
        cardId,
      }: {
        asset: SectionBackground;
        cardId: string;
        cardIndex: number;
      }) => {
        return editCardAssetInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          cardId,
          guideVersion,
          asset,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateCardInCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.cardIndex,
            { asset: variables.asset },
          );
        },
        onSuccess: response => {
          handleMutationSuccess('Card Asset edited in Card Widget', response, {
            entityId: guideId,
          });
        },
      },
    ),
    removeAsset: useMutation(
      ({ cardId }: { cardId: string; cardIndex: number }) => {
        const emptyAsset = {
          id: '',
          name: '',
          extension: '',
          imageUrl: '',
          detailUrl: '',
        };
        return editCardAssetInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          cardId,
          guideVersion,
          asset: emptyAsset,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateCardInCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.cardIndex,
            {
              asset: {
                id: '',
                name: '',
                extension: '',
                imageUrl: '',
                detailUrl: '',
              },
            },
          );
        },
        onSuccess: response => {
          handleMutationSuccess(
            'Asset removed from Card in Card Widget',
            response,
            {
              entityId: guideId,
            },
          );
        },
      },
    ),
    editHeadline: useMutation(
      ({
        title,
        description,
        cardId,
      }: {
        title: string;
        description: string;
        cardIndex: number;
        cardId: string;
      }) => {
        return editCardHeadlineInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          cardId,
          guideVersion,
          headline: { title, description },
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateCardInCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.cardIndex,
            {
              headline: {
                title: variables.title,
                description: variables.description,
              },
            },
          );
        },
        onSuccess: response => {
          handleMutationSuccess(
            'Headline edited in card from Card Widget',
            response,
            {
              entityId: guideId,
            },
          );
        },
      },
    ),
    rearrange: useMutation(
      ({ from, to, cardId }: { from: number; to: number; cardId: string }) => {
        return moveCardInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          cardId,
          guideVersion,
          from: { position: from },
          to: { position: to },
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyMoveCardInCardWidget({
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            from: variables.from,
            to: variables.to,
          });
        },
        onSuccess: response => {
          handleMutationSuccess('Cards rearranged in Card Widget', response, {
            entityId: guideId,
          });
        },
      },
    ),
    editLink: useMutation(
      ({
        url,
        openNewTab,
        cardId,
      }: {
        url: string;
        openNewTab: boolean;
        cardId: string;
        cardIndex: number;
      }) => {
        return editCardLinkInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          cardId,
          guideVersion,
          link: { url, openNewTab },
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateCardInCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.cardIndex,
            { link: { url: variables.url, openNewTab: variables.openNewTab } },
          );
        },
        onSuccess: response => {
          handleMutationSuccess('Cards link edited in Card Widget', response, {
            entityId: guideId,
          });
        },
      },
    ),
    uploadAssetInCard: useMutation(
      (options: { mediaId: string; cardIndex: number; cardId: string }) => {
        return retrieveAssetInfoApi(options.mediaId);
      },
      {
        retry: true,
        onSuccess: (data, variables) => {
          clearUploadingImage(variables.cardId);
          updateGuideVersion(guideId);
          optimisticallyUpdateCardInCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            variables.cardIndex,
            {
              asset: {
                assetId: data.id,
                assetName: data.name,
                detailUrl: `/media/?mediaId=${data.id}`,
                imageUrl: data.original,
              },
            },
          );
          editCardAssetInCardWidgetApi({
            guideId,
            chapterId,
            pageId,
            sectionId,
            widgetId,
            columnIndex,
            cardId: variables.cardId,
            guideVersion,
            asset: {
              id: data.id,
              name: data.name,
              detailUrl: `${CONFIG.ACCOUNT_BASE_ROUTE}/media/?mediaId=${data.id}`,
              imageUrl: data.original,
              extension: data.extension[0],
            },
          });
        },
      },
    ),
    editWidgetStyle: useMutation(
      ({ newStyle }: { newStyle: string }) => {
        return editWidgetStyleInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          stylesheet: newStyle,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            {
              style: {
                width: activeWidget.style.size,
                alignment: activeWidget.style.alignment,
                stylesheet: variables.newStyle,
              },
            },
          );
        },

        onSuccess: response => {
          handleMutationSuccess('Card Widget style edited', response, {
            entityId: guideId,
          });
        },
      },
    ),
    editStyle: useMutation(
      ({
        newSize,
        newAlignment,
      }: {
        newSize: number;
        newAlignment: WidgetAlignment;
      }) => {
        return editStyleInCardWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          style: { size: newSize, alignment: newAlignment },
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateCardWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            {
              style: {
                size: variables.newSize,
                alignment: variables.newAlignment,
              },
            },
          );
        },
        onSuccess: response => {
          handleMutationSuccess('Card Widget style edited', response, {
            entityId: guideId,
          });
        },
      },
    ),
  };
};

const optimisticallyAddCardToCardWidget = (
  guideId: string,
  pageId: string,
  sectionId: string,
  columnIndex: number,
  widgetId: string,
  newCard: CardWidgetCard,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const widget = old.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as CardWidget;
          const newWidget = {
            ...widget,
            cards: [...widget.cards, newCard],
          };
          draft.sections[sectionIndex].columns[columnIndex].widgets[
            widgetIndex
          ] = {
            ...newWidget,
          };
        });
      }
    },
  );
};
const optimisticallyRemoveCardFromCardWidget = (
  guideId: string,
  pageId: string,
  sectionId: string,
  columnIndex: number,
  widgetId: string,
  cardIndex: number,
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const widget = old.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as CardWidget;

          widget.cards.splice(cardIndex, 1);

          draft.sections[sectionIndex].columns[columnIndex].widgets[
            widgetIndex
          ] = {
            ...widget,
          };
        });
      }
    },
  );
};

const optimisticallyUpdateCardInCardWidget = (
  guideId: string,
  pageId: string,
  sectionId: string,
  columnIndex: number,
  widgetId: string,
  cardIndex: number,
  value: { [key: string]: unknown },
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const cardWidget = draft.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as CardWidget;
          const card = cardWidget.cards[cardIndex];

          cardWidget.cards.splice(cardIndex, 1, { ...card, ...value });
        });
      }
    },
  );
};
const optimisticallyUpdateCardWidget = (
  guideId: string,
  pageId: string,
  sectionId: string,
  columnIndex: number,
  widgetId: string,
  value: { [key: string]: unknown },
) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const cardWidget = old.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as CardWidget;
          draft.sections[sectionIndex].columns[columnIndex].widgets[
            widgetIndex
          ] = merge(cardWidget, value);
        });
      }
    },
  );
};

const optimisticallyMoveCardInCardWidget = ({
  guideId,
  pageId,
  sectionId,
  columnIndex,
  widgetId,
  from,
  to,
}: {
  guideId: string;
  pageId: string;
  sectionId: string;
  columnIndex: number;
  widgetId: string;
  from: number;
  to: number;
}) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const cardWidget = old.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as CardWidget;
          const card = cardWidget.cards[from];
          const newCardWidget = draft.sections[sectionIndex].columns[
            columnIndex
          ].widgets[widgetIndex] as CardWidget;
          newCardWidget.cards.splice(from, 1);
          newCardWidget.cards.splice(to, 0, card);
        });
      }
    },
  );
};

export const getNewCard = (
  id: string = generateUUID(),
  title: string = '',
  description: string = '',
  linkUrl: string = '',
): CardWidgetCard => ({
  id,
  headline: {
    title,
    description,
  },
  link: {
    url: linkUrl,
    openNewTab: false,
  },
  cardStyle: emptyCSSObject,
  asset: {
    id: '',
    name: '',
    extension: '',
    imageUrl: '',
    detailUrl: '',
  },
});
