import { Button, ModalBase } from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';
import { useActiveGuide } from '../../queries/guides';
import { Translate, useTranslate } from '@bynder/localization';
import { usePageMutations } from '../../mutations/pages';
import { useGuideMutations } from '../../mutations/guides';

interface TargetedPagesModalProps {
  onCancel: () => void;
}

const TargetedPagesModal = ({ onCancel }: TargetedPagesModalProps) => {
  const { translate } = useTranslate();
  const { batchUnpublishPage } = usePageMutations();
  const { data: guide } = useActiveGuide();
  const { disableTargetedPages } = useGuideMutations(guide?.id);
  const handleUnpublishPages = () => {
    const pageIds = guide?.chapters?.[0]?.pages
      ?.slice(0, -1)
      ?.map(page => page.id);

    if (pageIds && guide) {
      batchUnpublishPage.mutate({ pageIds });
      disableTargetedPages.mutate({ guideId: guide.id });
    }
  };

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={translate('TARGETED_PAGES_MODAL.HEADER_TEXT')} />
      <ModalBase.Content>
        <Translate id="TARGETED_PAGES_MODAL.CONTENT_TEXT" />
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            onClick={handleUnpublishPages}
          >
            <Translate id="TARGETED_PAGES_MODAL.BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="TARGETED_PAGES_MODAL.CANCEL_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default TargetedPagesModal;
