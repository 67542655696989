import { useQuery } from '@tanstack/react-query';
import { fetchGroupByIdApi, fetchGroups } from '../helpers/api/guidelinesApi';
import { Group } from '../types';
import { OVERVIEW_KEYS } from './guides';
import { useOverviewStore } from '../stores/overviewStore';
import { ActiveSplits, useReleaseToggle } from '../helpers/releaseToggle';

export const useGetOverviewGroups = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );
  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GROUPS, orderBy, orderType],
    () => fetchGroups({ orderBy, orderType }, isNewService),
    {
      enabled: !!orderBy && !!orderType && !isLoadingSplit,
    },
  );
};

export const useOverviewGroupById = (
  groupId: string | undefined,
): Group | undefined => {
  const { data: groups } = useGetOverviewGroups();

  return groups?.find(group => group.id === groupId);
};

export const useGroupById = (groupId: string) => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GROUPS, orderBy, orderType, groupId],
    () => fetchGroupByIdApi(groupId, { orderBy, orderType }, isNewService),
    { enabled: !isLoadingSplit },
  );
};
