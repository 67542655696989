import {
  IconBuilding,
  IconLock,
  IconPublic,
  IconShield,
  IconUser,
  IconUserGroup,
} from '@bynder/icons';
import { EntityVisibility, IdentityType } from '../../types';

export const getIdentityIcons = (identityType: IdentityType) => {
  switch (identityType) {
    case IdentityType.USER:
      return <IconUser />;
    case IdentityType.GROUP:
      return <IconUserGroup />;
    case IdentityType.PROFILE:
      return <IconShield />;
    default:
      return null;
  }
};

export const getPermissionIcon = (
  entityVisibility: EntityVisibility,
  isPrivate?: boolean,
) => {
  switch (entityVisibility) {
    case EntityVisibility.PORTAL:
      return <IconBuilding />;
    case EntityVisibility.PUBLIC:
      return <IconPublic />;
    case EntityVisibility.SPECIFIC:
      return isPrivate ? <IconLock /> : <IconUserGroup />;
    default:
      return <IconUserGroup />;
  }
};
