import React from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { get } from 'lodash';
import { defaultBynderThemeset } from '../../constants/themesets';
import { Themeset } from '../../types';

interface Props {
  children: React.ReactElement;
}

const ThemesetManager = (props: Props) => {
  const [assignedThemeset, setAssignedThemeset] = React.useState(
    defaultBynderThemeset,
  );

  const getValueOrDefault = (path: string) => {
    return get(assignedThemeset, path, get(defaultBynderThemeset, path));
  };

  return (
    <ThemeProvider
      theme={
        {
          themeset: assignedThemeset,
          setAssignedThemeset,
          getValueOrDefault,
        } as ThemeContextType
      }
    >
      {props.children}
    </ThemeProvider>
  );
};

export default ThemesetManager;

export interface ThemeContextType extends DefaultTheme {
  themeset: Themeset;
  setAssignedThemeset: (themeset: Themeset) => void;
  getValueOrDefault: (path: string) => unknown;
}
