import React from 'react';
import styled, { css } from 'styled-components';
import { Thumbnail, token } from '@bynder/design-system';
import { COLOR } from '../../constants/styles';
import FloatingContainer from './styled-components/FloatingContainer';
import Tooltip from './Tooltip';
import { Color } from '../../types/color';
import { TooltipPlacement } from '../widgets/helpers';

export default styled(FloatingContainer)`
  overflow-x: hidden;
  flex-flow: wrap;
  width: max-content;
  max-width: 176px;
  padding: 8px 12px;
  margin: 0 12px;
  z-index: 101;
`;

interface ColorDotProps {
  label?: string;
  color?: Color;
  isActive?: boolean;
  onClick?: (color?: Color) => void;
  isDefault?: boolean;
}
const TOOLTIP_ALIGN = { offset: [0, 10] };

export const ColorDot: React.FC<ColorDotProps> = ({
  label,
  color,
  isActive,
  onClick,
  isDefault = false,
}) => {
  if (isDefault) {
    return (
      <Tooltip
        align={TOOLTIP_ALIGN}
        label={
          <StyledTooltip>{label !== '' && <span>{label} </span>}</StyledTooltip>
        }
        placement={TooltipPlacement.BOTTOM}
      >
        <DotContainer
          isActive={isActive}
          onClick={() => onClick()}
          tabIndex={-1}
        >
          <Thumbnail shape="circle" variant="content" size="xs" />
        </DotContainer>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      align={TOOLTIP_ALIGN}
      label={
        <StyledTooltip>
          {color.label !== '' && <span>{color.label} </span>}
          <span>{color.hexCode}</span>
        </StyledTooltip>
      }
      placement={TooltipPlacement.BOTTOM}
    >
      <DotContainer
        key={color.id}
        isActive={isActive}
        onClick={() => onClick(color)}
        tabIndex={-1}
      >
        <Thumbnail
          shape="circle"
          variant="content"
          backgroundColor={color.hexCode}
          size="xs"
        />
      </DotContainer>
    </Tooltip>
  );
};

interface StyledColorDotProps {
  isActive?: boolean;
}

const DotContainer = styled.div<StyledColorDotProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${token.radiusCircle};
  height: 34px;
  width: 34px;
  margin: ${token.spacing1};
  cursor: pointer;
  &:hover {
    border: 2px
      ${({ isActive, theme }) =>
        isActive ? theme.primaryColor : token.gray100a}
      solid;
  }
  &:focus {
    border: 2px ${token.gray300a} solid;
  }
  ${({ isActive, theme }) =>
    isActive &&
    css`
      border: 2px ${theme.primaryColor} solid;
    `}
`;

const StyledTooltip = styled.div`
  size: 8px;
  span:first-child {
    color: ${token.white};
  }

  span:nth-child(2) {
    color: ${COLOR.WHITE_50};
  }
`;
