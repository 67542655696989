// Added to a separate file since ts-jest has troubles with exported enums

import { keyBy } from 'lodash';
import { IdentityAccess, IdentityType, RoleType } from '../types';

// https://github.com/kulshekhar/ts-jest/issues/944
export const enum GUIDELINES_PERMISSIONS {
  CREATE = 'create',
  VIEW = 'view',
  MANAGE = 'manage',
}

export const enum CONTENT_CUSTOMIZATION_PERMISSIONS {
  MANAGE = 'manage',
}

export const enum PORTAL_SETTINGS_PERMISSIONS {
  MANAGE = 'manage',
}

export const enum JWT_PERMISSIONS {
  GUIDELINES_CREATE = 'guidelines.guide.create',
  GUIDELINES_VIEW = 'guidelines.guide.view',
  GUIDELINES_MANAGE = 'guidelines.guide.manage',
  HOMEPAGE_MANAGE = 'homepage.page.manage',
  HOMEPAGE_VIEW = 'homepage.page.view',
  CONTENT_CUSTOMIZATION_MANAGE = 'asset_bank.handler.customization.manage',
  MANAGE_PORTAL_SETTINGS = 'asset_bank.handler.settings.list',
}

export const enum GuidelinesTiers {
  BASIC = 'BG0',
  ADVANCED = 'BGA',
  ENTERPRISE = 'BGE',
}

export const HOMEPAGE_APP_CODE = 'HP';
export const BG_APPCODE = 'BG';

export const enum PERMISSION_ENTITIES {
  GUIDE = 'Guide',
  GROUP = 'Group',
  THEMESETS = 'Themesets',
  PAGE = 'Page',
  CONTENT_CUSTOMIZATION_SETTINGS = 'ContentCustomizationSettings',
  PORTAL_SETTINGS = 'PortalSettings',
}

// This is to support the legacy way of updating access permissions to guides and groups.
// Will soon be deprecated in favour of agnostically updating an entity permission straight in the
// permission-service

interface LegacyModificationObject {
  add: string[];
  remove: string[];
}
export interface LegacyModificationGroup {
  users: LegacyModificationObject;
  profiles: LegacyModificationObject;
  groups: LegacyModificationObject;
}

interface LegacyAccessListModificationsType {
  viewAccessList: LegacyModificationGroup;
  editAccessList: LegacyModificationGroup;
}
export const generateAccessList = (
  existingPermissions: IdentityAccess[] | undefined,
  newPermissions: IdentityAccess[],
): LegacyAccessListModificationsType | null => {
  if (existingPermissions === undefined || newPermissions === undefined) {
    return null;
  }
  const newPermissionMap = keyBy(newPermissions, 'id');
  const existingPermissionMap = keyBy(existingPermissions, 'id');
  let accessListChanges: LegacyAccessListModificationsType = {
    viewAccessList: getEmptyLegacyModificationGroup(),
    editAccessList: getEmptyLegacyModificationGroup(),
  };

  accessListChanges = existingPermissions?.reduce((acc, el) => {
    const { id } = el;

    // a role has changed
    // eslint-disable-next-line no-prototype-builtins
    if (newPermissionMap.hasOwnProperty(id)) {
      if (newPermissionMap[id].role !== el.role) {
        switch (newPermissionMap[id].identity) {
          case IdentityType.USER:
            if (newPermissionMap[id].role === RoleType.EDITOR) {
              acc.editAccessList.users.add.push(id);
              acc.viewAccessList.users.remove.push(id);
            } else if (newPermissionMap[id].role === RoleType.READER) {
              acc.viewAccessList.users.add.push(id);
              acc.editAccessList.users.remove.push(id);
            }

            break;
          case IdentityType.PROFILE:
            if (newPermissionMap[id].role === RoleType.EDITOR) {
              acc.editAccessList.profiles.add.push(id);
              acc.viewAccessList.profiles.remove.push(id);
            } else if (newPermissionMap[id].role === RoleType.READER) {
              acc.viewAccessList.profiles.add.push(id);
              acc.editAccessList.profiles.remove.push(id);
            }

            break;
          case IdentityType.GROUP:
            if (newPermissionMap[id].role === RoleType.EDITOR) {
              acc.editAccessList.groups.add.push(id);
              acc.viewAccessList.groups.remove.push(id);
            } else if (newPermissionMap[id].role === RoleType.READER) {
              acc.viewAccessList.groups.add.push(id);
              acc.editAccessList.groups.remove.push(id);
            }

            break;
        }
      }

      // a role has been deleted
    } else {
      switch (el.identity) {
        case IdentityType.USER:
          if (el.role === RoleType.EDITOR) {
            acc.editAccessList.users.remove.push(id);
          } else {
            acc.viewAccessList.users.remove.push(id);
          }

          break;
        case IdentityType.PROFILE:
          if (el.role === RoleType.EDITOR) {
            acc.editAccessList.profiles.remove.push(id);
          } else {
            acc.viewAccessList.profiles.remove.push(id);
          }

          break;
        case IdentityType.GROUP:
          if (el.role === RoleType.EDITOR) {
            acc.editAccessList.groups.remove.push(id);
          } else {
            acc.viewAccessList.groups.remove.push(id);
          }

          break;
      }
    }

    return acc;
  }, accessListChanges);

  // handle new permission added
  accessListChanges = newPermissions?.reduce((acc, el) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!existingPermissionMap.hasOwnProperty(el.id)) {
      switch (el.identity) {
        case IdentityType.USER:
          if (el.role === RoleType.EDITOR) {
            acc.editAccessList.users.add.push(el.id);
          } else {
            acc.viewAccessList.users.add.push(el.id);
          }

          break;
        case IdentityType.PROFILE:
          if (el.role === RoleType.EDITOR) {
            acc.editAccessList.profiles.add.push(el.id);
          } else {
            acc.viewAccessList.profiles.add.push(el.id);
          }

          break;
        case IdentityType.GROUP:
          if (el.role === RoleType.EDITOR) {
            acc.editAccessList.groups.add.push(el.id);
          } else {
            acc.viewAccessList.groups.add.push(el.id);
          }

          break;
      }
    }

    return acc;
  }, accessListChanges);

  return accessListChanges;
};

export const getEmptyLegacyModificationGroup = (): LegacyModificationGroup => ({
  users: {
    add: [],
    remove: [],
  },
  profiles: {
    add: [],
    remove: [],
  },
  groups: {
    add: [],
    remove: [],
  },
});
