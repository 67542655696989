import { useMutation } from '@tanstack/react-query';
import {
  setHomepagePortalApi,
  unsetHomepagePortalApi,
} from '../helpers/api/portalApi';
import { handleMutationSuccess } from './helpers';
import { guideWriteCommandApi } from '../helpers/api/guidelinesApi';
import { notify } from '@bynder/design-system';
import {
  HomepageSetNotification,
  HomepageUnsetNotification,
} from '../types/notifications';
import { getCommand } from '../helpers/api/commands';
import { useGetCurrentHomepage } from '../queries/homepage';
import { AxiosResponse } from 'axios';

export const useHomepageMutations = () => {
  const { data: currentHomepage } = useGetCurrentHomepage();
  const publishHomepage = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) => {
      const { publishHomepage } = getCommand(
        mutationOptions.guideId,
        mutationOptions.guideVersion,
      );

      return guideWriteCommandApi(
        publishHomepage({ previousHomepageId: currentHomepage }),
      );
    },
    {
      onSuccess: (data, variables) => {
        handleMutationSuccess('Landing page set as homepage', data, {
          entityId: variables.guideId,
        });
        notify(HomepageSetNotification);
      },
    },
  );

  const setHomepage = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      setHomepagePortalApi(mutationOptions.guideId),
    {
      onSuccess: (_, variables) => {
        publishHomepage.mutate({
          guideId: variables.guideId,
          guideVersion: variables.guideVersion,
        });
      },
    },
  );

  const unsetHomepage = useMutation(
    (options: { guideId: string; guideVersion: number }) => {
      if (currentHomepage === options.guideId) {
        return unsetHomepagePortalApi();
      }
      return Promise.resolve() as unknown as Promise<AxiosResponse>;
    },
    {
      onSuccess: (_, variables) => {
        unpublishHomepage.mutate({
          guideId: variables.guideId,
          guideVersion: variables.guideVersion,
        });
      },
    },
  );

  const unpublishHomepage = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) => {
      const unpublishGuideCommand = getCommand(
        mutationOptions.guideId,
        mutationOptions.guideVersion,
      ).unpublishGuide;

      return guideWriteCommandApi(unpublishGuideCommand());
    },
    {
      onSuccess: (data, variables) => {
        handleMutationSuccess('Landing page unset as homepage', data, {
          entityId: variables.guideId,
        });
      },
      onMutate: () => {
        notify(HomepageUnsetNotification);
      },
    },
  );

  return { setHomepage, unsetHomepage };
};
