import { Box, Button, InlineBanner, ModalBase } from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';
import { Translate, useTranslate } from '@bynder/localization';
import { useGetCurrentHomepage } from '../../queries/homepage';
import { ActiveSplits, useReleaseToggle } from '../../helpers/releaseToggle';

interface HomepageModalProps {
  onSubmit: () => void;
  onCancel: () => void;
}

const HomepageModal = ({ onSubmit, onCancel }: HomepageModalProps) => {
  const { translate } = useTranslate();
  const { data: currentHomepage } = useGetCurrentHomepage();
  const { data: isAudiencesEnabled } = useReleaseToggle(
    ActiveSplits.pageInPage,
  );

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={translate('HOMEPAGE_MODAL.HEADER_TEXT')} />
      <ModalBase.Content>
        {currentHomepage ? (
          <Translate id="HOMEPAGE_MODAL.REPLACE_CONTENT_TEXT" />
        ) : (
          <Translate id="HOMEPAGE_MODAL.CONTENT_TEXT" />
        )}
        {isAudiencesEnabled && (
          <Box marginTop="5">
            <InlineBanner variant="info">
              <Translate id="HOMEPAGE_MODAL.INFO_TEXT" />
            </InlineBanner>
          </Box>
        )}
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton data-testid="submitButton" onClick={onSubmit}>
            <Translate id="HOMEPAGE_MODAL.BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="HOMEPAGE_MODAL.CANCEL_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default HomepageModal;
