import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import { findIndex } from 'lodash';
import { track } from '../helpers/analytics';
import {
  assignThemesetToGuide,
  createThemesetApi,
  createThemesetColor,
  deleteThemeset,
  deleteThemesetColor,
  duplicateThemeset,
  editGuidePropertiesApi,
  editNavBarApi,
  editPageHeaderApi,
  editPageSectionApi,
  editSidebarApi,
  editThemesetButtonApi,
  editThemesetCardApi,
  editThemesetColor,
  editThemesetDetails,
  editTypography,
} from '../helpers/api/customizationApi';
import { Color } from '../types/color';
import { generateUUID } from '../helpers/utils';
import {
  GuideProperties,
  NavigationBar,
  Sidebar,
  ThemesetDetails,
  Themeset,
  PageHeader,
  Sections,
  ThemesetCard,
  ThemesetButton,
} from '../types';
import { Typography } from '../types/typography';
import { queryClient } from '../constants/app';
import { THEMESET_KEYS } from '../queries/themesets';

interface AssingThemesetOptions {
  themesetId: string;
  guideId?: string;
}

export const useAssignThemesetToGuide = (keyGuideId?: string) => {
  return useMutation(
    ({ themesetId, guideId }: AssingThemesetOptions) => {
      const id = keyGuideId ? keyGuideId : guideId;

      if (id) {
        return assignThemesetToGuide(themesetId, id);
      }
      return Promise.reject('invalid guide id');
    },
    {
      retry: false,
      onSuccess: () => {
        track('Theme set applied to Guide');
      },
    },
  );
};

const useDuplicateThemeset = (themesetId: string) => {
  return useMutation(
    () => {
      return duplicateThemeset(themesetId);
    },
    {
      onSuccess: () => {
        track('Theme set Duplicated');
      },
    },
  );
};

const useEditThemesetDetails = (themesetId: string) => {
  return useMutation(
    (ediThemesetOptions: CreateThemesetOptions) => {
      return editThemesetDetails(
        themesetId,
        ediThemesetOptions.themesetDetails,
      );
    },
    {
      onSuccess: () => {
        track('Theme set Created');
      },
    },
  );
};

const useDeleteThemeset = (themesetId: string) => {
  return useMutation(
    () => {
      return deleteThemeset(themesetId);
    },
    {
      onSuccess: () => {
        track('Theme set Deleted');
      },
    },
  );
};

interface CreateThemesetOptions {
  themesetDetails: ThemesetDetails;
}
const useCreateThemeset = () => {
  return useMutation(
    (createThemesetOptions: CreateThemesetOptions) => {
      const newThemesetId = generateUUID();

      return createThemesetApi(
        newThemesetId,
        createThemesetOptions.themesetDetails,
      );
    },
    {
      onSuccess: () => {
        track('Theme set Created');
      },
    },
  );
};

interface CreateColorOptions {
  colorSetId: string;
  color: Color;
}
const useCreateThemesetColor = (themesetId: string) => {
  return useMutation((createColorOptions: CreateColorOptions) => {
    return createThemesetColor(
      themesetId,
      createColorOptions.colorSetId,
      createColorOptions.color,
    );
  });
};

const useEditThemesetColor = (themesetId: string) => {
  return useMutation((editColorOptions: CreateColorOptions) => {
    return editThemesetColor(
      themesetId,
      editColorOptions.colorSetId,
      editColorOptions.color,
    );
  });
};

interface DeleteColorOptions {
  colorSetId: string;
  colorId: string;
}

const useDeleteThemesetColor = (themesetId: string) => {
  return useMutation((deleteColorOptions: DeleteColorOptions) => {
    return deleteThemesetColor(
      themesetId,
      deleteColorOptions.colorSetId,
      deleteColorOptions.colorId,
    );
  });
};

interface EditTypographyOptions {
  newTypography: Typography;
}

const useEditTypography = (themesetId: string) =>
  useMutation(
    (editTypographyOptions: EditTypographyOptions) => {
      const {
        id,
        heading,
        label,
        fontFamily,
        fontStyle,
        fontSize,
        lineHeight,
        letterSpacing,
        fontColor,
      } = editTypographyOptions.newTypography;
      const requestPayload = {
        id,
        heading,
        label,
        fontFamily,
        fontStyle,
        fontSize,
        lineHeight,
        letterSpacing,
        fontColorId: fontColor?.id || null,
      };

      return editTypography(themesetId, requestPayload);
    },
    {
      onSuccess: () => {
        track('Typography Edited');
      },
    },
  );

interface NavbarOptions {
  navbarOptions: NavigationBar;
}

const useEditNavbar = (themesetId: string) =>
  useMutation(
    (options: NavbarOptions) => {
      return editNavBarApi(themesetId, options.navbarOptions);
    },
    {
      onSuccess: () => {
        track('Navbar Edited');
      },
    },
  );
interface SidebarOptions {
  sidebarOptions: Sidebar;
}

interface PageHeaderOptions {
  pageHeaderOptions: PageHeader;
}

const useEditSidebar = (themesetId: string) =>
  useMutation(
    (options: SidebarOptions) => {
      return editSidebarApi(themesetId, options.sidebarOptions);
    },
    {
      onSuccess: () => {
        track('Sidebar Edited');
      },
    },
  );

const useEditPageHeader = (themsetId: string) =>
  useMutation((options: PageHeaderOptions) => {
    return editPageHeaderApi(themsetId, options.pageHeaderOptions);
  });

const useEditPageSection = (themesetId: string) =>
  useMutation((options: Sections) => {
    return editPageSectionApi(themesetId, options);
  });
const useEditCardWidget = (themesetId: string) =>
  useMutation((options: ThemesetCard) => {
    return editThemesetCardApi(themesetId, options);
  });

const useEditButtonWidget = (themesetId: string) =>
  useMutation((options: ThemesetButton) => {
    return editThemesetButtonApi(themesetId, options);
  });

const useEditGuideProperties = (themesetId: string) =>
  useMutation(
    (options: GuideProperties) => {
      return editGuidePropertiesApi(themesetId, options);
    },
    {
      onMutate: variables => {
        track('Guide properties edited');
        optimisticallyUpdateThemesetProperties(themesetId, variables);
      },
      onError: () => {
        queryClient.invalidateQueries([THEMESET_KEYS.THEMESETS]);
      },
    },
  );

const optimisticallyUpdateThemesetProperties = (
  themesetId: string,
  options: GuideProperties,
) => {
  queryClient.setQueryData(
    [THEMESET_KEYS.THEMESETS, themesetId],
    (old: Themeset[]) => {
      if (old) {
        const themesetIndex = findIndex(
          old,
          themeset => themeset.id === themesetId,
        );

        return produce(old, draft => {
          draft[themesetIndex].properties = options;
        });
      }
    },
  );
};

export const themesetColorMutations = {
  createThemesetColor: useCreateThemesetColor,
  editThemesetColor: useEditThemesetColor,
  deleteThemesetColor: useDeleteThemesetColor,
};

const themesetMutations = {
  createThemeset: useCreateThemeset,
  assignThemesetToGuide: useAssignThemesetToGuide,
  duplicateThemeset: useDuplicateThemeset,
  deleteThemeset: useDeleteThemeset,
  editThemesetDetails: useEditThemesetDetails,
  editTypography: useEditTypography,
  editNavbar: useEditNavbar,
  editSidebar: useEditSidebar,
  editGuideProperties: useEditGuideProperties,
  editPageHeader: useEditPageHeader,
  editPageSection: useEditPageSection,
  editCardWidget: useEditCardWidget,
  editButtonWidget: useEditButtonWidget,
};

export default themesetMutations;
