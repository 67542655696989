import { useHistory } from 'react-router-dom';
import getWebsocketClient from '@bynder/js-websocket-client-lib';
import { CONFIG, ROUTES, isDevelopment } from '../constants/app';
import { notify } from '@bynder/design-system';
import { PERMISSIONS_KEYS } from '../queries/permissions';
import { SOCKETS, getWSEndpoint } from '../constants/sockets';
import { queryClient } from '../constants/app';
import { GUIDE_KEYS, OVERVIEW_KEYS } from '../queries/guides';
import { THEMESET_KEYS } from '../queries/themesets';
import { useEffect } from 'react';
import { useSocketsStoreBase } from '../stores/socketsStore';
import { useModalStore, useModalStoreBase } from '../stores/modalStore';
import {
  DeleteGuideSuccessNotification,
  DuplicateGuideSuccessNotification,
  GroupDeletedSuccessNotification,
  GuideAddedToGroupSuccessNotification,
  GuideDeletedFromGroupSuccessNotification,
  duplicatePageNotification,
  TempErrorNotification,
  createTargetedPageNotification,
  duplicateTargetedPageNotification,
} from '../types/notifications';
import { ModalType } from '../types';
import { useInspectorStore } from '../stores/inspectorStore';
import { InspectorSetting } from '../constants/guides';
import { HOMEPAGE_KEYS } from '../queries/homepage';

interface WebsocketsTrimmedMessage {
  aggregate_root_id: string;
  correlation_id: string;
  correlationId: string;
  msg_topic: string;
  msg_schema_name: string;
  status: string;
}

export const useReactQueryWebSocketsManager = () => {
  const handleWsMessage = useHandleWsMessage();
  useEffect(() => {
    // Handle websockets connection to account_id room
    async function getWebSocketClient() {
      const wsClient = await getWebsocketClient(
        CONFIG.SOCKET_SERVER_URL,
        getWSEndpoint(),
        undefined,
        false,
      );
      const ACCOUNTID = isDevelopment
        ? import.meta.env.VITE_ACCOUNT_ID
        : document.querySelector('#root')?.getAttribute('data-account-id');

      const roomName = `${
        import.meta.env.VITE_KAFKA_TOPIC_MESSAGES
      }_${ACCOUNTID}`;
      wsClient
        .waitForConnect()
        .then(() => {
          wsClient.joinRoom(roomName);

          // Handle messages for query invalidation
          wsClient.onRoomMessage([SOCKETS.GROUP_ACCESS_EDITED], {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              queryClient.invalidateQueries([
                PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
                data.aggregate_root_id,
              ]);
              queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);
              queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GROUPS]);

              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });
          wsClient.onRoomMessage([SOCKETS.GUIDE_ACCESS_EDITED], {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              queryClient.invalidateQueries([
                PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
                data.aggregate_root_id,
              ]);
              queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);

              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });
          wsClient.onRoomMessage(
            [
              SOCKETS.GUIDE_CREATED_WITH_TEMPLATE_INSIDE_GROUP,
              SOCKETS.GUIDE_ADDED_TO_GROUP,
              SOCKETS.GROUP_DELETED,
              SOCKETS.GUIDE_DUPLICATED,
              SOCKETS.GUIDE_DELETED_FROM_GROUP,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GROUPS]);

                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );

          wsClient.onRoomMessage([SOCKETS.PAGE_ACCESS_EDITED], {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });

          wsClient.onRoomMessage(
            [
              SOCKETS.GUIDE_CREATED_WITH_TEMPLATE,
              SOCKETS.CHAPTER_ACCESS_EDITED,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                queryClient.invalidateQueries([
                  PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
                ]);
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);

                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );

          wsClient.onRoomMessage(
            [
              SOCKETS.GUIDE_FEATURED,
              SOCKETS.GUIDE_UNFEATURED,
              SOCKETS.HOMEPAGE_PUBLISHED,
              SOCKETS.GUIDE_UNPUBLISHED,
              SOCKETS.GUIDE_TARGETED_PAGES_ENABLED,
              SOCKETS.GUIDE_TARGETED_PAGES_DISABLED,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                queryClient.invalidateQueries([HOMEPAGE_KEYS.HOMEPAGE]);
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);
                queryClient.invalidateQueries([GUIDE_KEYS.GUIDE_BY_ID]);
                queryClient.invalidateQueries([GUIDE_KEYS.PAGE_BY_ID]);

                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );

          wsClient.onRoomMessage(
            [
              SOCKETS.GUIDE_TITLE_EDITED,
              SOCKETS.GUIDE_DELETED,
              SOCKETS.GUIDE_COVER_REMOVED,
              SOCKETS.GUIDE_COVER_SET,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );

          wsClient.onRoomMessage(SOCKETS.GROUP_CREATED, {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GROUPS]);
              queryClient.invalidateQueries([
                PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
              ]);

              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });

          wsClient.onRoomMessage(
            [
              SOCKETS.GROUP_TITLE_EDITED,
              SOCKETS.GROUP_DESCRIPTION_EDITED,
              SOCKETS.GROUP_COVER_ASSET_EDITED,
              SOCKETS.GUIDE_DELETED_INSIDE_GROUP,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GROUPS]);

                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );

          wsClient.onRoomMessage(SOCKETS.GUIDE_DUPLICATED_INSIDE_GROUP, {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              queryClient.invalidateQueries([
                PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
              ]);
              queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GROUPS]);

              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });

          wsClient.onRoomMessage(
            [
              SOCKETS.CHAPTER_DELETED_FROM_GUIDE,
              SOCKETS.PAGE_DUPLICATED_IN_CHAPTER,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);
                queryClient.invalidateQueries([
                  GUIDE_KEYS.GUIDE_BY_ID,
                  data.aggregate_root_id,
                ]);
                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );

          wsClient.onRoomMessage(
            [
              SOCKETS.THEMESET_EDITED,
              SOCKETS.THEMESET_CREATED,
              SOCKETS.THEMESET_DELETED,
            ],
            {
              onSuccess: () => {
                queryClient.invalidateQueries([THEMESET_KEYS.THEMESETS]);
              },
            },
          );

          wsClient.onRoomMessage(
            [SOCKETS.THEMESET_DELETED, SOCKETS.THEMESET_ASSIGNED],
            {
              onSuccess: () => {
                queryClient.invalidateQueries([THEMESET_KEYS.THEMESETS]);
                queryClient.invalidateQueries([THEMESET_KEYS.ASSIGNATIONS]);
              },
            },
          );

          wsClient.onRoomMessage(SOCKETS.COMMAND_FAILED_AGGREGATE_SIZE_LIMIT, {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });

          wsClient.onRoomMessage(SOCKETS.COMMAND_FAILED, {
            onSuccess: (data: WebsocketsTrimmedMessage) => {
              handleWsMessage(data);
            },
            onFailure: error => {
              throw error;
            },
          });

          wsClient.onRoomMessage(
            [SOCKETS.GUIDE_BATCH_EXECUTED, SOCKETS.SECTION_DUPLICATED_IN_PAGE],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GUIDES]);
                queryClient.invalidateQueries([
                  GUIDE_KEYS.PAGE_BY_ID,
                  data.aggregate_root_id,
                ]);
                queryClient.invalidateQueries([
                  GUIDE_KEYS.GUIDE_BY_ID,
                  data.aggregate_root_id,
                ]);
                queryClient.invalidateQueries([OVERVIEW_KEYS.OVERVIEW_GROUPS]);
                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );
          wsClient.onRoomMessage(
            [
              ...CHAPTER_ACTIONS_SOCKET_MESSAGES,
              ...PAGE_SOCKET_MESSAGES,
              ...SECTION_SOCKETS_MESSAGES,
              ...WIDGETS_ACTIONS_SOCKET_MESSAGES,
            ],
            {
              onSuccess: (data: WebsocketsTrimmedMessage) => {
                handleWsMessage(data);
              },
              onFailure: error => {
                throw error;
              },
            },
          );
        })
        .catch(e => {
          throw new Error(e);
        });
    }

    getWebSocketClient();
  }, []);
};

const useHandleWsMessage = () => {
  const history = useHistory();
  const toggleIsCreatingGuide = useModalStore.use.toggleIsCreatingGuide();
  const toggleIsCreatingLandingPage =
    useModalStore.use.toggleIsCreatingLandingPage();
  const toggleIsCreatingGroup = useModalStore.use.toggleIsCreatingGroup();
  const setIsInspectorOpen = useInspectorStore(s => s.setIsInspectorOpen);
  const setActiveInspectorSetting = useInspectorStore(
    s => s.setActiveInspectorSetting,
  );

  const handleWsMessage = (data: WebsocketsTrimmedMessage) => {
    const {
      correlationId,
      aggregate_root_id: entityId,
      msg_schema_name: command,
    } = data;

    const closeModal = useModalStoreBase.getState().closeModal;
    const openModal = useModalStoreBase.getState().openModal;
    const changingEntities = useSocketsStoreBase.getState().changingEntities;
    const changingEntity = changingEntities.find(
      entity => entity.correlationId === correlationId,
    );

    const clearAllChangingEntities =
      useSocketsStoreBase.getState().clearAllChangingEntities;

    const hasCorrelationId = changingEntities.some(
      entity => entity.correlationId === correlationId,
    );

    // Invalidate queries when command fail.
    if (command === SOCKETS.COMMAND_FAILED) {
      queryClient.invalidateQueries([GUIDE_KEYS.GUIDE_BY_ID, entityId]);
      queryClient.invalidateQueries([GUIDE_KEYS.PAGE_BY_ID, entityId]);
    }

    // handle side effects when user has performed the change (has a matching correlationId )
    if (hasCorrelationId) {
      switch (command) {
        case SOCKETS.GROUP_CREATED:
          toggleIsCreatingGroup(false);
          history.push({
            pathname: ROUTES.GROUP_OVERVIEW(data.aggregate_root_id),
          });
          break;
        case SOCKETS.GUIDE_CREATED_WITH_TEMPLATE:
        case SOCKETS.GUIDE_CREATED_WITH_TEMPLATE_INSIDE_GROUP:
          toggleIsCreatingGuide(false);
          toggleIsCreatingLandingPage(false);
          history.push({
            pathname: ROUTES.GUIDE_EDIT(entityId),
          });
          setIsInspectorOpen(true);
          setActiveInspectorSetting(InspectorSetting.GUIDE_VIEW);
          break;

        case SOCKETS.GUIDE_DUPLICATED:
        case SOCKETS.GUIDE_DUPLICATED_INSIDE_GROUP:
          notify(DuplicateGuideSuccessNotification(entityId));
          break;

        case SOCKETS.GUIDE_DELETED:
          notify(DeleteGuideSuccessNotification);
          break;

        case SOCKETS.GUIDE_ADDED_TO_GROUP:
          notify(GuideAddedToGroupSuccessNotification);
          break;

        case SOCKETS.GUIDE_DELETED_FROM_GROUP:
          notify(GuideDeletedFromGroupSuccessNotification);
          break;

        case SOCKETS.GROUP_DELETED:
          notify(GroupDeletedSuccessNotification);
          break;
        case SOCKETS.GUIDE_BATCH_EXECUTED: {
          const { payload } = changingEntity as {
            payload: {
              isCreatingLandingPage: boolean;
              newPageId: string;
              isBatchCommand: boolean;
              isCreatingTargetedPage: boolean;
              guideTitle: string;
              toPageId: string;
            };
          };

          if (payload?.isBatchCommand) {
            notify(
              duplicateTargetedPageNotification(entityId, payload.toPageId),
            );
          } else if (payload?.isCreatingTargetedPage) {
            notify(createTargetedPageNotification(entityId, payload.toPageId));
          } else if (payload?.isCreatingLandingPage) {
            history.push({
              pathname: ROUTES.GUIDE_PAGE_EDIT(entityId, payload.newPageId),
            });
            setIsInspectorOpen(true);
            setActiveInspectorSetting(InspectorSetting.GUIDE_VIEW);
            toggleIsCreatingLandingPage(false);
          }

          closeModal();
          break;
        }
        case SOCKETS.PAGE_DUPLICATED_IN_CHAPTER: {
          const { payload } = changingEntity as {
            payload: { toPageId: string; guideTitle: string };
          };

          closeModal();
          notify(
            duplicatePageNotification(
              entityId,
              payload.toPageId,
              payload.guideTitle,
            ),
          );
          break;
        }

        case SOCKETS.COMMAND_FAILED_AGGREGATE_SIZE_LIMIT: {
          queryClient.invalidateQueries([GUIDE_KEYS.GUIDE_BY_ID, entityId]);
          queryClient.invalidateQueries([GUIDE_KEYS.PAGE_BY_ID, entityId]);
          openModal({
            modalType: ModalType.MAX_GUIDE_SIZE_MODAL,
            modalOnSubmit: () => {},
          });

          break;
        }

        case SOCKETS.COMMAND_FAILED: {
          const queryPath = changingEntity?.queryPath;

          queryClient.invalidateQueries([queryPath]);

          notify(TempErrorNotification);
          break;
        }

        default:
          break;
      }

      clearAllChangingEntities();
    } else {
      // When there is no correlationId, someone else has performed the change. We refresh latest stage of the guide.
      queryClient.invalidateQueries([GUIDE_KEYS.GUIDE_BY_ID, entityId]);
      queryClient.invalidateQueries([GUIDE_KEYS.PAGE_BY_ID, entityId]);
    }
  };

  return handleWsMessage;
};

const CHAPTER_ACTIONS_SOCKET_MESSAGES = [
  SOCKETS.NEW_CHAPTER_ADDED_TO_GUIDE,
  SOCKETS.CHAPTER_TITLE_EDITED,
  SOCKETS.CHAPTER_MOVED,
];

const TEXT_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.NEW_TEXT_WIDGET_ADDED_TO_SECTION,
  SOCKETS.TEXT_EDITED_IN_TEXT_WIDGET,
];
const COLLECTION_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.NEW_COLLECTION_WIDGET_ADDED_TO_SECTION,
  SOCKETS.COLLECTION_WIDGET_EDITED,
  SOCKETS.STYLE_EDITED_IN_COLLECTION_WIDGET,
];
const IMAGE_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.NEW_IMAGE_WIDGET_ADDED_TO_SECTION,
  SOCKETS.IMAGE_ACTION_SET_TO_LINK_TO_URL,
  SOCKETS.IMAGE_ACTION_SET_TO_NO_ACTION,
  SOCKETS.IMAGE_ACTION_SET_TO_OPEN_PREVIEW,
  SOCKETS.ASSET_PLACED_IN_IMAGE_WIDGET,
];

const VIDEO_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.NEW_VIDEO_WIDGET_ADDED_TO_SECTION,
  SOCKETS.ASSET_PLACED_IN_VIDEO_WIDGET,
  SOCKETS.CONTROLS_EDITED_IN_VIDEO_WIDGET,
  SOCKETS.MUTED_EDITED_IN_VIDEO_WIDGET,
  SOCKETS.AUTOPLAY_EDITED_IN_VIDEO_WIDGET,
  SOCKETS.LOOP_EDITED_IN_VIDEO_WIDGET,
];
const BUTTON_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.LINK_EDITED_IN_BUTTON_WIDGET,
  SOCKETS.NAME_EDITED_IN_BUTTON_WIDGET,
  SOCKETS.NEW_BUTTON_WIDGET_ADDED_TO_SECTION,
  SOCKETS.STYLE_EDITED_IN_BUTTON_WIDGET,
  SOCKETS.NEW_BUTTONS_ADDED_TO_BUTTON_WIDGET,
  SOCKETS.WIDGET_STYLE_EDITED_IN_BUTTON_WIDGET,
  SOCKETS.BUTTON_REMOVED_FROM_BUTTON_WIDGET,
];

const CARD_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.NEW_CARD_WIDGET_ADDED_TO_SECTION,
  SOCKETS.NEW_CARDS_ADDED_TO_CARD_WIDGET,
  SOCKETS.CARD_REMOVED_FROM_CARD_WIDGET,
  SOCKETS.CARD_MOVED_IN_CARD_WIDGET,
  SOCKETS.CARD_ASSET_EDITED_IN_CARD_WIDGET,
  SOCKETS.CARD_HEADLINE_EDITED_IN_CARD_WIDGET,
  SOCKETS.CARD_LINK_EDITED_IN_CARD_WIDGET,
  SOCKETS.STYLE_EDITED_IN_CARD_WIDGET,
  SOCKETS.WIDGET_STYLE_EDITED_IN_CARD_WIDGET,
];
const YOUTUBE_WIDGET_SOCKETS_MESSAGES = [
  SOCKETS.SOURCE_EDITED_IN_YOUTUBE_WIDGET,
  SOCKETS.SETTINGS_EDITED_IN_YOUTUBE_WIDGET,
  SOCKETS.NEW_YOUTUBE_WIDGET_ADDED_TO_SECTION,
];

const TABLE_WIDGET_SOCKET_MESSAGES = [
  SOCKETS.NEW_TABLE_WIDGET_ADDED_TO_SECTION,
  SOCKETS.ROW_INSERTED_IN_TABLE_WIDGET,
  SOCKETS.COLUMN_INSERTED_IN_TABLE_WIDGET,
  SOCKETS.ROW_REMOVED_FROM_TABLE_WIDGET,
  SOCKETS.COLUMN_REMOVED_FROM_TABLE_WIDGET,
  SOCKETS.CELLS_CONTENT_EDITED_IN_TABLE_WIDGET,
];
const COLOR_PALETTE_SOCKET_MESSAGES = [
  SOCKETS.NEW_COLOR_PALETTE_WIDGET_ADDED_TO_SECTION,
  SOCKETS.NEW_COLOR_ADDED_TO_COLOR_PALETTE_WIDGET,
  SOCKETS.COLOR_REMOVED_FROM_COLOR_PALETTE_WIDGET,
  SOCKETS.COLOR_EDITED_IN_COLOR_PALETTE_WIDGET,
  SOCKETS.DEFAULT_COLOR_CODES_SET_IN_COLOR_PALETTE_WIDGET,
  SOCKETS.STYLE_EDITED_IN_COLOR_PALETTE_WIDGET,
];

const SPACER_WIDGET_SOCKET_MESSAGES = [
  SOCKETS.NEW_SPACING_WIDGET_ADDED_TO_SECTION,
  SOCKETS.HEIGHT_EDITED_IN_SPACING_WIDGET,
  SOCKETS.DIVIDER_EDITED_IN_SPACING_WIDGET,
  SOCKETS.STYLE_EDITED_IN_SPACING_WIDGET,
];

const AUDIO_WIDGET_SOCKET_MESSAGES = [
  SOCKETS.NEW_AUDIO_WIDGET_ADDED_TO_SECTION,
  SOCKETS.ASSET_PLACED_IN_AUDIO_WIDGET,
];

const PAGE_SOCKET_MESSAGES = [
  SOCKETS.PAGE_TITLE_EDITED,
  SOCKETS.PAGE_DELETED_FROM_CHAPTER,
  SOCKETS.PAGE_UNPUBLISHED,
  SOCKETS.PAGE_PUBLISHED,
  SOCKETS.HEIGHT_EDITED_IN_PAGE_HEADER,
  SOCKETS.BACKGROUND_COLOR_EDITED_IN_PAGE_HEADER,
  SOCKETS.TEXT_COLOR_EDITED_IN_PAGE_HEADER,
  SOCKETS.ASSET_PLACED_IN_PAGE_HEADER,
  SOCKETS.NEW_PAGE_ADDED_TO_CHAPTER,
  SOCKETS.TABLE_OF_CONTENTS_EDITED,
  SOCKETS.PAGE_MOVED,
  SOCKETS.TITLE_VISIBILITY_EDITED_IN_PAGE_HEADER,
  SOCKETS.SUBTITLE_VISIBILITY_EDITED_IN_PAGE_HEADER,
  SOCKETS.HEADER_VISIBILITY_EDITED_IN_PAGE_HEADER,
];

const VIMEO_WIDGET_SOCKET_MESSAGES = [
  SOCKETS.SOURCE_EDITED_IN_VIMEO_WIDGET,
  SOCKETS.SETTINGS_EDITED_IN_VIMEO_WIDGET,
  SOCKETS.NEW_VIMEO_WIDGET_ADDED_TO_SECTION,
];

const FIGMA_WIDGET_SOCKET_MESSAGES = [
  SOCKETS.SOURCE_EDITED_IN_FIGMA_WIDGET,
  SOCKETS.SETTINGS_EDITED_IN_FIGMA_WIDGET,
  SOCKETS.NEW_FIGMA_WIDGET_ADDED_TO_SECTION,
];

const WIDGETS_ACTIONS_SOCKET_MESSAGES = [
  SOCKETS.WIDGET_DELETED_FROM_SECTION,
  SOCKETS.WIDGET_MOVED,
  ...TEXT_WIDGET_SOCKETS_MESSAGES,
  ...IMAGE_WIDGET_SOCKETS_MESSAGES,
  ...COLLECTION_WIDGET_SOCKETS_MESSAGES,
  ...TABLE_WIDGET_SOCKET_MESSAGES,
  ...COLOR_PALETTE_SOCKET_MESSAGES,
  ...VIDEO_WIDGET_SOCKETS_MESSAGES,
  ...SPACER_WIDGET_SOCKET_MESSAGES,
  ...AUDIO_WIDGET_SOCKET_MESSAGES,
  ...BUTTON_WIDGET_SOCKETS_MESSAGES,
  ...CARD_WIDGET_SOCKETS_MESSAGES,
  ...YOUTUBE_WIDGET_SOCKETS_MESSAGES,
  ...VIMEO_WIDGET_SOCKET_MESSAGES,
  ...FIGMA_WIDGET_SOCKET_MESSAGES,
];

const SECTION_SOCKETS_MESSAGES = [
  SOCKETS.NEW_SECTION_ADDED_TO_PAGE,
  SOCKETS.SECTION_DELETED_FROM_PAGE,
  SOCKETS.SECTION_LAYOUT_CHANGED,
  SOCKETS.BACKGROUND_COLOR_EDITED_IN_SECTION,
  SOCKETS.SECTION_MOVED,
  SOCKETS.SECTION_DUPLICATED_IN_PAGE,
  SOCKETS.SECTION_WIDTH_EDITED,
  SOCKETS.SECTION_HEIGHT_EDITED,
  SOCKETS.SECTION_CONTENT_ALIGNMENT_EDITED,
  SOCKETS.SECTION_HORIZONTAL_PADDING_EDITED,
  SOCKETS.SECTION_VERTICAL_PADDING_EDITED,
  SOCKETS.SECTION_CONTENT_SPACING_EDITED,
  SOCKETS.SECTION_COLUMN_SPACING_EDITED,
  SOCKETS.ASSET_PLACED_IN_SECTION,
  SOCKETS.ASSET_REMOVED_FROM_SECTION,
  SOCKETS.STYLE_EDITED_IN_SECTION,
];
