import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import { useTranslate } from '@bynder/localization';
import {
  addGuideToGroup,
  createGuideWithTemplateApi,
  deleteGuide,
  deleteGuideFromGroup,
  deleteGuideInsideGroup,
  disableTargetedPages,
  duplicateGuideApi,
  duplicateGuideInsideGroupApi,
  enableTargetedPages,
  featureGuide,
  removeGuideCover,
  setGuideCover,
  unFeatureGuide,
  updateGuideTitle,
} from '../helpers/api/guidelinesApi';
import { generateUUID } from '../helpers/utils';
import { useOverviewGroupById } from '../queries/groups';
import {
  GUIDE_KEYS,
  OVERVIEW_KEYS,
  useOverviewDocumentById,
} from '../queries/guides';
import { Cover, GuidesResponse, Guide, GuideType } from '../types';
import { useAssignThemesetToGuide } from './themesets';
import { track } from '../helpers/analytics';
import { queryClient } from '../constants/app';
import {
  getOrderByFromLocalStorage,
  getOrderTypeFromLocalStorage,
} from '../helpers/browser';
import { useDefaultThemeset, useGuideThemesetId } from '../queries/themesets';
import { handleMutationSuccess, updateGuideVersion } from './helpers';
import { retrieveAssetInfoApi } from '../helpers/api/uploadApi';
import { useModalStore } from '../stores/modalStore';

interface BaseGuideMutation {
  guideId: string;
}

interface EditGuideTitleOptions {
  guideTitle: string;
}

export const useGuideMutations = (guideId: string) => {
  const { version: guideVersion } = useOverviewDocumentById(guideId) || {};
  const toggleIsCreatingGuide = useModalStore.use.toggleIsCreatingGuide();
  const closeModal = useModalStore.use.closeModal();
  const { translate } = useTranslate();
  const newGuideId = generateUUID();
  const newGuideVersion = 0;

  return {
    updateGuideCover: useMutation(
      (options: { mediaId: string }) => {
        return retrieveAssetInfoApi(options.mediaId);
      },
      {
        retry: true,
        onMutate: () => {
          updateGuideVersion(guideId!);
        },

        onSuccess: data => {
          track('Media uploaded from guide cover');
          optimisticallyUpdateGuideCover(guideId!, {
            assetId: data.id,
            assetName: data.name,
            detailUrl: `/media/?mediaId=${data.id}`,
            imageUrl: data.original,
          });
          setGuideCover(
            guideId,
            {
              assetId: data.id,
              assetName: data.name,
              detailUrl: `/media/?mediaId=${data.id}`,
              imageUrl: data.original,
            },
            guideVersion,
          );
        },
      },
    ),
    createGuide: useMutation(
      (createGuideOptions: { guideTitle: string }) =>
        createGuideWithTemplateApi(
          newGuideId,
          createGuideOptions.guideTitle,
          generateUUID(),
          translate('GUIDE.UNTITLED_CHAPTER'),
          generateUUID(),
          translate('GUIDE.UNTITLED_PAGE'),
          generateUUID(),
          newGuideVersion,
          GuideType.MULTI_PAGE,
        ),
      {
        onMutate: () => {
          toggleIsCreatingGuide(true);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Created', data, {
            entityId: newGuideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
          });
        },
      },
    ),
    deleteGuide: useMutation(
      (options: BaseGuideMutation) => {
        return deleteGuide(options.guideId, guideVersion);
      },
      {
        onMutate: data => {
          optiomisticallyDeleteGuide(data.guideId);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Deleted', data, {
            entityId: guideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.DELETE_GUIDE'),
          });
        },
      },
    ),

    addGuideToGroup: '',
    removeGuideFromGroup: '',
    duplicateGuide: '',
    duplicateGuideInGroup: '',
    editGuideTitle: useMutation(
      (editGuideTitleOptions: EditGuideTitleOptions) => {
        return updateGuideTitle(
          guideId,
          editGuideTitleOptions.guideTitle,
          guideVersion,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateGuideTitle(guideId, variables.guideTitle);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Edited', data, {
            entityId: guideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.RENAMING_GUIDE'),
          });
        },
      },
    ),
    featureGuide: useMutation(
      () => {
        return featureGuide(guideId, guideVersion);
      },
      {
        onMutate: () => {
          updateGuideVersion(guideId);
          optimisticallyToggleFeatureGuide(guideId, true);
        },

        onSuccess: data => {
          handleMutationSuccess('Guide Featured', data, {
            entityId: guideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            message: translate(
              'OVERVIEW_CARD_MUTATION_MESSAGE.FEATURING_GUIDE',
            ),
          });
        },
      },
    ),
    unFeatureGuide: useMutation(
      () => {
        return unFeatureGuide(guideId, guideVersion);
      },
      {
        onMutate: () => {
          updateGuideVersion(guideId);
          optimisticallyToggleFeatureGuide(guideId, false);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Unfeatured', data, {
            entityId: guideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            message: translate(
              'OVERVIEW_CARD_MUTATION_MESSAGE.UNFEATURING_GUIDE',
            ),
          });
        },
      },
    ),
    setGuideCover: useMutation(
      (setGuideCoverOptions: SetGuideCoverOptions) => {
        return setGuideCover(
          guideId,
          setGuideCoverOptions.guideCover,
          guideVersion,
        );
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateGuideCover(guideId, variables.guideCover);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Edited', data, {
            entityId: guideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.SET_COVER'),
          });
        },
      },
    ),
    removeGuideCover: useMutation(
      (removeGuideCoverOptions: BaseGuideMutation) => {
        return removeGuideCover(removeGuideCoverOptions.guideId, guideVersion);
      },
      {
        onMutate: () => {
          updateGuideVersion(guideId);
          optimisticallyRemoveGuideCover(guideId);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Edited', data, {
            entityId: guideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.SET_COVER'),
          });
        },
      },
    ),
    enableTargetedPages: useMutation(
      (enableTargetedPagesOptions: BaseGuideMutation) => {
        return enableTargetedPages(enableTargetedPagesOptions.guideId, 0);
      },
      {
        onMutate: () => {
          updateGuideVersion(guideId);
          optimisticallyToggleTargetedPages(guideId, true);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Edited', data, {
            entityId: guideId,
          });
        },
      },
    ),
    disableTargetedPages: useMutation(
      (disableTargetedPagesOptions: BaseGuideMutation) => {
        return disableTargetedPages(disableTargetedPagesOptions.guideId, 0);
      },
      {
        onMutate: () => {
          updateGuideVersion(guideId);
          optimisticallyToggleTargetedPages(guideId, false);
        },
        onSuccess: data => {
          handleMutationSuccess('Guide Edited', data, {
            entityId: guideId,
          });
          closeModal();
        },
      },
    ),
  };
};

interface SetGuideCoverOptions {
  guideCover: Cover;
}

export const useCreateGuide = () => {
  const { translate } = useTranslate();
  const newGuideId = generateUUID();
  const assignThemesetToGuideMutation = useAssignThemesetToGuide();
  const newGuideVersion = 0;
  const toggleIsCreatingGuide = useModalStore.use.toggleIsCreatingGuide();

  return useMutation(
    (createGuideOptions: { guideTitle: string; themesetId: string }) =>
      createGuideWithTemplateApi(
        newGuideId,
        createGuideOptions.guideTitle,
        generateUUID(),
        translate('GUIDE.UNTITLED_CHAPTER'),
        generateUUID(),
        translate('GUIDE.UNTITLED_PAGE'),
        generateUUID(),
        newGuideVersion,
        GuideType.MULTI_PAGE,
      ),
    {
      onMutate: () => {
        toggleIsCreatingGuide(true);
      },
      onSuccess: (data, variables) => {
        if (variables.themesetId) {
          assignThemesetToGuideMutation.mutate({
            guideId: newGuideId,
            themesetId: variables.themesetId,
          });
        }
        handleMutationSuccess('Guide Created', data, {
          entityId: newGuideId,
          queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
        });
      },
    },
  );
};

export const useAddGuideToGroup = (guideId: string, groupId: string) => {
  const { translate } = useTranslate();
  const group = useOverviewGroupById(groupId);

  return useMutation(
    () => {
      return addGuideToGroup(groupId, guideId, group?.version ?? 0);
    },
    {
      onSuccess: data => {
        handleMutationSuccess('Guide Added to a Group', data, {
          entityId: guideId,
          message: translate(
            'OVERVIEW_CARD_MUTATION_MESSAGE.GUIDE_ADD_TO_GROUP',
          ),
          queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
        });
      },
    },
  );
};

export const useRemoveGuideFromGroup = (guideId: string, groupId: string) => {
  const { translate } = useTranslate();
  const { version: guideVersion } = useOverviewDocumentById(guideId) || {};
  const { version: groupVersion } = useOverviewGroupById(groupId) || {};

  return useMutation(
    () =>
      deleteGuideInsideGroup(
        groupId,
        groupVersion ?? 0,
        guideId,
        guideVersion ?? 0,
      ),
    {
      onSuccess: data => {
        handleMutationSuccess('Guide Deleted', data, {
          entityId: guideId,
          queryPath: OVERVIEW_KEYS.OVERVIEW_GROUPS,
          message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.DELETE_GUIDE'),
        });
      },
    },
  );
};

export const useDeleteGuideFromGroup = (groupId: string) => {
  const { translate } = useTranslate();
  const group = useOverviewGroupById(groupId);

  return useMutation(
    (deleteGuideFromGroupOptions: BaseGuideMutation) => {
      const { guideId } = deleteGuideFromGroupOptions;

      return deleteGuideFromGroup(groupId, group?.version ?? 0, guideId);
    },
    {
      onSuccess: (data, variables) => {
        handleMutationSuccess('Guide Removed from Group', data, {
          entityId: variables.guideId,
          message: translate(
            'OVERVIEW_CARD_MUTATION_MESSAGE.GUIDE_REMOVE_FROM_GROUP',
          ),
          queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
        });
      },
    },
  );
};

interface DuplicateGuideOptions {
  newTitle: string;
  newGuideId: string;
}

export const useDuplicateGuide = (guideId: string, guideType: GuideType) => {
  const { translate } = useTranslate();
  const { version: sourceGuideVersion } =
    useOverviewDocumentById(guideId) || {};
  const { data: sourceGuideThemesetId } = useGuideThemesetId(guideId);
  const defaultThemeset = useDefaultThemeset();
  const assignThemesetToGuideMutation = useAssignThemesetToGuide();

  return useMutation(
    async (duplicateGuideOptions: DuplicateGuideOptions) => {
      const { newTitle, newGuideId } = duplicateGuideOptions;
      const newGuideVersion = 1;
      const res = await duplicateGuideApi(newGuideId, newGuideVersion, {
        sourceGuideId: guideId,
        sourceGuideVersion: sourceGuideVersion ?? 0,
        guideTitle: newTitle,
      });

      return res;
    },
    {
      onSuccess: (data, variables: DuplicateGuideOptions) => {
        assignThemesetToGuideMutation.mutate({
          guideId: variables.newGuideId,
          themesetId: sourceGuideThemesetId ?? defaultThemeset?.id ?? '',
        });
        handleMutationSuccess(
          guideType === GuideType.MULTI_PAGE
            ? 'Guide Duplicated'
            : 'Page Duplicated',
          data,
          {
            entityId: guideId,
            message: translate(
              'OVERVIEW_CARD_MUTATION_MESSAGE.DUPLICATE_GUIDE',
              { type: guideType === GuideType.MULTI_PAGE ? 'guide' : 'page' },
            ),
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
          },
        );
      },
    },
  );
};

export const useDuplicateGuideInsideGroup = (
  guideId: string,
  groupId: string,
) => {
  const { translate } = useTranslate();

  const { version: sourceGuideVersion } =
    useOverviewDocumentById(guideId) || {};
  const { version: groupVersion } = useOverviewGroupById(groupId) || {};
  const { data: sourceGuideThemesetId } = useGuideThemesetId(guideId);
  const defaultThemeset = useDefaultThemeset();
  const assignThemesetToGuideMutation = useAssignThemesetToGuide();

  return useMutation(
    async (duplicateGuideGroupOptions: DuplicateGuideOptions) => {
      const { newTitle, newGuideId } = duplicateGuideGroupOptions;
      const newGuideVersion = 1;
      const res = await duplicateGuideInsideGroupApi(
        newGuideId,
        newGuideVersion,
        {
          sourceGuideId: guideId,
          sourceGuideVersion: sourceGuideVersion ?? 0,
          guideTitle: newTitle,
          groupId,
          groupVersion: groupVersion ?? 0,
        },
      );

      return res;
    },
    {
      onSuccess: (data, variables) => {
        assignThemesetToGuideMutation.mutate({
          guideId: variables.newGuideId,
          themesetId: sourceGuideThemesetId ?? defaultThemeset?.id ?? '',
        });

        handleMutationSuccess('Guide Duplicated', data, {
          entityId: guideId,
          message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.DUPLICATE_GUIDE'),
          queryPath: OVERVIEW_KEYS.OVERVIEW_GROUPS,
        });
      },
    },
  );
};

const optimisticallyUpdateGuideTitle = (
  guideId: string,
  guideTitle: string,
) => {
  queryClient.setQueryData(
    [
      OVERVIEW_KEYS.OVERVIEW_GUIDES,
      getOrderByFromLocalStorage(),
      getOrderTypeFromLocalStorage(),
    ],
    (old: GuidesResponse) =>
      produce(old, draft => ({
        allGuides: draft.allGuides.map(guide =>
          guide.id === guideId ? { ...guide, title: guideTitle } : guide,
        ),
        featuredGuides: draft.featuredGuides.map(guide =>
          guide.id === guideId ? { ...guide, title: guideTitle } : guide,
        ),
      })),
  );

  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.title = guideTitle;
      });
    }

    return old;
  });
};
const optimisticallyUpdateGuideCover = (guideId: string, guideCover: Cover) => {
  queryClient.setQueryData(
    [
      OVERVIEW_KEYS.OVERVIEW_GUIDES,
      getOrderByFromLocalStorage(),
      getOrderTypeFromLocalStorage(),
    ],
    (old: GuidesResponse) =>
      produce(old, draft => ({
        allGuides: draft.allGuides.map(guide =>
          guide.id === guideId ? { ...guide, cover: { ...guideCover } } : guide,
        ),
        featuredGuides: draft.featuredGuides.map(guide =>
          guide.id === guideId ? { ...guide, cover: { ...guideCover } } : guide,
        ),
      })),
  );

  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.cover = { ...guideCover };
      });
    }

    return old;
  });
};

const optimisticallyToggleFeatureGuide = (
  guideId: string,
  isFeatured: boolean,
) => {
  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.isFeatured = isFeatured;
      });
    }

    return old;
  });
};

const optimisticallyToggleTargetedPages = (
  guideId: string,
  isEnabled: boolean,
) => {
  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.targetedPagesEnabled = isEnabled;
      });
    }

    return old;
  });
};

const optiomisticallyDeleteGuide = (guideId: string) => {
  queryClient.setQueryData(
    [
      OVERVIEW_KEYS.OVERVIEW_GUIDES,
      getOrderByFromLocalStorage(),
      getOrderTypeFromLocalStorage(),
    ],
    (old: GuidesResponse) =>
      produce(old, draft => ({
        allGuides: draft.allGuides.filter(guide => guide.id !== guideId),
        featuredGuides: draft.featuredGuides.filter(
          guide => guide.id !== guideId,
        ),
      })),
  );
};

const optimisticallyRemoveGuideCover = (guideId: string) => {
  queryClient.setQueryData(
    [
      OVERVIEW_KEYS.OVERVIEW_GUIDES,
      getOrderByFromLocalStorage(),
      getOrderTypeFromLocalStorage(),
    ],
    (old: GuidesResponse) =>
      produce(old, draft => {
        const guide = draft.allGuides.find(guide => guide.id === guideId);
        const featuredGuide = draft.featuredGuides.find(
          guide => guide.id === guideId,
        );

        if (guide) {
          guide.cover = {
            assetId: null,
            assetName: null,
            imageUrl: null,
            detailUrl: null,
          };
        }
        if (featuredGuide) {
          featuredGuide.cover = {
            assetId: null,
            assetName: null,
            imageUrl: null,
            detailUrl: null,
          };
        }
        return draft;
      }),
  );
  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.cover = {
          assetId: null,
          assetName: null,
          imageUrl: null,
          detailUrl: null,
        };
      });
    }

    return old;
  });
};
