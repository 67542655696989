import React from 'react';
import styled from 'styled-components';
import { IconCheck } from '@bynder/icons';
import {
  Button,
  Dropdown,
  List,
  token,
  TruncatedText,
} from '@bynder/design-system';
import { useTranslate } from '@bynder/localization';
import { TypographyHeading } from '../../../types/typography';
import { StyledText } from '../../shared/styled-components/Themesets';
import themesetQueries from '../../../queries/themesets';

interface Props {
  expanded: boolean;
  onExpandEvent: () => void;
  onChange: (selectedHeading: TypographyHeading) => void;
  currentState: { blockType: TypographyHeading };
}

const TypographyPicker: React.FC<Props> = ({
  currentState,
  onChange,
  onExpandEvent,
  expanded,
}) => {
  const themesetTypographies = themesetQueries.getThemesetTypographies();
  const { translate } = useTranslate();
  const handleClick = (selectedHeading: TypographyHeading) => {
    onChange(selectedHeading);
  };

  return (
    <StyledTypoPicker>
      <StyledDarkButton
        isPressed={expanded}
        onClick={onExpandEvent}
        variant="clean"
        rightIcon={<Dropdown.Arrow />}
      >
        <TruncatedText>
          {themesetTypographies[currentState.blockType]?.label || 'Multiple'}
        </TruncatedText>
      </StyledDarkButton>
      {expanded && (
        <ListContainer>
          <List isContained>
            {Object.values(themesetTypographies).map(item => (
              <List.Item
                key={item.heading}
                rightElements={
                  currentState.blockType === item.heading && <IconCheck />
                }
                onClick={() => handleClick(item.heading)}
                subtext={`${
                  item.heading === TypographyHeading.H2
                    ? `${translate('THEMESET.ANCHOR_LINK_TEXT')} • `
                    : ''
                }${item.fontFamily} • ${item.fontSize}px`}
              >
                <StyledText $heading={item.heading} $hasSize={false}>
                  {item.label}
                </StyledText>
              </List.Item>
            ))}
          </List>
        </ListContainer>
      )}
    </StyledTypoPicker>
  );
};

export default TypographyPicker;

const ListContainer = styled.div`
  top: 53px;
  position: absolute;
  padding: ${token.spacing3} 0;
  border-radius: ${token.radiusBase};
  background: ${token.gray10};
  box-shadow: ${token.elevationStatic}, ${token.elevation2};
  text-transform: capitalize;
  max-width: 336px;
`;

const StyledDarkButton = styled(Button)`
  max-width: 141px;
  background-color: ${token.gray50a};
  color: ${token.gray75};
  :hover {
    color: ${token.gray10} !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
`;

const StyledTypoPicker = styled.div`
  padding-right: ${token.spacing2};
  border-right: 1px solid rgba(255, 255, 255, 0.1);
`;
