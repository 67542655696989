import { Button, ModalBase } from '@bynder/design-system';
import PrimaryButton from '../shared/PrimaryButton';
import { Translate, useTranslate } from '@bynder/localization';

interface HomepageModalProps {
  onSubmit: () => void;
  onCancel: () => void;
}

const UnsetHomepageModal = ({
  onSubmit,
  onCancel,
}: HomepageModalProps) => {
  const { translate } = useTranslate();

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={translate('UNSET_HOMEPAGE_MODAL.HEADER_TEXT')} />
      <ModalBase.Content>
        <Translate id="UNSET_HOMEPAGE_MODAL.CONTENT_TEXT" />
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton data-testid="submitButton" onClick={onSubmit}>
            <Translate id="UNSET_HOMEPAGE_MODAL.BUTTON_TEXT" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="UNSET_HOMEPAGE_MODAL.CANCEL_TEXT" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default UnsetHomepageModal;
