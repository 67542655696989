import {
  AddNewPageToChapterCommand,
  Command,
  DuplicatePageCommand,
  EditBackgroundColorInPageHeaderCommand,
  EditPageAccessCommand,
  EditPageHeaderTextColorCommand,
  MovePageCommand,
  UnpublishPageCommand,
} from '../../types/commands';
import { EntityVisibility } from '../../types';
import { LegacyModificationGroup } from '../permissions';

export const getCommand = (aggregateId: string, aggregateVersion: number) => ({
  addNewPageToChapter: ({
    chapterId,
    pageId,
    pageTitle,
  }: {
    chapterId: string;
    pageId: string;
    pageTitle: string;
  }): AddNewPageToChapterCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.ADD_NEW_PAGE_TO_CHAPTER,
    payload: {
      chapterId,
      pageId,
      pageTitle,
    },
  }),

  duplicatePage: ({
    from,
    to,
    deletePage,
  }: {
    from: { chapterId: string; pageId: string };
    to: {
      guideId: string;
      guideVersion: number;
      chapterId: string;
      pageId: string;
    };
    deletePage: boolean;
  }): DuplicatePageCommand => ({
    aggregateId: to.guideId,
    aggregateVersion: to.guideVersion,
    command: Command.MOVE_PAGE_TO_GUIDE,
    payload: {
      deletePage,
      from: {
        guideId: aggregateId,
        guideVersion: aggregateVersion,
        chapterId: from.chapterId,
        pageId: from.pageId,
      },
      to: {
        chapterId: to.chapterId,
        pageId: to.pageId,
      },
    },
  }),
  movePageToIndex: (
    newIndex: number,
    pageId: string,
    fromChapterId: string,
    toChapterId: string,
  ): MovePageCommand => ({
    command: Command.MOVE_PAGE,
    aggregateId,
    aggregateVersion,
    payload: {
      pageId,
      from: { chapterId: fromChapterId },
      to: { chapterId: toChapterId, position: newIndex },
    },
  }),
  editPageAccess: ({
    chapterId,
    pageId,
    pageVisibility,
    editAccessChanges,
    viewAccessChanges,
  }: {
    chapterId: string;
    pageId: string;
    pageVisibility: EntityVisibility;
    viewAccessChanges: LegacyModificationGroup;
    editAccessChanges: LegacyModificationGroup;
  }): EditPageAccessCommand => ({
    command: Command.EDIT_PAGE_ACCESS,
    aggregateId,
    aggregateVersion,
    payload: {
      chapterId,
      pageId,
      viewAccessChanges,
      editAccessChanges,
      pageVisibility,
    },
  }),
  unpublishPage: ({
    pageId,
    chapterId,
  }: {
    pageId: string;
    chapterId: string;
  }): UnpublishPageCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.UNPUBLISH_PAGE,
    payload: {
      guideId: aggregateId,
      chapterId,
      pageId,
    },
  }),
  editBackgroundColorInPageHeader: ({
    chapterId,
    pageId,
    pageHeaderBackgroundColorId,
  }: {
    chapterId: string;
    pageId: string;
    pageHeaderBackgroundColorId: string | null;
  }): EditBackgroundColorInPageHeaderCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.EDIT_BACKGROUND_COLOR_IN_PAGE_HEADER,
    payload: {
      guideId: aggregateId,
      chapterId,
      pageId,
      pageHeaderBackgroundColorId,
    },
  }),
  editPageHeaderTextColor: ({
    chapterId,
    pageId,
    pageHeaderTextColorId,
  }: {
    chapterId: string;
    pageId: string;
    pageHeaderTextColorId: string | null;
  }): EditPageHeaderTextColorCommand => ({
    aggregateId,
    aggregateVersion,
    command: Command.EDIT_TEXT_COLOR_IN_PAGE_HEADER,
    payload: {
      guideId: aggregateId,
      chapterId,
      pageId,
      pageHeaderTextColorId,
    },
  }),
});
