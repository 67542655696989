import React from 'react';
import { isEqual } from 'lodash';
import { IconLink } from '@bynder/icons';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  List,
  ModalBase,
} from '@bynder/design-system';
import { Translate } from '@bynder/localization';
import { AccessModalDropdown } from './AccessModalDropdown';
import PrimaryButton from '../shared/PrimaryButton';
import { AccessModalSearch } from './AccessModalSearch';
import { useModuleTier } from '../../helpers/hooks';
import { useEntityPermission } from '../../queries/permissions';
import { useEditAccess } from '../../mutations/permissions';
import { useModalStore } from '../../stores/modalStore';
import { AccessModalIdentityList } from './AccessModalIdentityList';
import SkeletonElement from '../shared/styled-components/SkeletonElement';
import { EntityVisibility } from '../../types';

export interface AccessModalProps {
  onCancel: () => void;
  entityId: string;
  headerText: string;
  onCopyUrl?: () => void;
  activeGuideId?: string;
}

export const AccessModal: React.FC<AccessModalProps> = ({
  onCancel,
  entityId,
  headerText,
  onCopyUrl,
  activeGuideId,
}) => {
  const closeModal = useModalStore.use.closeModal();

  const { isBasic } = useModuleTier();
  const {
    data: entityPermissions,
    isLoading,
    isError,
  } = useEntityPermission(entityId);

  const [hasChanges, setHasChanges] = React.useState(false);
  const [documentVisibility, setDocumentVisibility] = React.useState(
    entityPermissions?.documentVisibility || EntityVisibility.SPECIFIC,
  );
  const [permissions, setPermissions] = React.useState(
    entityPermissions?.permissions || [],
  );
  React.useEffect(() => {
    if (!isLoading && entityPermissions) {
      setDocumentVisibility(entityPermissions.documentVisibility);
      setPermissions(entityPermissions.permissions);
    }
  }, [isLoading, entityPermissions]);

  React.useEffect(() => {
    if (
      documentVisibility !== entityPermissions?.documentVisibility ||
      !isEqual(entityPermissions?.permissions, permissions)
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [
    documentVisibility,
    entityPermissions?.documentVisibility,
    entityPermissions?.permissions,
    permissions,
  ]);

  const editAccess = useEditAccess(
    entityId,
    activeGuideId,
    entityPermissions?.documentType,
  );

  const onHandleClick = () => {
    editAccess.mutate({
      entityVisibility: documentVisibility,
      identityList: permissions,
      closeModal: true,
    });
  };

  const handleCopyLink = () => {
    if (onCopyUrl) {
      onCopyUrl();
    }
  };

  if (isError) {
    closeModal();
  }
  if (isLoading || !entityPermissions) {
    return null;
  }

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header title={headerText} />
      <ModalBase.Content>
        <Flex direction="column" gap="3">
          <Form.Row>
            {isLoading ? (
              <DropdownSkeleton />
            ) : (
              <AccessModalDropdown
                activeGuideId={activeGuideId}
                entityVisibility={documentVisibility}
                isListEmpty={permissions?.length === 0}
                handleChangeVisibility={setDocumentVisibility}
                documentType={entityPermissions?.documentType}
              />
            )}
          </Form.Row>
          {!isBasic && (
            <Form.Row>
              <AccessModalSearch
                ownerId={entityPermissions?.documentOwnerId}
                identityList={permissions}
                onSelectIdentity={setPermissions}
              />
            </Form.Row>
          )}
          <Form.Row>
            <AccessModalIdentityList
              documentOwnerId={entityPermissions?.documentOwnerId}
              identityList={permissions}
              onEditIdentity={setPermissions}
            />
          </Form.Row>
        </Flex>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            isLoading={editAccess.isLoading}
            onClick={onHandleClick}
            isDisabled={!hasChanges}
          >
            {editAccess.isLoading ? (
              <Translate id="ACCESS_MODAL.MODAL_ACTIONS_SAVING_BUTTON_TEXT" />
            ) : (
              <Translate id="ACCESS_MODAL.MODAL_ACTIONS_SAVE_BUTTON_TEXT" />
            )}
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="ACCESS_MODAL.MODAL_ACTIONS_CANCEL_BUTTON_TEXT" />
          </Button>
        }
        actionTertiary={
          onCopyUrl && (
            <Button
              icon={<IconLink />}
              variant="secondary"
              onClick={handleCopyLink}
            >
              <Translate id="SHARE_MODAL.BUTTON_TEXT" />
            </Button>
          )
        }
      />
    </ModalBase>
  );
};

const DropdownSkeleton: React.FC = () => {
  return (
    <List.Item
      thumbnail={<SkeletonElement width={40} height={40} />}
      subtext={<SkeletonElement width={200} height={12} />}
      isSelected={false}
      rightElements={<Dropdown.Arrow />}
      isDisabled
      data-testid="SkeletonDropdown"
    >
      <SkeletonElement width={100} />
    </List.Item>
  );
};
