import { Typography } from './typography';
import { Color } from './color';
import { Font } from '../constants/themesets';

export interface ColorSet {
  id: string;
  label: string;
  lastModifiedTime: string;
  creationTime: string;
  colors: Color[];
}
export interface NavigationBar {
  backgroundColor: Color | null;
  borderColor: Color;
  textColor: Color | null;
  fontFamily: string;
  fontStyle: string;
  fontSize: number;
  creationTime?: string;
  lastModifiedTime?: string;
}

export enum BackButtonBehavior {
  BROWSER = 'BROWSER',
  CUSTOM = 'CUSTOM',
  OVERVIEW = 'OVERVIEW',
}

export interface GuideProperties {
  landingPageHeaderEnabled: boolean;
  pageHeaderEnabled: boolean;
  customLogoEnabled: boolean;
  logoId: string | null;
  logoUrl: string | null;
  backButton: boolean;
  backButtonBehavior: {
    behavior: BackButtonBehavior;
    link: string | null;
  };
}

export interface PageHeader {
  backgroundColor: Color | null;
  textColor: Color | null;
}

export interface Sections {
  backgroundColor: Color | null;
}

export interface ThemesetCard {
  titleColor: Color | null;
  descriptionColor: Color | null;
  borderColor: Color | null;
}

export interface ThemesetButton {
  colors: {
    default: {
      textColor: Color | null;
      backgroundColor: Color | null;
      borderColor: Color | null;
    };
    hover: {
      textColor: Color | null;
      backgroundColor: Color | null;
      borderColor: Color | null;
    };
  };
}

export interface Sidebar {
  backgroundColor: Color | null;
  borderColor: Color | null;
  lastModifiedTime?: string;
  chapter: {
    fontFamily: string;
    fontStyle: string;
    fontSize: number;
    colors: {
      text: { normal: Color | null; hover: Color | null };
      background: { hover: Color | null };
    };
  };
  page: {
    fontFamily: string;
    fontStyle: string;
    fontSize: number;
    colors: {
      text: { normal: Color | null; hover: Color | null; active: Color | null };
      background: { hover: Color | null; active: Color | null };
    };
  };
  tableOfContents: {
    fontFamily: string;
    fontStyle: string;
    fontSize: number;
    colors: {
      text: { normal: Color | null; hover: Color | null; active: Color | null };
      background: { hover: Color | null; active: Color | null };
      accent: {
        normal: Color | null;
        hover: Color | null;
        active: Color | null;
      };
    };
  };
}

export enum ThemesetCategory {
  BYNDER_THEME_SET = 'BYNDER_THEME_SET',
  PORTAL_THEME_SET = 'PORTAL_THEME_SET',
  CUSTOM_THEME_SET = 'CUSTOM_THEME_SET',
}

export interface Themeset {
  id: string;
  label: string;
  description: string;
  cover: ThemesetCover;
  accountId: string;
  ownerId: string;
  creationTime: string;
  lastModifiedTime: string;
  navigationBar: NavigationBar;
  sideBar: Sidebar;
  pageHeader: PageHeader;
  pageSection: Sections;
  widgets: {
    card: ThemesetCard;
    button: ThemesetButton;
  };
  colorSets: ColorSet[];
  fonts?: Font[];
  category: ThemesetCategory;
  typographies: Typography[];
  default?: {
    textColorId: string;
    backGroundColorId: string;
  };
  isDefault: boolean;
  properties: GuideProperties;
}

export interface ThemesetDetails {
  label: string;
  description: string;
  backgroundColor: string;
  backgroundImageUrl: string;
}

interface ThemesetCover {
  backgroundColor: string;
  backgroundImageUrl: string | null;
}

export interface PortalInformation {
  domain: string;
  logo: string;
  logo_link: string;
  name: string;
  portal_id: string;
}
