import { useMutation } from '@tanstack/react-query';
import {
  setHomepagePortalApi,
  unsetHomepagePortalApi,
} from '../helpers/api/portalApi';
import { handleMutationSuccess } from './helpers';
import { setHomepageApi, unsetHomepageApi } from '../helpers/api/guidelinesApi';
import { useGetCurrentHomepage } from '../queries/homepage';
import { notify } from '@bynder/design-system';
import {
  HomepageSetNotification,
  HomepageUnsetNotification,
} from '../types/notifications';
import { queryClient } from '../constants/app';
import { GUIDE_KEYS } from '../queries/guides';
import { Guide } from '../types';
import { produce } from 'immer';

export const useHomepageMutations = () => {
  const { data: currentHomepage } = useGetCurrentHomepage();

  const setHomepageCommand = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      setHomepageApi(
        currentHomepage,
        mutationOptions.guideId,
        mutationOptions.guideVersion,
      ),
    {
      onSuccess: (data, variables) => {
        handleMutationSuccess('Landing page set as homepage', data, {
          entityId: variables.guideId,
        });
        notify(HomepageSetNotification);
        optimisticallyPublishHomepage(variables.guideId);
      },
    },
  );

  const setHomepage = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      setHomepagePortalApi(mutationOptions.guideId),
    {
      onSuccess: (_, variables) => {
        setHomepageCommand.mutate({
          guideId: variables.guideId,
          guideVersion: variables.guideVersion,
        });
      },
    },
  );

  const unsetHomepage = useMutation(
    (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: { guideId: string; guideVersion: number },
    ) => unsetHomepagePortalApi(),
    {
      onSuccess: (_, variables) => {
        unsetHomepageCommand.mutate({
          guideId: variables.guideId,
          guideVersion: variables.guideVersion,
        });
      },
    },
  );

  const unsetHomepageCommand = useMutation(
    (mutationOptions: { guideId: string; guideVersion: number }) =>
      unsetHomepageApi(mutationOptions.guideId, mutationOptions.guideVersion),
    {
      onSuccess: (data, variables) => {
        handleMutationSuccess('Landing page unset as homepage', data, {
          entityId: variables.guideId,
        });
      },
      onMutate: () => {
        notify(HomepageUnsetNotification);
      },
    },
  );

  return { setHomepageCommand, setHomepage, unsetHomepage };
};

const optimisticallyPublishHomepage = (guideId: string) => {
  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      return produce(old, draft => {
        draft.isHomepage = true;
      });
    }

    return old;
  });
};
