import { DefaultTheme, css } from 'styled-components';
import { defaultBynderThemeset } from '../../../constants/themesets';
import { TypographyHeading } from '../../../types/typography';

export const DEFAULT_FONT = 'Source Sans Pro';
export const DEFAULT_STYLE = 'Not defined';

export const getTypographyWeight = (
  theme: DefaultTheme,
  fontFamily: string,
  fontStyle: string,
) => {
  const typographyStyle = theme?.availableFonts
    ?.find(font => font.label === fontFamily)
    ?.styles.find(style => style.label === fontStyle);

  // need to cover some old use cases where fontStyle is not the labeled one
  return typographyStyle?.weight || fontStyle;
};

export const fontNormal = css`
  font-family: '${props => props.theme.fontNormal?.fontFamily}', ${DEFAULT_FONT};
  font-weight: ${props => props.theme.fontNormal?.fontWeight};
`;

export const getTypography = (
  heading: TypographyHeading,
  theme: DefaultTheme,
  hasSize = true,
  hasLineHeight = true,
) => {
  const { themesetTypographies } = theme;
  const typography = themesetTypographies[heading];

  const typographyWeight = getTypographyWeight(
    theme,
    typography.fontFamily,
    typography.fontStyle,
  );

  return hasSize
    ? css`
        font-family: '${typography?.fontFamily}', ${DEFAULT_FONT} !important;
        font-weight: ${typographyWeight} !important;
        font-size: ${typography?.fontSize}px !important;
        letter-spacing: ${typography?.letterSpacing === null
          ? 'normal'
          : typography.letterSpacing} !important;
        line-height: ${typography?.lineHeight === null || !hasLineHeight
          ? 'normal'
          : typography.lineHeight} !important;
        color: ${typography.fontColor?.hexCode ||
        defaultBynderThemeset.typographies[0].fontColor!.hexCode};
      `
    : css`
        font-family: '${typography?.fontFamily}', ${DEFAULT_FONT} !important;
        font-weight: ${typographyWeight} !important;
      `;
};
