import React from 'react';
import {
  Button,
  Form,
  Input,
  ModalBase,
  Select,
  Thumbnail,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';
import { useThemesets } from '../../queries/themesets';
import { ActiveSplits, useReleaseToggle } from '../../helpers/releaseToggle';

interface NewCreateModalProps {
  entityType: EntityEnum;
  onCancel: () => void;
  onSubmit: (title: string, type: EntityEnum, themesetId?: string) => void;
  maxLength?: number;
}

export const enum EntityEnum {
  LANDING_PAGE = 'LANDING_PAGE',
  GUIDE = 'GUIDE',
  GROUP = 'GROUP',
}

const NewCreateModal: React.FC<NewCreateModalProps> = ({
  entityType,
  onCancel,
  onSubmit,
  maxLength,
}) => {
  const { translate } = useTranslate();
  const [title, setTitle] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { data: themesets } = useThemesets();
  const defaultThemeset = themesets?.find(themeset => themeset.isDefault);
  const [selectedThemesetId, setSelectedThemesetId] = React.useState<string>(
    defaultThemeset?.id || '',
  );
  const { data: isSplitActive } = useReleaseToggle(
    ActiveSplits.themedTemplates,
  );

  React.useEffect(() => {
    (document.activeElement as HTMLElement).blur();

    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && title) {
      onSubmit(title, entityType, selectedThemesetId);
    }
  };

  const modalTitle = {
    [EntityEnum.GROUP]: translate('CREATE_MODAL.NEW_GROUP_HEADER_TEXT'),
    [EntityEnum.GUIDE]: translate('CREATE_MODAL.NEW_GUIDE_HEADER_TEXT'),
    [EntityEnum.LANDING_PAGE]: translate('CREATE_MODAL.NEW_PAGE_HEADER_TEXT'),
  };

  const modalDescription = {
    [EntityEnum.GROUP]: translate('CREATE_MODAL.NEW_GROUP_BUTTON_TEXT'),
    [EntityEnum.GUIDE]: translate('CREATE_MODAL.NEW_GUIDE_BUTTON_TEXT'),
    [EntityEnum.LANDING_PAGE]: translate('CREATE_MODAL.NEW_PAGE_BUTTON_TEXT'),
  };

  const selectedThemeset = themesets?.find(
    themeset => themeset.id === selectedThemesetId,
  );

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel}>
      <ModalBase.Header
        title={modalTitle[entityType]}
        secondaryInfo={modalDescription[entityType]}
      />
      <ModalBase.Content>
        <Form.Group>
          <Form.Label htmlFor="modalInput">Title</Form.Label>
          <div>
            <Input
              onKeyDown={handleKeyPress}
              inputRef={inputRef}
              id="modalInput"
              placeholder="Enter a title"
              value={title}
              onChange={setTitle}
              data-testid="modalInput"
              maxLength={maxLength}
              iconRight={
                <MaxCharacterCounter value={title} maxValue={maxLength} />
              }
            />
          </div>
        </Form.Group>
        {isSplitActive && entityType === EntityEnum.GUIDE && (
          <Form.Group>
            <Form.Label htmlFor="themesetSelect">Theme</Form.Label>
            <Select
              aria-label="Select a themeset"
              value={selectedThemesetId}
              onChange={setSelectedThemesetId}
              label={selectedThemeset?.label}
              icon={
                <Thumbnail
                  variant="content"
                  shape="circle"
                  size="xs"
                  backgroundColor={selectedThemeset?.cover?.backgroundColor}
                />
              }
            >
              {themesets.map(themeset => (
                <Select.Item
                  key={themeset.id}
                  value={themeset.id}
                  icon={
                    <Thumbnail
                      variant="content"
                      shape="circle"
                      size="xs"
                      backgroundColor={themeset?.cover?.backgroundColor}
                    />
                  }
                >
                  {themeset.label}
                </Select.Item>
              ))}
            </Select>
          </Form.Group>
        )}
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            isDisabled={title === ''}
            onClick={() => onSubmit(title, entityType, selectedThemesetId)}
          >
            {translate('CREATE_GROUP_MODAL.DEFAULT_BUTTON_TEXT')}
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="cancelButton"
          >
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default NewCreateModal;
