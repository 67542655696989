import { useQueries, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { findIndex, flatten } from 'lodash';
import {
  fetchGuideByIdApi,
  fetchGuideLimitById,
  fetchGuides,
  fetchPageByIdApi,
} from '../helpers/api/guidelinesApi';
import { useUserType, UserType } from '../helpers/hooks';
import { Guide, GuideType, Section } from '../types';
import { queryClient } from '../constants/app';
import { useGuideStore } from '../stores/guideStore';
import { useInspectorStore } from '../stores/inspectorStore';
import { useOverviewStore } from '../stores/overviewStore';
import { isHomeOrSinglePage } from '../helpers/utils';
import { ActiveSplits, useReleaseToggle } from '../helpers/releaseToggle';

export const OVERVIEW_KEYS = {
  OVERVIEW_GUIDES: 'OVERVIEW_GUIDES',
  OVERVIEW_GROUPS: 'OVERVIEW_GROUPS',
};

export const GUIDE_KEYS = {
  GUIDE_BY_ID: 'GUIDES',
  GUIDE_LIMIT: 'GUIDE_LIMIT',
  PAGE_BY_ID: 'PAGES',
};

export const useGetOverviewGuides = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();
  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GUIDES, orderBy, orderType],
    () => fetchGuides({ orderBy, orderType }, isPublic, isNewService),
    {
      enabled: !!orderBy && !!orderType && !isLoadingSplit,
      select: data => ({
        allGuides: data?.allGuides?.filter(
          guide => guide.type === GuideType.MULTI_PAGE,
        ),
        featuredGuides: data?.featuredGuides?.filter(
          guide => guide.type === GuideType.MULTI_PAGE,
        ),
      }),
    },
  );
};

export const useGetOverviewLandingPages = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();
  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GUIDES, orderBy, orderType],
    () => fetchGuides({ orderBy, orderType }, isPublic, isNewService),
    {
      enabled: !!orderBy && !!orderType && !isLoadingSplit,
      select: data => ({
        all: data?.allGuides?.filter(
          guide =>
            !(guide.type === GuideType.HOME_PAGE) &&
            guide.type === GuideType.SINGLE_PAGE,
        ),
        featured: data?.featuredGuides?.filter(
          guide =>
            !(guide.type === GuideType.HOME_PAGE) &&
            guide.type === GuideType.SINGLE_PAGE,
        ),
      }),
    },
  );
};

export const useGetOverviewHomepages = () => {
  const orderType = useOverviewStore.use.orderType();
  const orderBy = useOverviewStore.use.orderBy();
  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [OVERVIEW_KEYS.OVERVIEW_GUIDES, orderBy, orderType],
    () => fetchGuides({ orderBy, orderType }, isPublic, isNewService),
    {
      enabled: !!orderBy && !!orderType && !isLoadingSplit,
      select: data => ({
        all: data?.allGuides?.filter(
          guide => guide.isHomepage || guide.type === GuideType.HOME_PAGE,
        ),
      }),
    },
  );
};

export const useOverviewDocumentById = (guideId: string | undefined) => {
  const { data: guides } = useGetOverviewGuides();
  const { data: landingPages } = useGetOverviewLandingPages();
  const { data: homepages } = useGetOverviewHomepages();

  const findGuideById = (guides: Guide[] | undefined) =>
    guides?.find(guide => guide.id === guideId);

  return (
    findGuideById(guides?.allGuides) ||
    findGuideById(landingPages?.all) ||
    findGuideById(homepages?.all)
  );
};

export const useFetchGuideById = (guideId: string | null) => {
  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery<Guide, AxiosError>(
    [GUIDE_KEYS.GUIDE_BY_ID, guideId],
    () => fetchGuideByIdApi(guideId!, isPublic, isNewService),
    {
      enabled: !!guideId && !isLoadingSplit,
      retry: false,
    },
  );
};

export const usePrefetchPage = (pageId: string | null) => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);

  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  if (pageId && activeGuideId && !isLoadingSplit) {
    return queryClient.prefetchQuery(
      [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, pageId],
      () => fetchPageByIdApi(activeGuideId, pageId, isPublic, isNewService),
    );
  }
};

export const useFetchPageById = (guideId: string, pageId: string) => {
  const isPublic = useIsPublic();
  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    () => fetchPageByIdApi(guideId, pageId, isPublic, isNewService),
    {
      enabled: !!guideId && !!pageId && !isLoadingSplit,
      retry: false,
    },
  );
};

export const useChapterById = (guideId: string, chapterId: string) => {
  const { data: guide } = useFetchGuideById(guideId);

  return guide?.chapters.find(chapter => chapter.id === chapterId);
};

export const useGetFirstViewablePageId = (guideId: string) => {
  const { data: guide } = useFetchGuideById(guideId);

  const pages = flatten(guide?.chapters?.map(chapter => chapter.pages));

  return pages?.find(page => page.canView)?.id;
};

export const useActivePage = () => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const activePageId = useGuideStore(s => s.activePageId);

  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [GUIDE_KEYS.PAGE_BY_ID, activeGuideId, activePageId],
    () =>
      fetchPageByIdApi(activeGuideId!, activePageId!, isPublic, isNewService),
    {
      enabled: !!activeGuideId && !!activePageId && !isLoadingSplit,
      retry: false,
    },
  );
};

export const useActiveWidget = () => {
  const widgetSettings = useInspectorStore(s => s.widgetSettings);
  const { data: page } = useActivePage();
  const sectionIndex = findIndex(
    page?.sections,
    section => section.id === widgetSettings?.sectionId,
  );

  return page?.sections[sectionIndex]?.columns[
    widgetSettings?.columnIndex ?? 0
  ]?.widgets?.find(w => w.id === widgetSettings?.widgetId);
};

export const useActiveChapter = () => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const activeChapterId = useGuideStore(s => s.activeChapterId);
  return useChapterById(activeGuideId!, activeChapterId!);
};

export const useGetPageChapterId = (guideId: string, pageId?: string) => {
  const { data: guide } = useFetchGuideById(guideId);

  return guide?.chapters?.find(chapter =>
    chapter.pages?.find(page => page.id === pageId),
  )?.id;
};

export const useFetchGuideSizeLimit = (guideId: string) => {
  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  return useQuery(
    [GUIDE_KEYS.GUIDE_LIMIT, guideId],
    () => fetchGuideLimitById(guideId, isNewService),
    { enabled: !!guideId && !isLoadingSplit },
  );
};

export const useActiveGuide = () => {
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );
  return useQuery<Guide, AxiosError>(
    [GUIDE_KEYS.GUIDE_BY_ID, activeGuideId!],
    () => fetchGuideByIdApi(activeGuideId!, isPublic, isNewService),
    {
      enabled: !!activeGuideId && !isLoadingSplit,
    },
  );
};

export const useActiveSection = () => {
  const activeSectionId = useGuideStore(s => s.activeSectionId);
  const { data: page } = useActivePage();

  const returnObject = {
    activeSection: {} as Section,
    previousSectionId: null as unknown as string,
    nextSectionId: null as unknown as string,
    sectionIndex: null as unknown as number,
    sectionCount: null as unknown as number,
  };

  if (page && activeSectionId) {
    return page.sections.reduce((acc, section, i, pageSections) => {
      if (section.id === activeSectionId) {
        acc.activeSection = section;
        acc.sectionIndex = i;
        acc.sectionCount = pageSections.length;
        if (acc.sectionIndex > 0) {
          acc.previousSectionId = pageSections[acc.sectionIndex - 1].id;
        }
        if (acc.sectionIndex < acc.sectionCount - 1) {
          acc.nextSectionId = pageSections[acc.sectionIndex + 1].id;
        }
      }

      return acc;
    }, returnObject);
  }
  return returnObject;
};

export const usePrefetchAllSubPages = () => {
  const { data: activeGuide, isLoading } = useActiveGuide();
  const isPublic = useIsPublic();

  const { data: isNewService, isLoading: isLoadingSplit } = useReleaseToggle(
    ActiveSplits.guidelinesQueryServiceRollout,
  );

  useQueries({
    queries: activeGuide!.chapters[0].pages.map(page => {
      return {
        queryKey: [GUIDE_KEYS.PAGE_BY_ID, activeGuide!.id, page.id],
        queryFn: () =>
          fetchPageByIdApi(activeGuide!.id, page.id, isPublic, isNewService),
        enabled:
          isHomeOrSinglePage(activeGuide!.type) &&
          !!activeGuide!.id &&
          !!page.id &&
          !isLoading &&
          !isLoadingSplit,
        retry: true,
      };
    }),
  });
};

export const useIsPublic = () => {
  const { userType } = useUserType();
  return userType === UserType.PUBLIC || userType === UserType.OPEN_ASSET_BANK;
};
