import { Cover, EntityVisibility, Identity, Permission, Chapter } from '.';

export enum GuideType {
  SINGLE_PAGE = 'SINGLE_PAGE',
  MULTI_PAGE = 'MULTI_PAGE',
  HOME_PAGE = 'HOME_PAGE',
}

export interface Guide {
  id: string;
  title: string;
  visibility: EntityVisibility;
  createdById?: string;
  creationTime?: string;
  lastModifiedTime?: string;
  version: number;
  isFeatured: boolean;
  isFresh?: boolean;
  editors?: Identity[];
  chapters: Chapter[];
  cover: Cover;
  groupId: string | null;
  ownerId: string;
  canEdit: boolean;
  viewAccessLists: AccessLists;
  editAccessLists: AccessLists;
  visibleToAllPortalUsers?: boolean;
  themesetId?: string;
  permissions: Permission[];
  firstPageId: string;
  type: GuideType;
  isHomepage: boolean;
  targetedPagesEnabled: boolean;
}

export interface GuideLimit {
  aggregateId: string;
  spaceAvailableKilobytes: string;
  spaceAvailablePercentage: string;
  spaceUsedKilobytes: string;
  spaceUsedPercentage: string;
}

export interface AccessLists {
  users: Identity[];
  groups: Identity[];
  profiles: Identity[];
}

export interface GuidesResponse {
  allGuides: Guide[];
  featuredGuides: Guide[];
}

export enum OrderBy {
  CREATION_TIME = 'creationTime',
  LAST_MODIFIED_TIME = 'lastModifiedTime',
  TITLE = 'title',
}

export enum OrderType {
  ASC = 'asc',
  DESC = 'desc',
}

export interface OverviewOrder {
  orderBy: OrderBy;
  orderType: OrderType;
  searchTerm?: string;
}
