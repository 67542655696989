import Axios, { Canceler } from 'axios';
import { API_ROUTES, isDevelopment } from '../../constants/app';
import axios from '../axios';
import { JWT_PERMISSIONS } from '../permissions';
import {
  CurrentUserIdentity,
  Identity,
  IdentityGroupResponse,
  IdentityProfileResponse,
  IdentityUserResponse,
} from '../../types';

const CancelToken = Axios.CancelToken;

let cancelFetchUsers: Canceler;

export const fetchUserIdentitiesApi = async (
  searchTerm?: string,
  userIds?: string[],
  page?: number,
  limit?: number,
  searchFiltering?: boolean,
): Promise<IdentityUserResponse[]> => {
  // only cancel request if searching/typing a search
  if (cancelFetchUsers && searchTerm) {
    cancelFetchUsers();
  }

  return (await axios())
    .get(API_ROUTES.USERS_LIST, {
      cancelToken: new CancelToken(c => (cancelFetchUsers = c)),
      params: {
        search_term: searchTerm,
        user_ids: userIds,
        page,
        limit,
        sort_order: 'asc',
        sort_field: 'first_name',
        permission: searchFiltering
          ? [
              JWT_PERMISSIONS.GUIDELINES_VIEW,
              JWT_PERMISSIONS.GUIDELINES_CREATE,
              JWT_PERMISSIONS.GUIDELINES_MANAGE,
            ]
          : [],
      },
    })
    .then(({ data }) => data);
};

let cancelFetchGroups: Canceler;

export const fetchGroupIdentitiesApi = async (
  searchTerm?: string,
  groupIds?: string[],
  page?: number,
  limit?: number,
): Promise<IdentityGroupResponse[]> => {
  // only cancel request if searching/typing a search
  if (cancelFetchGroups && searchTerm) {
    cancelFetchGroups();
  }

  return (await axios())
    .get(API_ROUTES.GROUPS_LIST, {
      cancelToken: new CancelToken(c => (cancelFetchGroups = c)),
      params: {
        search_term: searchTerm,
        group_ids: groupIds,
        page,
        limit,
        sort_order: 'asc',
        sort_field: 'name',
      },
    })
    .then(({ data }) => data)
    .catch(error => {
      if (Axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      }

      return error;
    });
};

let cancelFetchProfiles: Canceler;

export const fetchProfileIdentitiesApi = async (
  searchTerm?: string,
  profileIds?: string[],
  page?: number,
  limit?: number,
): Promise<IdentityProfileResponse[]> => {
  // only cancel request if searching/typing a search
  if (cancelFetchProfiles && searchTerm) {
    cancelFetchProfiles();
  }

  return (await axios())
    .get(API_ROUTES.PROFILES_LIST, {
      cancelToken: new CancelToken(c => (cancelFetchProfiles = c)),
      params: {
        search_term: searchTerm,
        profile_ids: profileIds,
        page,
        limit,
        sort_order: 'asc',
        sort_field: 'name',
      },
    })
    .then(({ data }) => data)
    .catch(error => {
      if (Axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      }

      return error;
    });
};

export const fetchCurrentUserIdentityApi =
  async (): Promise<CurrentUserIdentity> => {
    if (isDevelopment) {
      return (await axios())
        .get(API_ROUTES.CURRENT_USER, {
          params: { userId: import.meta.env.VITE_USER_ID },
        })
        .then(({ data }) => data)
        .catch(e => {
          throw e;
        });
    }

    return (await axios())
      .get(API_ROUTES.CURRENT_USER)
      .then(({ data }) => data);
  };

export const fetchUserIdentityByIdApi = async (
  userId: string,
): Promise<Identity> => {
  return (await axios())
    .get(API_ROUTES.USER_BY_ID(userId))
    .then(({ data }) => data);
};
