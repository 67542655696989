import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@bynder/localization';
import {
  editChapterAccessApi,
  editGroupAccessApi,
  editGuideAccessApi,
  editPageAccessApi,
} from '../helpers/api/permissionsApi';
import { generateAccessList } from '../helpers/permissions';
import { useOverviewGroupById } from '../queries/groups';
import {
  GUIDE_KEYS,
  useActiveGuide,
  useGetPageChapterId,
  useOverviewDocumentById,
} from '../queries/guides';
import { PERMISSIONS_KEYS, useEntityPermission } from '../queries/permissions';
import {
  EntityVisibility,
  Guide,
  Identity,
  IdentityAccess,
  Permission,
  PermissionDocumentType,
} from '../types';
import { handleMutationSuccess, updateGuideVersion } from './helpers';
import { useModalStore } from '../stores/modalStore';
import { queryClient } from '../constants/app';
import { produce } from 'immer';
import { findIndex } from 'lodash';

interface EditGuideOptions {
  entityVisibility: EntityVisibility;
  identityList: Identity[];
  closeModal?: boolean;
}

export const useEditAccess = (
  entityId: string,
  activeGuideId: string | undefined,
  entityType: PermissionDocumentType | undefined,
) => {
  const { translate } = useTranslate();
  const closeModal = useModalStore.use.closeModal();
  const guide = useOverviewDocumentById(entityId);
  const { data: activeGuide } = useActiveGuide();
  const group = useOverviewGroupById(entityId);
  const chapterId = useGetPageChapterId(activeGuideId ?? '', entityId);

  const { data: entityPermissions } = useEntityPermission(entityId);

  return useMutation(
    (editGuideOptions: EditGuideOptions) => {
      const accessList = generateAccessList(
        entityPermissions?.permissions,
        editGuideOptions?.identityList as IdentityAccess[],
      );

      if (accessList === null) {
        return Promise.reject('Invalid access list');
      }
      const { viewAccessList, editAccessList } = accessList;

      if (entityType === PermissionDocumentType.GUIDE && guide) {
        return editGuideAccessApi(
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          guide.version,
        );
      } else if (entityType === PermissionDocumentType.GROUP) {
        return editGroupAccessApi(
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          group.version,
        );
      } else if (entityType === PermissionDocumentType.CHAPTER && activeGuide) {
        return editChapterAccessApi(
          activeGuide.id,
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          activeGuide.version,
        );
      } else if (
        entityType === PermissionDocumentType.PAGE &&
        activeGuide &&
        chapterId
      ) {
        return editPageAccessApi(
          activeGuide.id,
          chapterId,
          entityId,
          viewAccessList,
          editAccessList,
          editGuideOptions.entityVisibility,
          activeGuide.version,
        );
      }
      return Promise.reject('Invalid entity type');
    },
    {
      onSuccess(data, variables: EditGuideOptions) {
        let trackingMessage: {
          message: string;
          type: EntityVisibility;
        } | null = null;
        switch (entityType) {
          case PermissionDocumentType.GUIDE:
            updateGuideVersion(entityId);
            trackingMessage = {
              message: 'Guide Access changed',
              type: variables.entityVisibility,
            };

            break;

          case PermissionDocumentType.GROUP:
            trackingMessage = {
              message: 'Group Access changed',
              type: variables.entityVisibility,
            };

            break;
          case PermissionDocumentType.PAGE:
            if (activeGuide) {
              updateGuideVersion(activeGuide.id);
              updatePageAccessOptimistically(
                activeGuide.id, 
                chapterId ?? '', 
                entityId, 
                variables.identityList as Permission[]
              );
            }

            trackingMessage = {
              message: 'Page Access changed',
              type: variables.entityVisibility,
            };

            break;
          case PermissionDocumentType.CHAPTER:
            if (activeGuide) {
              updateGuideVersion(activeGuide.id);
            }
            trackingMessage = {
              message: 'Chapter Access changed',
              type: variables.entityVisibility,
            };
        }

        handleMutationSuccess(trackingMessage, data, {
          entityId: activeGuideId ? activeGuideId : entityId,
          message: translate('OVERVIEW_CARD_MUTATION_MESSAGE.UPDATED_ACCESS'),
          queryPath: PERMISSIONS_KEYS.ENTITY_PERMISSIONS,
        });

        variables.closeModal && closeModal();
      },
    },
  );
};

export const updatePageAccessOptimistically = (
  guideId: string,
  chapterId: string,
  pageId: string,
  identityList: Permission[],
) => {
  queryClient.setQueryData([GUIDE_KEYS.GUIDE_BY_ID, guideId], (old: Guide) => {
    if (old) {
      const chapterIndex = findIndex(
        old.chapters,
        chapter => chapter.id === chapterId,
      );

      const pageIndex = findIndex(
        old.chapters[chapterIndex].pages,
        page => page.id === pageId,
      )

      return produce(old, draft => {
        draft.chapters[chapterIndex].pages[pageIndex].permissions = identityList;
      });
    }

    return old;
  });
};
