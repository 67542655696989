import React from 'react';
import {
  Button,
  ModalBase,
  Card,
  token,
  Form,
  Input,
  Divider,
  Select,
  Thumbnail,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import { TemplatesEnum } from '../../constants/templates/constants';
import styled from 'styled-components';
import whiteTemplate from '../../assets/whiteTemplate.png';
import guidePageTemplate from '../../assets/guidePageTemplate.svg';
import { useLandingPageMutations } from '../../mutations/landingPage';
import { useInspectorStore } from '../../stores/inspectorStore';
import { GUIDE_TITLE_MAX_LENGTH } from '../../constants/guides';
import MaxCharacterCounter from '../shared/MaxCharacterCounter';
import { usePageMutations } from '../../mutations/pages';
import { generateUUID } from '../../helpers/utils';
import { OverviewSections, useOverviewStore } from '../../stores/overviewStore';
import { useThemesets } from '../../queries/themesets';
import DynamicTemplateCard from '../templates/DynamicTemplateCard';
import { ActiveSplits, useReleaseToggle } from '../../helpers/releaseToggle';

interface CreateModalProps {
  onCancel: () => void;
  chapterId?: string;
  toIndex?: number;
  createNewEntity: boolean;
  showAllTemplates: boolean;
}

const PageTemplateModal: React.FC<CreateModalProps> = ({
  onCancel,
  chapterId,
  toIndex,
  createNewEntity,
  showAllTemplates,
}) => {
  const { translate } = useTranslate();
  const { createBlankLandingPage, createTemplateLandingPage } =
    useLandingPageMutations();
  const { addBlankPage, addTemplatePage } = usePageMutations();
  const activeTemplate = useInspectorStore(s => s.activeTemplate);
  const setActiveTemplate = useInspectorStore(s => s.setTemplate);
  const [title, setTitle] = React.useState('Untitled');
  const activeSection = useOverviewStore.use.activeSection();
  const isHomepage = activeSection === OverviewSections.HOMEPAGES;
  const { data: themesets } = useThemesets();
  const defaultThemeset = themesets?.find(themeset => themeset.isDefault);
  const [selectedThemesetId, setSelectedThemesetId] = React.useState<string>(
    defaultThemeset?.id || '',
  );
  const { data: isSplitActive } = useReleaseToggle(
    ActiveSplits.themedTemplates,
  );

  const handleAddTemplate = () => {
    const newPageId = generateUUID();
    const isBlankTemplate = activeTemplate === TemplatesEnum.WHITE_TEMPLATE;
    const templateData = {
      title,
      pageTemplate: activeTemplate,
      chapterId,
      toIndex,
    };

    if (createNewEntity) {
      return isBlankTemplate
        ? createBlankLandingPage.mutate({
            guideTitle: title,
            isHomepage,
            themesetId: selectedThemesetId,
          })
        : createTemplateLandingPage.mutate({
            pageTitle: title,
            pageTemplate: activeTemplate,
            isHomepage,
            themesetId: selectedThemesetId,
          });
    }

    return isBlankTemplate
      ? addBlankPage.mutate({
          pageTitle: title,
          chapterId,
          newPageId,
          toIndex,
        })
      : addTemplatePage.mutate({
          pageTitle: templateData.title,
          pageTemplate: templateData.pageTemplate,
          chapterId: templateData.chapterId,
          toIndex: templateData.toIndex,
        });
  };

  const PageTemplateCard = ({
    template,
    templateImg,
    templateName,
  }: {
    template: TemplatesEnum;
    templateImg: string;
    templateName: string;
  }) => (
    <Card
      data-testid={template}
      variant="clean"
      onClick={() => setActiveTemplate(template)}
      onSelect={() => setActiveTemplate(template)}
      isSelected={activeTemplate === template}
    >
      <Card.Frame height="fixed">
        <Card.Images>
          <Card.Img src={templateImg} />
        </Card.Images>
      </Card.Frame>
      <Card.Header title={templateName} />
    </Card>
  );

  const selectedThemeset = themesets?.find(
    themeset => themeset.id === selectedThemesetId,
  );

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel} size="medium">
      <ModalBase.Header
        title={
          createNewEntity
            ? isHomepage
              ? translate('CREATE_MODAL.NEW_HOMEPAGE_HEADER_TEXT')
              : translate('CREATE_MODAL.NEW_PAGE_HEADER_TEXT')
            : translate('CREATE_MODAL.NEW_GUIDE_PAGE_HEADER_TEXT')
        }
      />
      <ModalBase.Content>
        <Form.Row>
          <Form.Group>
            <Form.Label htmlFor="modalInput">
              <Translate id="PAGE_TEMPLATE_MODAL.INPUT_LABEL" />
            </Form.Label>
            <Input
              id="modalInput"
              placeholder={translate('PAGE_TEMPLATE_MODAL.INPUT_PLACEHOLDER')}
              value={title}
              onChange={setTitle}
              data-testid="modalInput"
              maxLength={GUIDE_TITLE_MAX_LENGTH}
              iconRight={
                <MaxCharacterCounter
                  value={title}
                  maxValue={GUIDE_TITLE_MAX_LENGTH}
                />
              }
            />
          </Form.Group>
          {isSplitActive && (
            <Form.Group>
              <Form.Label htmlFor="themesetSelect">Theme</Form.Label>
              <Select
                aria-label="Select a themeset"
                value={selectedThemesetId}
                onChange={setSelectedThemesetId}
                label={selectedThemeset?.label}
                icon={
                  <Thumbnail
                    variant="content"
                    shape="circle"
                    size="xs"
                    backgroundColor={selectedThemeset?.cover?.backgroundColor}
                  />
                }
              >
                {themesets.map(themeset => (
                  <Select.Item
                    key={themeset.id}
                    value={themeset.id}
                    icon={
                      <Thumbnail
                        variant="content"
                        shape="circle"
                        size="xs"
                        backgroundColor={themeset?.cover?.backgroundColor}
                      />
                    }
                  >
                    {themeset.label}
                  </Select.Item>
                ))}
              </Select>
            </Form.Group>
          )}
        </Form.Row>
        <Divider />
        <ModalContentTile>
          <Translate id="PAGE_TEMPLATE_MODAL.SELECT_TITLE" />
        </ModalContentTile>
        <Grid>
          <PageTemplateCard
            template={TemplatesEnum.WHITE_TEMPLATE}
            templateImg={whiteTemplate}
            templateName={translate('PAGE_TEMPLATE_MODAL.BLANK_TEMPLATE')}
          />
          {showAllTemplates ? (
            <>
              <DynamicTemplateCard
                template={TemplatesEnum.LANDING_PAGE_TEMPLATE}
                themesetId={selectedThemesetId}
                templateName={translate('PAGE_TEMPLATE_MODAL.HP_TEMPLATE_1')}
                onSelect={setActiveTemplate}
                onClick={setActiveTemplate}
                isSelected={
                  activeTemplate === TemplatesEnum.LANDING_PAGE_TEMPLATE
                }
              />
              <DynamicTemplateCard
                template={TemplatesEnum.HOMEPAGE_TEMPLATE}
                themesetId={selectedThemesetId}
                templateName={translate('PAGE_TEMPLATE_MODAL.HP_TEMPLATE_2')}
                onSelect={setActiveTemplate}
                onClick={setActiveTemplate}
                isSelected={activeTemplate === TemplatesEnum.HOMEPAGE_TEMPLATE}
              />
              <DynamicTemplateCard
                template={TemplatesEnum.GTM_TEMPLATE}
                themesetId={selectedThemesetId}
                templateName={translate('PAGE_TEMPLATE_MODAL.GTM_TEMPLATE')}
                onSelect={setActiveTemplate}
                onClick={setActiveTemplate}
                isSelected={activeTemplate === TemplatesEnum.GTM_TEMPLATE}
              />
              <DynamicTemplateCard
                template={TemplatesEnum.PL_TEMPLATE}
                themesetId={selectedThemesetId}
                templateName={translate('PAGE_TEMPLATE_MODAL.PL_TEMPLATE')}
                onSelect={setActiveTemplate}
                onClick={setActiveTemplate}
                isSelected={activeTemplate === TemplatesEnum.PL_TEMPLATE}
              />
              <DynamicTemplateCard
                template={TemplatesEnum.BRAND_PORTAL_TEMPLATE}
                themesetId={selectedThemesetId}
                templateName={translate('PAGE_TEMPLATE_MODAL.BRAND_PORTAL')}
                onSelect={setActiveTemplate}
                onClick={setActiveTemplate}
                isSelected={
                  activeTemplate === TemplatesEnum.BRAND_PORTAL_TEMPLATE
                }
              />
            </>
          ) : (
            <PageTemplateCard
              template={TemplatesEnum.GUIDE_PAGE_TEMPLATE}
              templateImg={guidePageTemplate}
              templateName={translate('PAGE_TEMPLATE_MODAL.BRAND_PORTAL')}
            />
          )}
        </Grid>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            data-testid="submitButton"
            onClick={handleAddTemplate}
            isDisabled={!title}
          >
            <Translate id="CREATE_MODAL.DEFAULT_BUTTON_LABEL" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button variant="secondary" onClick={onCancel}>
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default PageTemplateModal;

const ModalContentTile = styled.div`
  margin-top: ${token.spacing6};
`;

const Grid = styled.div`
  margin-top: ${token.spacing6};
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: ${token.spacing6};
  align-items: flex-start;
`;
