import { Card } from '@bynder/design-system';
import { useThemesetById } from '../../queries/themesets';
import { defaultBynderThemeset } from '../../constants/themesets';
import { TemplatesEnum } from '../../constants/templates/constants';

interface DynamicTemplateCard {
  template: TemplatesEnum;
  templateName: string;
  themesetId: string;
  onSelect?: (template: TemplatesEnum) => void;
  isSelected?: boolean;
  onClick?: (template: TemplatesEnum) => void;
}

const defaultButtonBackground =
  defaultBynderThemeset.widgets.button.colors.default.backgroundColor.hexCode;
const defaultButtonBorder =
  defaultBynderThemeset.widgets.button.colors.default.borderColor.hexCode;
const defaultCardTitle = defaultBynderThemeset.widgets.card.titleColor.hexCode;
const defaultCardBorderHover =
  defaultBynderThemeset.widgets.card.borderColor.hexCode;
const defaultCardDescription =
  defaultBynderThemeset.widgets.card.descriptionColor.hexCode;
const defaultSectionBackground =
  defaultBynderThemeset.pageSection.backgroundColor.hexCode;
const defaultParagraphL =
  defaultBynderThemeset.typographies[4].fontColor.hexCode;

const DynamicTemplateCard: React.FC<DynamicTemplateCard> = ({
  template,
  themesetId,
  templateName,
  onSelect,
  isSelected,
  onClick,
}) => {
  const { data: themeset } = useThemesetById(themesetId);

  const buttonBackground =
    themeset?.widgets?.button?.colors?.default?.backgroundColor?.hexCode ??
    defaultButtonBackground;
  const buttonBorder =
    themeset?.widgets?.button?.colors?.default?.borderColor?.hexCode ??
    defaultButtonBorder;
  const cardTitle =
    themeset?.widgets?.card?.titleColor?.hexCode ?? defaultCardTitle;
  const cardDescription =
    themeset?.widgets?.card?.descriptionColor?.hexCode ??
    defaultCardDescription;
  const cardBorderHover =
    themeset?.widgets?.card?.borderColor?.hexCode ?? defaultCardBorderHover;
  const sectionBackground =
    themeset?.pageSection?.backgroundColor?.hexCode ?? defaultSectionBackground;
  const paragraphText =
    themeset?.typographies[4]?.fontColor?.hexCode ?? defaultParagraphL;

  return (
    <Card
      variant="clean"
      onSelect={() => onSelect?.(template)}
      isSelected={isSelected}
      onClick={() => onClick?.(template)}
    >
      <Card.Frame height="fixed">
        <Card.Images>
          <DynamicTemplate
            template={template}
            buttonBackground={buttonBackground}
            cardBorderHover={cardBorderHover}
            sectionBackground={sectionBackground}
            cardTitle={cardTitle}
            cardDescription={cardDescription}
            buttonBorder={buttonBorder}
            paragraphText={paragraphText}
          />
        </Card.Images>
      </Card.Frame>
      <Card.Header title={templateName} />
    </Card>
  );
};

export default DynamicTemplateCard;

interface TemplateProps {
  template: TemplatesEnum;
  buttonBackground: string;
  cardBorderHover: string;
  sectionBackground: string;
  cardTitle: string;
  cardDescription: string;
  buttonBorder: string;
  paragraphText: string;
}

const DynamicTemplate: React.FC<TemplateProps> = ({
  template,
  buttonBackground,
  cardBorderHover,
  sectionBackground,
  cardTitle,
  cardDescription,
  buttonBorder,
  paragraphText,
}) => {
  switch (template) {
    case TemplatesEnum.LANDING_PAGE_TEMPLATE:
      return (
        <svg
          width="256"
          height="160"
          viewBox="0 0 256 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="256" height="160" fill="white" />
          <rect
            y="65"
            width="256"
            height="80"
            fill={sectionBackground}
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_8031_93578)">
            <rect
              x="18.6768"
              y="76.9106"
              width="62.1702"
              height="37.102"
              rx="1.15944"
              fill="#D5D7DA"
            />
          </g>
          <rect
            x="18.8776"
            y="77.1115"
            width="61.7685"
            height="36.7003"
            rx="0.958608"
            stroke="#D5D7DA"
            stroke-width="0.401657"
          />
          <rect
            x="18.6768"
            y="118.384"
            width="46.6276"
            height="3.88564"
            rx="1.94282"
            fill={cardTitle}
          />
          <rect
            x="18.6768"
            y="127.37"
            width="31.0851"
            height="3.88564"
            rx="1.94282"
            fill={cardDescription}
          />
          <g clip-path="url(#clip1_8031_93578)">
            <path
              d="M96.875 78.0701C96.875 77.4297 97.3941 76.9106 98.0344 76.9106H157.886C158.526 76.9106 159.045 77.4297 159.045 78.0701V112.853C159.045 113.494 158.526 114.013 157.886 114.013H98.0344C97.3941 114.013 96.875 113.494 96.875 112.853V78.0701Z"
              fill="#D5D7DA"
            />
          </g>
          <path
            d="M97.0758 78.0701C97.0758 77.5407 97.505 77.1115 98.0344 77.1115H157.886C158.415 77.1115 158.844 77.5407 158.844 78.0701V112.853C158.844 113.383 158.415 113.812 157.886 113.812H98.0344C97.505 113.812 97.0758 113.383 97.0758 112.853V78.0701Z"
            stroke="#D5D7DA"
            stroke-width="0.401657"
          />
          <rect
            x="96.875"
            y="118.384"
            width="46.6276"
            height="3.88564"
            rx="1.94282"
            fill={cardTitle}
          />
          <rect
            x="96.875"
            y="127.37"
            width="31.0851"
            height="3.88564"
            rx="1.94282"
            fill={cardDescription}
          />
          <g clip-path="url(#clip2_8031_93578)">
            <path
              d="M175.073 78.0701C175.073 77.4297 175.592 76.9106 176.233 76.9106H236.084C236.724 76.9106 237.243 77.4297 237.243 78.0701V112.853C237.243 113.494 236.724 114.013 236.084 114.013H176.233C175.592 114.013 175.073 113.494 175.073 112.853V78.0701Z"
              fill="#D5D7DA"
            />
          </g>
          <path
            d="M176.233 76.4106C175.316 76.4106 174.573 77.1536 174.573 78.0701V112.853C174.573 113.77 175.316 114.513 176.233 114.513H236.084C237 114.513 237.743 113.77 237.743 112.853V78.0701C237.743 77.1536 237 76.4106 236.084 76.4106H176.233Z"
            stroke={cardBorderHover}
          />
          <rect
            x="175.073"
            y="118.384"
            width="46.6276"
            height="3.88564"
            rx="1.94282"
            fill={cardTitle}
          />
          <rect
            x="175.073"
            y="127.37"
            width="31.0851"
            height="3.88564"
            rx="1.94282"
            fill={cardDescription}
          />
          <rect
            x="46.3623"
            y="24.4541"
            width="163.197"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <rect
            x="61.9043"
            y="33.4399"
            width="132.112"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <rect
            x="104.647"
            y="42.0609"
            width="46.6276"
            height="9.47124"
            rx="2.891"
            fill={buttonBackground}
          />
          <rect
            x="104.647"
            y="42.0609"
            width="46.6276"
            height="9.47124"
            rx="2.891"
            stroke={buttonBorder}
            stroke-width="0.242852"
          />
          <defs>
            <clipPath id="clip0_8031_93578">
              <rect
                x="18.6768"
                y="76.9106"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip1_8031_93578">
              <path
                d="M96.875 78.0701C96.875 77.4297 97.3941 76.9106 98.0344 76.9106H157.886C158.526 76.9106 159.045 77.4297 159.045 78.0701V112.853C159.045 113.494 158.526 114.013 157.886 114.013H98.0344C97.3941 114.013 96.875 113.494 96.875 112.853V78.0701Z"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip2_8031_93578">
              <path
                d="M175.073 78.0701C175.073 77.4297 175.592 76.9106 176.233 76.9106H236.084C236.724 76.9106 237.243 77.4297 237.243 78.0701V112.853C237.243 113.494 236.724 114.013 236.084 114.013H176.233C175.592 114.013 175.073 113.494 175.073 112.853V78.0701Z"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case TemplatesEnum.HOMEPAGE_TEMPLATE:
      return (
        <svg
          width="256"
          height="161"
          viewBox="0 0 256 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="256"
            height="160"
            transform="translate(0 0.393555)"
            fill="white"
          />
          <rect
            y="66"
            width="256"
            height="80"
            fill={sectionBackground}
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_8031_93777)">
            <path
              d="M30.7266 81.4832C30.7266 80.8428 31.2457 80.3237 31.886 80.3237H80.9874C81.6278 80.3237 82.1468 80.8428 82.1468 81.4832V109.851C82.1468 110.491 81.6278 111.01 80.9874 111.01H31.886C31.2457 111.01 30.7266 110.491 30.7266 109.851V81.4832Z"
              fill="#D5D7DA"
            />
          </g>
          <path
            d="M30.9274 81.4832C30.9274 80.9537 31.3566 80.5246 31.886 80.5246H80.9874C81.5168 80.5246 81.946 80.9537 81.946 81.4832V109.851C81.946 110.38 81.5168 110.81 80.9874 110.81H31.886C31.3566 110.81 30.9274 110.38 30.9274 109.851V81.4832Z"
            stroke="#D5D7DA"
            stroke-width="0.401657"
          />
          <rect
            x="30.7266"
            y="114.626"
            width="38.5652"
            height="3.21377"
            rx="1.60688"
            fill={cardTitle}
          />
          <rect
            x="30.7266"
            y="122.057"
            width="25.7101"
            height="3.21377"
            rx="1.60688"
            fill={cardDescription}
          />
          <g clip-path="url(#clip1_8031_93777)">
            <path
              d="M94.582 81.4832C94.582 80.8428 95.1011 80.3237 95.7415 80.3237H144.843C145.483 80.3237 146.002 80.8428 146.002 81.4832V109.851C146.002 110.491 145.483 111.01 144.843 111.01H95.7415C95.1011 111.01 94.582 110.491 94.582 109.851V81.4832Z"
              fill="#D5D7DA"
            />
          </g>
          <path
            d="M95.7415 79.8237C94.825 79.8237 94.082 80.5667 94.082 81.4832V109.851C94.082 110.767 94.825 111.51 95.7415 111.51H144.843C145.759 111.51 146.502 110.767 146.502 109.851V81.4832C146.502 80.5667 145.759 79.8237 144.843 79.8237H95.7415Z"
            stroke={cardBorderHover}
          />
          <rect
            x="94.582"
            y="114.626"
            width="38.5652"
            height="3.21377"
            rx="1.60688"
            fill={cardTitle}
          />
          <rect
            x="94.582"
            y="122.057"
            width="25.7101"
            height="3.21377"
            rx="1.60688"
            fill={cardDescription}
          />
          <rect
            x="30.8193"
            y="24.8481"
            width="178.739"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <rect
            x="30.8193"
            y="33.8337"
            width="163.197"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <path
            d="M30.9408 45.3459C30.9408 43.7493 32.2351 42.4549 33.8318 42.4549H74.6774C76.274 42.4549 77.5684 43.7493 77.5684 45.3459V49.0352C77.5684 50.6318 76.274 51.9262 74.6774 51.9262H33.8318C32.2351 51.9262 30.9408 50.6318 30.9408 49.0352V45.3459Z"
            fill={buttonBackground}
          />
          <path
            d="M30.9408 45.3459C30.9408 43.7493 32.2351 42.4549 33.8318 42.4549H74.6774C76.274 42.4549 77.5684 43.7493 77.5684 45.3459V49.0352C77.5684 50.6318 76.274 51.9262 74.6774 51.9262H33.8318C32.2351 51.9262 30.9408 50.6318 30.9408 49.0352V45.3459Z"
            stroke={buttonBorder}
            stroke-width="0.242852"
          />
          <rect
            x="158.654"
            y="80.2766"
            width="42.6978"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <rect
            x="158.654"
            y="89.2625"
            width="27.1552"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <rect
            x="158.654"
            y="98.2476"
            width="42.6978"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <rect
            x="158.654"
            y="107.233"
            width="27.1552"
            height="3.88564"
            rx="1.94282"
            fill={paragraphText}
          />
          <path
            d="M158.776 118.746C158.776 117.149 160.07 115.855 161.667 115.855H202.512C204.109 115.855 205.403 117.149 205.403 118.746V122.435C205.403 124.031 204.109 125.326 202.512 125.326H161.667C160.07 125.326 158.776 124.031 158.776 122.435V118.746Z"
            fill={buttonBackground}
          />
          <path
            d="M158.776 118.746C158.776 117.149 160.07 115.855 161.667 115.855H202.512C204.109 115.855 205.403 117.149 205.403 118.746V122.435C205.403 124.031 204.109 125.326 202.512 125.326H161.667C160.07 125.326 158.776 124.031 158.776 122.435V118.746Z"
            stroke={buttonBorder}
            stroke-width="0.242852"
          />
          <defs>
            <clipPath id="clip0_8031_93777">
              <path
                d="M30.7266 81.4832C30.7266 80.8428 31.2457 80.3237 31.886 80.3237H80.9874C81.6278 80.3237 82.1468 80.8428 82.1468 81.4832V109.851C82.1468 110.491 81.6278 111.01 80.9874 111.01H31.886C31.2457 111.01 30.7266 110.491 30.7266 109.851V81.4832Z"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip1_8031_93777">
              <path
                d="M94.582 81.4832C94.582 80.8428 95.1011 80.3237 95.7415 80.3237H144.843C145.483 80.3237 146.002 80.8428 146.002 81.4832V109.851C146.002 110.491 145.483 111.01 144.843 111.01H95.7415C95.1011 111.01 94.582 110.491 94.582 109.851V81.4832Z"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case TemplatesEnum.GTM_TEMPLATE:
      return (
        <svg
          width="256"
          height="161"
          viewBox="0 0 256 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_8031_93685)">
            <rect
              width="256"
              height="160"
              transform="translate(0 0.566406)"
              fill="white"
            />
            <rect
              y="68"
              width="256"
              height="56"
              fill={sectionBackground}
              fill-opacity="0.1"
            />
            <rect
              x="18.6768"
              y="81.8474"
              width="141.973"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="18.6768"
              y="89.6184"
              width="141.973"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="18.6768"
              y="97.3899"
              width="141.973"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="18.6768"
              y="106.375"
              width="126.43"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <g clip-path="url(#clip1_8031_93685)">
              <path
                d="M185.832 78.6355C185.832 77.9952 186.351 77.4761 186.991 77.4761H236.085C236.725 77.4761 237.244 77.9952 237.244 78.6355V113.47C237.244 114.11 236.725 114.629 236.085 114.629H186.991C186.351 114.629 185.832 114.11 185.832 113.47V78.6355Z"
                fill="#D5D7DA"
              />
            </g>
            <path
              d="M186.332 78.6355C186.332 78.2713 186.627 77.9761 186.991 77.9761H236.085C236.449 77.9761 236.744 78.2713 236.744 78.6355V113.47C236.744 113.834 236.449 114.129 236.085 114.129H186.991C186.627 114.129 186.332 113.834 186.332 113.47V78.6355Z"
              stroke={cardBorderHover}
            />
            <rect
              x="46.3613"
              y="25.0205"
              width="163.197"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="61.9043"
              y="34.0059"
              width="132.112"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <path
              d="M104.646 45.5183C104.646 43.9216 105.94 42.6273 107.537 42.6273H148.382C149.979 42.6273 151.273 43.9216 151.273 45.5183V49.2075C151.273 50.8042 149.979 52.0985 148.382 52.0985H107.537C105.94 52.0985 104.646 50.8042 104.646 49.2075V45.5183Z"
              fill={buttonBackground}
            />
            <path
              d="M104.646 45.5183C104.646 43.9216 105.94 42.6273 107.537 42.6273H148.382C149.979 42.6273 151.273 43.9216 151.273 45.5183V49.2075C151.273 50.8042 149.979 52.0985 148.382 52.0985H107.537C105.94 52.0985 104.646 50.8042 104.646 49.2075V45.5183Z"
              stroke={buttonBorder}
              stroke-width="0.242852"
            />
            <g clip-path="url(#clip2_8031_93685)">
              <rect
                x="18.6768"
                y="135.322"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="18.7927"
              y="135.438"
              width="61.9383"
              height="36.8701"
              rx="1.04349"
              stroke="#D5D7DA"
              stroke-width="0.231887"
            />
            <g clip-path="url(#clip3_8031_93685)">
              <rect
                x="96.875"
                y="135.322"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="96.9909"
              y="135.438"
              width="61.9383"
              height="36.8701"
              rx="1.04349"
              stroke="#D5D7DA"
              stroke-width="0.231887"
            />
            <g clip-path="url(#clip4_8031_93685)">
              <rect
                x="175.073"
                y="135.322"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="175.189"
              y="135.438"
              width="61.9383"
              height="36.8701"
              rx="1.04349"
              stroke="#D5D7DA"
              stroke-width="0.231887"
            />
          </g>
          <defs>
            <clipPath id="clip0_8031_93685">
              <rect
                width="256"
                height="160"
                fill="white"
                transform="translate(0 0.566406)"
              />
            </clipPath>
            <clipPath id="clip1_8031_93685">
              <path
                d="M185.832 78.6355C185.832 77.9952 186.351 77.4761 186.991 77.4761H236.085C236.725 77.4761 237.244 77.9952 237.244 78.6355V113.47C237.244 114.11 236.725 114.629 236.085 114.629H186.991C186.351 114.629 185.832 114.11 185.832 113.47V78.6355Z"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip2_8031_93685">
              <rect
                x="18.6768"
                y="135.322"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip3_8031_93685">
              <rect
                x="96.875"
                y="135.322"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip4_8031_93685">
              <rect
                x="175.073"
                y="135.322"
                width="62.1702"
                height="37.102"
                rx="1.15944"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case TemplatesEnum.PL_TEMPLATE:
      return (
        <svg
          width="256"
          height="161"
          viewBox="0 0 256 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_8031_93744)">
            <rect
              width="256"
              height="160"
              transform="translate(0 0.968262)"
              fill="white"
            />
            <rect
              x="30.7266"
              y="30.8446"
              width="90.2941"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="30.7266"
              y="39.83"
              width="74.7516"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <path
              d="M30.848 51.3424C30.848 49.7457 32.1423 48.4514 33.739 48.4514H74.5846C76.1813 48.4514 77.4756 49.7457 77.4756 51.3424V55.0316C77.4756 56.6283 76.1813 57.9226 74.5846 57.9226H33.739C32.1423 57.9226 30.848 56.6283 30.848 55.0316V51.3424Z"
              fill={buttonBackground}
            />
            <path
              d="M30.848 51.3424C30.848 49.7457 32.1423 48.4514 33.739 48.4514H74.5846C76.1813 48.4514 77.4756 49.7457 77.4756 51.3424V55.0316C77.4756 56.6283 76.1813 57.9226 74.5846 57.9226H33.739C32.1423 57.9226 30.848 56.6283 30.848 55.0316V51.3424Z"
              stroke={buttonBorder}
              stroke-width="0.242852"
            />
            <g clip-path="url(#clip1_8031_93744)">
              <rect
                x="146.404"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="146.605"
              y="13.6206"
              width="33.971"
              height="24.438"
              rx="0.574338"
              stroke="#D5D7DA"
              stroke-width="0.401657"
            />
            <g clip-path="url(#clip2_8031_93744)">
              <rect
                x="188.779"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="188.98"
              y="13.6206"
              width="33.971"
              height="24.438"
              rx="0.574338"
              stroke="#D5D7DA"
              stroke-width="0.401657"
            />
            <g clip-path="url(#clip3_8031_93744)">
              <rect
                x="146.404"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="146.605"
              y="45.5521"
              width="33.971"
              height="24.438"
              rx="0.574338"
              stroke="#D5D7DA"
              stroke-width="0.401657"
            />
            <g clip-path="url(#clip4_8031_93744)">
              <rect
                x="188.779"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="188.279"
              y="44.8513"
              width="35.3727"
              height="25.8396"
              rx="1.27517"
              stroke={cardBorderHover}
            />
            <rect
              y="87.9683"
              width="256"
              height="78"
              fill={sectionBackground}
              fill-opacity="0.1"
            />
            <rect
              x="46.3623"
              y="102.34"
              width="163.197"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="61.9043"
              y="111.325"
              width="132.112"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <g clip-path="url(#clip5_8031_93744)">
              <rect
                x="37.0176"
                y="119.825"
                width="181.885"
                height="131.441"
                rx="4.10184"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="37.4278"
              y="120.236"
              width="181.065"
              height="130.62"
              rx="3.69166"
              stroke="#D5D7DA"
              stroke-width="0.820368"
            />
          </g>
          <defs>
            <clipPath id="clip0_8031_93744">
              <rect
                width="256"
                height="160"
                fill="white"
                transform="translate(0 0.968262)"
              />
            </clipPath>
            <clipPath id="clip1_8031_93744">
              <rect
                x="146.404"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip2_8031_93744">
              <rect
                x="188.779"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip3_8031_93744">
              <rect
                x="146.404"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip4_8031_93744">
              <rect
                x="188.779"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip5_8031_93744">
              <rect
                x="37.0176"
                y="119.825"
                width="181.885"
                height="131.441"
                rx="4.10184"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case TemplatesEnum.BRAND_PORTAL_TEMPLATE:
      return (
        <svg
          width="256"
          height="161"
          viewBox="0 0 256 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_8031_93744)">
            <rect
              width="256"
              height="160"
              transform="translate(0 0.968262)"
              fill="white"
            />
            <rect
              x="30.7266"
              y="30.8446"
              width="90.2941"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="30.7266"
              y="39.83"
              width="74.7516"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <path
              d="M30.848 51.3424C30.848 49.7457 32.1423 48.4514 33.739 48.4514H74.5846C76.1813 48.4514 77.4756 49.7457 77.4756 51.3424V55.0316C77.4756 56.6283 76.1813 57.9226 74.5846 57.9226H33.739C32.1423 57.9226 30.848 56.6283 30.848 55.0316V51.3424Z"
              fill={buttonBackground}
            />
            <path
              d="M30.848 51.3424C30.848 49.7457 32.1423 48.4514 33.739 48.4514H74.5846C76.1813 48.4514 77.4756 49.7457 77.4756 51.3424V55.0316C77.4756 56.6283 76.1813 57.9226 74.5846 57.9226H33.739C32.1423 57.9226 30.848 56.6283 30.848 55.0316V51.3424Z"
              stroke={buttonBorder}
              stroke-width="0.242852"
            />
            <g clip-path="url(#clip1_8031_93744)">
              <rect
                x="146.404"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="146.605"
              y="13.6206"
              width="33.971"
              height="24.438"
              rx="0.574338"
              stroke="#D5D7DA"
              stroke-width="0.401657"
            />
            <g clip-path="url(#clip2_8031_93744)">
              <rect
                x="188.779"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="188.98"
              y="13.6206"
              width="33.971"
              height="24.438"
              rx="0.574338"
              stroke="#D5D7DA"
              stroke-width="0.401657"
            />
            <g clip-path="url(#clip3_8031_93744)">
              <rect
                x="146.404"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="146.605"
              y="45.5521"
              width="33.971"
              height="24.438"
              rx="0.574338"
              stroke="#D5D7DA"
              stroke-width="0.401657"
            />
            <g clip-path="url(#clip4_8031_93744)">
              <rect
                x="188.779"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="188.279"
              y="44.8513"
              width="35.3727"
              height="25.8396"
              rx="1.27517"
              stroke={cardBorderHover}
            />
            <rect
              y="87.9683"
              width="256"
              height="78"
              fill={sectionBackground}
              fill-opacity="0.1"
            />
            <rect
              x="46.3623"
              y="102.34"
              width="163.197"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <rect
              x="61.9043"
              y="111.325"
              width="132.112"
              height="3.88564"
              rx="1.94282"
              fill={paragraphText}
            />
            <g clip-path="url(#clip5_8031_93744)">
              <rect
                x="37.0176"
                y="119.825"
                width="181.885"
                height="131.441"
                rx="4.10184"
                fill="#D5D7DA"
              />
            </g>
            <rect
              x="37.4278"
              y="120.236"
              width="181.065"
              height="130.62"
              rx="3.69166"
              stroke="#D5D7DA"
              stroke-width="0.820368"
            />
          </g>
          <defs>
            <clipPath id="clip0_8031_93744">
              <rect
                width="256"
                height="160"
                fill="white"
                transform="translate(0 0.968262)"
              />
            </clipPath>
            <clipPath id="clip1_8031_93744">
              <rect
                x="146.404"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip2_8031_93744">
              <rect
                x="188.779"
                y="13.4198"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip3_8031_93744">
              <rect
                x="146.404"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip4_8031_93744">
              <rect
                x="188.779"
                y="45.3513"
                width="34.3727"
                height="24.8396"
                rx="0.775166"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip5_8031_93744">
              <rect
                x="37.0176"
                y="119.825"
                width="181.885"
                height="131.441"
                rx="4.10184"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return null;
  }
};
