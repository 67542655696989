import { create } from 'zustand';
import { InspectorSetting } from '../constants/guides';
import { TemplatesEnum } from '../constants/templates/constants';

interface InspectorStore {
  isInspectorOpen: boolean;
  activeSectionComputedHeight: number | null;
  activeInspectorSetting: InspectorSetting | null;
  activeTemplate: TemplatesEnum;
  sectionPosition: number | null;
  widgetSettings: {
    widgetId?: string;
    sectionId?: string;
    columnIndex?: number;
    widgetIndex?: number;
  } | null;
  setSectionPosition: (sectionPosition: number | null) => void;
  setTemplate: (activeTemplate: TemplatesEnum) => void;
  setIsInspectorOpen: (isInspectorOpen: boolean) => void;
  setActiveSectionComputedHeight: (
    activeSectionComputedHeight: number | null,
  ) => void;
  setActiveInspectorSetting: (
    activeInspectorSetting: InspectorSetting | null,
  ) => void;
  setWidgetSettings: (
    widgetSettings: {
      widgetId?: string;
      sectionId?: string;
      columnIndex?: number;
      widgetIndex?: number;
    } | null,
  ) => void;
}

export const useInspectorStore = create<InspectorStore>(set => ({
  isInspectorOpen: false,
  activeInspectorSetting: null,
  widgetSettings: null,
  activeSectionComputedHeight: null,
  activeTemplate: TemplatesEnum.WHITE_TEMPLATE,
  sectionPosition: null,
  setSectionPosition: sectionPosition => set(() => ({ sectionPosition })),
  setTemplate: activeTemplate => set(() => ({ activeTemplate })),
  setIsInspectorOpen: isInspectorOpen => set(() => ({ isInspectorOpen })),
  setActiveSectionComputedHeight: activeSectionComputedHeight =>
    set(() => ({ activeSectionComputedHeight })),
  setActiveInspectorSetting: activeInspectorSetting =>
    set(() => ({ activeInspectorSetting })),
  setWidgetSettings: widgetSettings =>
    set(state => ({
      widgetSettings: {
        ...state.widgetSettings,
        ...widgetSettings,
      },
    })),
}));
