import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@bynder/localization';
import {
  sendBatchCommandApi,
  createGuideWithTemplateApi,
} from '../helpers/api/guidelinesApi';
import { generateUUID } from '../helpers/utils';
import { OVERVIEW_KEYS } from '../queries/guides';
import { useModalStore } from '../stores/modalStore';
import { handleMutationSuccess } from './helpers';
import { EntityVisibility, GuideType } from '../types';
import { GuideTemplateBuilder } from '../constants/templates/guideTemplateBuilder';
import { TemplatesEnum } from '../constants/templates/constants';
import { useOverviewStore } from '../stores/overviewStore';
import { getCommand } from '../helpers/api/commands';
import { getEmptyLegacyModificationGroup } from '../helpers/permissions';
import { useAssignThemesetToGuide } from './themesets';

export const useLandingPageMutations = () => {
  const toggleIsCreatingLandingPage =
    useModalStore.use.toggleIsCreatingLandingPage();
  const activeGroupId = useOverviewStore.use.activeGroupId();
  const { translate } = useTranslate();
  const newGuideId = generateUUID();
  const newGuideVersion = 0;
  const newPageId = generateUUID();
  const closeModal = useModalStore.use.closeModal();
  const assignThemesetToGuideMutation = useAssignThemesetToGuide();

  return {
    createBlankLandingPage: useMutation(
      (createGuideOptions: {
        guideTitle: string;
        isHomepage?: boolean;
        themesetId: string;
      }) => {
        const { createGuideWithTemplate, editGuideAccess } = getCommand(
          newGuideId,
          0,
        );

        const createBlankHomepage = createGuideWithTemplate({
          guideTitle: createGuideOptions.guideTitle,
          chapterId: generateUUID(),
          chapterTitle: translate('GUIDE.UNTITLED_CHAPTER'),
          pageId: generateUUID(),
          pageTitle: translate('GUIDE.UNTITLED_PAGE'),
          sectionId: generateUUID(),
          guideType: createGuideOptions.isHomepage
            ? GuideType.HOME_PAGE
            : GuideType.SINGLE_PAGE,
        });

        const setVisibilityPortal = editGuideAccess({
          viewAccessChanges: getEmptyLegacyModificationGroup(),
          editAccessChanges: getEmptyLegacyModificationGroup(),
          guideVisibility: EntityVisibility.PORTAL,
        });

        if (createGuideOptions.isHomepage) {
          return sendBatchCommandApi(
            newGuideId,
            [createBlankHomepage, setVisibilityPortal],
            newGuideVersion,
          );
        }

        return createGuideWithTemplateApi(
          newGuideId,
          createGuideOptions.guideTitle,
          generateUUID(),
          translate('GUIDE.UNTITLED_CHAPTER'),
          generateUUID(),
          translate('GUIDE.UNTITLED_PAGE'),
          generateUUID(),
          newGuideVersion,
          createGuideOptions.isHomepage
            ? GuideType.HOME_PAGE
            : GuideType.SINGLE_PAGE,
        );
      },
      {
        onMutate: () => {
          toggleIsCreatingLandingPage(true);
          closeModal();
        },
        onSuccess: (data, variables) => {
          if (variables.themesetId) {
            assignThemesetToGuideMutation.mutate({
              guideId: newGuideId,
              themesetId: variables.themesetId,
            });
          }
          handleMutationSuccess('Landing Page Created', data, {
            entityId: newGuideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            payload: {
              isCreatingLandingPage: true,
              newPageId,
            },
          });
        },
      },
    ),

    createTemplateLandingPage: useMutation(
      (mutationOptions: {
        pageTitle: string;
        pageTemplate: TemplatesEnum;
        isHomepage?: boolean;
        themesetId: string;
      }) => {
        const { editGuideAccess } = getCommand(newGuideId, 0);

        const guideTemplate = GuideTemplateBuilder(
          newGuideId,
          generateUUID(),
          newPageId,
          generateUUID(),
          newGuideVersion,
          mutationOptions.pageTemplate,
          mutationOptions.pageTitle,
          activeGroupId,
          mutationOptions.isHomepage,
        );

        const setVisibilityPortal = editGuideAccess({
          viewAccessChanges: getEmptyLegacyModificationGroup(),
          editAccessChanges: getEmptyLegacyModificationGroup(),
          guideVisibility: EntityVisibility.PORTAL,
        });

        return sendBatchCommandApi(
          newGuideId,
          [
            ...(guideTemplate || []),
            ...(mutationOptions.isHomepage ? [setVisibilityPortal] : []),
          ],
          newGuideVersion,
        );
      },

      {
        onMutate: () => {
          toggleIsCreatingLandingPage(true);
          closeModal();
        },
        onSuccess: (data, variables) => {
          if (variables.themesetId) {
            assignThemesetToGuideMutation.mutate({
              guideId: newGuideId,
              themesetId: variables.themesetId,
            });
          }
          handleMutationSuccess('Landing Page Created', data, {
            entityId: newGuideId,
            queryPath: OVERVIEW_KEYS.OVERVIEW_GUIDES,
            payload: {
              isCreatingLandingPage: true,
              newPageId,
            },
          });
        },
      },
    ),
  };
};
