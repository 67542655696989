import React from 'react';
import {
  Button,
  ModalBase,
  Flex,
  Table,
  Switch,
  Chip,
} from '@bynder/design-system';
import { useActiveGuide } from '../../queries/guides';
import {
  Identity,
  IdentityType,
  Page,
  PageStates,
  Permission,
  PermissionDocumentType,
} from '../../types';
import { usePageMutations } from '../../mutations/pages';
import { useIdentity } from '../../queries/identity';
import { AccessModalSearch } from './AccessModalSearch';
import { useEditAccess } from '../../mutations/permissions';
import styled from 'styled-components';
import { getIdentityIcons } from '../shared/Permissions';
import { IconInfo } from '@bynder/icons';
import { useHomepageMutations } from '../../mutations/homepage';

interface AudiencesModalProps {
  show: boolean;
  handleClose: () => void;
  onCancel: () => void;
}

const AudiencesModal: React.FC<AudiencesModalProps> = ({ onCancel }) => {
  const { data: activeGuide, isLoading } = useActiveGuide();
  const pages = activeGuide?.chapters?.[0]?.pages || [];

  if (isLoading || !activeGuide) {
    return null;
  }

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel} size="medium">
      <ModalBase.Header title="Manage targeting" />
      <ModalBase.Content>
        <Table columnWidths={['25%', '50%', '25%']}>
          <Table.Head>
            <Table.HeadCell>Page Name</Table.HeadCell>
            <Table.HeadCell>Access</Table.HeadCell>
            <Table.HeadCell>
              <Flex justifyContent="center">Publish</Flex>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {pages.map((page, index, col) => {
              return (
                <PageRow
                  guideTitle={activeGuide.title}
                  activeGuideId={activeGuide.id}
                  isActiveHomepage={activeGuide.isHomepage}
                  guideVersion={activeGuide.version}
                  isLast={index === col.length - 1}
                  page={page}
                  key={page.id}
                />
              );
            })}
          </Table.Body>
        </Table>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <Button
            variant="primary"
            data-testid="submitButton"
            onClick={onCancel}
          >
            OK
          </Button>
        }
        actionTertiary={
          <Button variant="clean" isDisabled icon={<IconInfo />}>
            All changes are saved automatically.
          </Button>
        }
      />
    </ModalBase>
  );
};

export default AudiencesModal;

const PageRow: React.FC<{
  page: Page;
  activeGuideId: string;
  guideTitle?: string;
  isLast: boolean;
  isActiveHomepage: boolean;
  guideVersion: number;
}> = ({
  page,
  activeGuideId,
  isLast,
  guideTitle,
  isActiveHomepage,
  guideVersion,
}) => {
  const { publishPage, unpublishPage } = usePageMutations(page.id);
  const { setHomepage, unsetHomepage } = useHomepageMutations();

  const editAccess = useEditAccess(
    page.id,
    activeGuideId,
    PermissionDocumentType.PAGE,
  );

  const handleTogglePublish = () => {
    if (page.state === PageStates.DRAFT) {
      publishPage.mutate();
    } else {
      unpublishPage.mutate();
    }
  };

  const editPageAccess = (newAccessList: Permission[]) => {
    editAccess.mutate({
      entityVisibility: page.visibility,
      identityList: newAccessList,
      closeModal: false,
    });
  };

  const handleDelete = (permissionId: string) => {
    const newList = page.permissions.filter(
      (item: Identity) => item.id !== permissionId,
    );

    editAccess.mutate({
      entityVisibility: page.visibility,
      identityList: newList,
      closeModal: false,
    });
  };

  const handleSetHomepage = () => {
    if (isActiveHomepage) {
      unsetHomepage.mutate({ guideId: activeGuideId, guideVersion });
    } else {
      setHomepage.mutate({ guideId: activeGuideId, guideVersion });
    }
  };

  return (
    <>
      <Table.Row>
        <Table.Cell subtext={isLast ? 'Default' : ''}>
          <Flex>{isLast && guideTitle ? guideTitle : page.title}</Flex>
        </Table.Cell>
        <Table.Cell>
          <AccessModalSearch
            searchFiltering={false}
            ownerId={page.ownerId!}
            identityList={page.permissions}
            onSelectIdentity={editPageAccess}
            isDisabled={isLast}
          />
        </Table.Cell>
        <Table.Cell>
          <Flex justifyContent="center">
            {isLast ? (
              <Switch
                isChecked={isActiveHomepage}
                onChange={handleSetHomepage}
              />
            ) : (
              <Switch
                isChecked={page.state === PageStates.PUBLISHED}
                onChange={handleTogglePublish}
              />
            )}
          </Flex>
        </Table.Cell>
      </Table.Row>
      {!!page.permissions.length && (
        <tr>
          <Table.Cell />
          <Table.Cell>
            <StyledChipContainer>
              {page.permissions.map(permission => (
                <UserChip
                  key={permission.id}
                  identity={permission}
                  onDelete={handleDelete}
                />
              ))}
            </StyledChipContainer>
          </Table.Cell>
          <Table.Cell />
        </tr>
      )}
    </>
  );
};

const UserChip: React.FC<{
  identity: Permission;
  onDelete: (identityId: string) => void;
}> = ({ identity, onDelete }) => {
  const { data: user, isLoading: isLoading } = useIdentity(
    identity.id,
    identity.identity,
  );

  if (isLoading) {
    return null;
  }

  const onDeleteFn = () => {
    onDelete(identity.id);
  };

  return (
    <Chip
      icon={getIdentityIcons(identity.identity)}
      onDelete={onDeleteFn}
      size="m"
    >
      {identity.identity === IdentityType.USER ? user?.full_name : user?.name}
    </Chip>
  );
};

const StyledChipContainer = styled.div`
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  overflow: hidden;
`;
