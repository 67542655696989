import { useMutation } from '@tanstack/react-query';
import { useGuideStore } from '../../stores/guideStore';
import {
  handleMutationSuccess,
  optimisticallyUpdateWidget,
  updateGuideVersion,
} from '../helpers';
import {
  ButtonWidget,
  ButtonWidgetButton,
  Page,
  WIDGET_TYPE,
} from '../../types';
import {
  addNewButtonToButonWidgetApi,
  editNameInButtonApi,
  editLinkInButtonApi,
  editStyleInButtonApi,
  removeButtonFromButtonWidgetApi,
  editWidgetStyleInButtonWidgetApi,
} from '../../helpers/api/widgets/button';
import { useInspectorStore } from '../../stores/inspectorStore';
import { GUIDE_KEYS, useActiveWidget } from '../../queries/guides';
import { queryClient } from '../../constants/app';
import { findIndex } from 'lodash';
import { produce } from 'immer';
import { emptyCSSObject } from '../../constants/widgets';

export const useButtonWidgetMutations = () => {
  const guideId = useGuideStore(s => s.activeGuideId) as string;
  const pageId = useGuideStore(s => s.activePageId) as string;
  const chapterId = useGuideStore(s => s.activeChapterId) as string;
  const guideVersion = useGuideStore(s => s.activeGuideVersion) as number;
  const sectionId = useGuideStore(s => s.activeSectionId) as string;
  const columnIndex = useInspectorStore(s => s.widgetSettings?.columnIndex) as number;
  const widgetId = useInspectorStore(s => s.widgetSettings?.widgetId) as string;
  const activeWidget = useActiveWidget() as ButtonWidget;
  const setActiveWidgetIndex = useInspectorStore(s => s.setWidgetSettings);

  return {
    addNewButtonToButtonWidget: useMutation(
      ({ newButton }: { newButton: ButtonWidgetButton }) => {
        return addNewButtonToButonWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId,
          columnIndex,
          guideVersion,
          newButton,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId!);
          const lastIndex = activeWidget?.buttons.length;
          setActiveWidgetIndex({ widgetIndex: lastIndex });
          optimisticallyAddButtonToButtonWidget({
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            newButton: variables.newButton,
          });
        },
        onSuccess: response => {
          handleMutationSuccess('Button added to button Widget', response, {
            entityId: guideId!,
          });
        },
      },
    ),
    editName: useMutation(
      ({ title, id }: { title: string; id: string; buttonIndex: number }) => {
        return editNameInButtonApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          title,
          buttonId: id,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateButtonInButtonWidget({
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            buttonIndex: variables.buttonIndex,
            value: { name: { text: variables.title } },
          });
        },
        onSuccess: data => {
          handleMutationSuccess('Button widget edited', data, {
            entityId: guideId,
          });
        },
      },
    ),
    editLink: useMutation(
      ({
        link,
        openNewTab,
        buttonId,
      }: {
        link: string;
        openNewTab: boolean;
        buttonId: string;
        buttonIndex: number;
      }) => {
        return editLinkInButtonApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          buttonId,
          link: {
            url: link,
            openNewTab,
          },
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateButtonInButtonWidget({
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            buttonIndex: variables.buttonIndex,
            value: {
              link: { url: variables.link, openNewTab: variables.openNewTab },
            },
          });
        },
        onSuccess: data => {
          handleMutationSuccess('Button widget edited', data, {
            entityId: guideId,
          });
        },
      },
    ),
    editStyle: useMutation(
      ({
        style,
        buttonId,
      }: {
        style: string;
        buttonId: string;
        buttonIndex: number;
      }) => {
        return editStyleInButtonApi({
          guideId,
          guideVersion,
          chapterId,
          pageId,
          sectionId,
          columnIndex,
          widgetId,
          buttonId,
          style,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateButtonInButtonWidget({
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            buttonIndex: variables.buttonIndex,
            value: {
              style: { stylesheet: variables.style },
            },
          });
        },
        onSuccess: data => {
          handleMutationSuccess('Button widget edited', data, {
            entityId: guideId,
          });
        },
      },
    ),
    removeButton: useMutation(
      ({ buttonId }: { buttonId: string; buttonIndex: number }) => {
        return removeButtonFromButtonWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId,
          columnIndex,
          guideVersion,
          buttonId,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyRemoveCardFromCardWidget({
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            buttonIndex: variables.buttonIndex,
          });
        },
        onSuccess: data => {
          handleMutationSuccess('Button widget edited', data, {
            entityId: guideId,
          });
        },
      },
    ),
    // moveButton: useMutation(
    //   ({
    //     from,
    //     to,
    //     buttonId,
    //   }: {
    //     from: number;
    //     to: number;
    //     buttonId: string;
    //   }) => {
    //     return moveButtonInButtonWidgetApi({
    //       guideId,
    //       chapterId,
    //       pageId,
    //       sectionId,
    //       widgetId,
    //       columnIndex,
    //       guideVersion,
    //       from,
    //       to,
    //       buttonId,
    //     });
    //   },
    //   {
    //     onMutate: variables => {
    //       updateGuideVersion(guideId);
    //       optimisticallyMoveButtonInButtonWidget({
    //         guideId,
    //         chapterId,
    //         pageId,
    //         sectionId,
    //         columnIndex,
    //         widgetId,
    //         from: variables.from,
    //         to: variables.to,
    //       });
    //     },
    //     onSuccess: data => {
    //       handleMutationSuccess('Button widget edited', data, {
    //         entityId: guideId,
    //       });
    //     },
    //   },
    // ),

    editWidgetStyleInButtonWidget: useMutation(
      ({ style }: { style: string }) => {
        return editWidgetStyleInButtonWidgetApi({
          guideId,
          chapterId,
          pageId,
          sectionId,
          widgetId: widgetId,
          columnIndex,
          guideVersion,
          style,
        });
      },
      {
        onMutate: variables => {
          updateGuideVersion(guideId);
          optimisticallyUpdateWidget(
            guideId,
            pageId,
            sectionId,
            columnIndex,
            widgetId,
            WIDGET_TYPE.BUTTON,
            { style: { stylesheet: variables.style } },
          );
        },
        onSuccess: data => {
          handleMutationSuccess('Button widget edited', data, {
            entityId: guideId,
          });
        },
      },
    ),
  };
};

const optimisticallyUpdateButtonInButtonWidget = ({
  guideId,
  pageId,
  sectionId,
  columnIndex,
  widgetId,
  buttonIndex,
  value,
}: {
  guideId: string;
  pageId: string;
  sectionId: string;
  columnIndex: number;
  widgetId: string;
  buttonIndex: number;
  value: { [key: string]: unknown };
}) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const buttonWidget = draft.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as ButtonWidget;
          const button = buttonWidget.buttons[buttonIndex];

          buttonWidget.buttons.splice(buttonIndex, 1, { ...button, ...value });
        });
      }
    },
  );
};

const optimisticallyAddButtonToButtonWidget = ({
  guideId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  newButton,
}: {
  guideId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;

  newButton: ButtonWidgetButton;
}) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const widget = old.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as ButtonWidget;
          const newWidget = {
            ...widget,
            buttons: [...widget.buttons, newButton],
          };
          draft.sections[sectionIndex].columns[columnIndex].widgets[
            widgetIndex
          ] = {
            ...newWidget,
          };
        });
      }
    },
  );
};

const optimisticallyRemoveCardFromCardWidget = ({
  guideId,
  pageId,
  sectionId,
  widgetId,
  columnIndex,
  buttonIndex,
}: {
  guideId: string;
  pageId: string;
  sectionId: string;
  widgetId: string;
  columnIndex: number;
  buttonIndex: number;
}) => {
  queryClient.setQueryData(
    [GUIDE_KEYS.PAGE_BY_ID, guideId, pageId],
    (old: Page) => {
      const sectionIndex = findIndex(
        old.sections,
        section => section.id === sectionId,
      );

      const widgetIndex = findIndex(
        old.sections[sectionIndex].columns[columnIndex].widgets,
        widget => widget.id === widgetId,
      );

      if (old) {
        return produce(old, draft => {
          const widget = old.sections[sectionIndex].columns[columnIndex]
            .widgets[widgetIndex] as ButtonWidget;

          widget.buttons.splice(buttonIndex, 1);

          draft.sections[sectionIndex].columns[columnIndex].widgets[
            widgetIndex
          ] = {
            ...widget,
          };
        });
      }
    },
  );
};

//TODO: Implement this function
// const optimisticallyMoveButtonInButtonWidget = ({
//   guideId,
//   chapterId,
//   pageId,
//   sectionId,
//   widgetId,
//   columnIndex,
//   from,
//   to,
// }: {
//   guideId: string;
//   chapterId: string;
//   pageId: string;
//   sectionId: string;
//   widgetId: string;
//   columnIndex: number;
//   from: number;
//   to: number;
// }) => {};

export const getNewButton = (id: string): ButtonWidgetButton => ({
  id,
  name: {
    text: '',
  },
  link: {
    url: '',
    openNewTab: false,
  },
  style: emptyCSSObject,
});
