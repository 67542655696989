import ColorPic from './RichTextColorPicker';
import FormatAlignCenter from '../../../assets/icons/format_align_center_black_24dp.svg?raw';
import FormatAlignJustify from '../../../assets/icons/format_align_justify_black_24dp.svg?raw';
import FormatAlignLeft from '../../../assets/icons/format_align_left_black_24dp.svg?raw';
import FormatAlignRight from '../../../assets/icons/format_align_right_black_24dp.svg?raw';
import FormatBold from '../../../assets/icons/format_bold_black_24dp.svg?raw';
import FormatItalic from '../../../assets/icons/format_italic_black_24dp.svg?raw';
import FormatListBulleted from '../../../assets/icons/format_list_bulleted_black_24dp.svg?raw';
import FormatListNumbered from '../../../assets/icons/format_list_numbered_black_24dp.svg?raw';
import FormatUnderlined from '../../../assets/icons/format_underlined_black_24dp.svg?raw';
import InsertLink from '../../../assets/icons/insert_link_black_24dp.svg?raw';
import LinkOff from '../../../assets/baseline-link_off-24px.svg?raw'; // for some reason not available in the npm module
import TypographyPicker from './TypographyPicker';
import { Color } from '../../../types/color';
import { svgtob64 } from '../../../helpers/utils';
import { token } from '@bynder/design-system';

const allowedProtocols = '(http|https|mailto).*';

export const defaultWhite: Color = {
  id: 'white',
  hexCode: '#FFFFFF',
  creationTime: '',
  lastModifiedTime: '',
  label: 'Default White',
};

export const defaultTransparent: Color = {
  id: 'transparent',
  hexCode: '#00000000',
  creationTime: '',
  lastModifiedTime: '',
  label: 'Transparent',
};

export const defaultTextColor: Color = {
  id: 'textColor',
  hexCode: token.gray800,
  creationTime: '',
  lastModifiedTime: '',
  label: 'Default Text',
};

export const defaultToolbarOptions = (hasColors: boolean) => {
  return {
    options: hasColors
      ? ['blockType', 'colorPicker', 'inline', 'list', 'textAlign', 'link']
      : ['blockType', 'inline', 'list', 'textAlign', 'link'],
    blockType: {
      component: TypographyPicker,
    },
    colorPicker: {
      component: ColorPic,
      inDropdown: true,
    },
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline'],
      bold: { icon: svgtob64(FormatBold) },
      italic: { icon: svgtob64(FormatItalic) },
      underline: { icon: svgtob64(FormatUnderlined) },
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered'],
      unordered: { icon: svgtob64(FormatListBulleted) },
      ordered: { icon: svgtob64(FormatListNumbered) },
    },
    textAlign: {
      inDropdown: false,
      options: ['left', 'center', 'right', 'justify'],
      left: { icon: svgtob64(FormatAlignLeft) },
      right: { icon: svgtob64(FormatAlignRight) },
      center: { icon: svgtob64(FormatAlignCenter) },
      justify: { icon: svgtob64(FormatAlignJustify) },
    },
    link: {
      inDropdown: false,
      showOpenOptionOnHover: false,
      defaultTargetOption: '_blank',
      options: ['link', 'unlink'],
      link: { icon: svgtob64(InsertLink) },
      unlink: { icon: svgtob64(LinkOff) },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      linkCallback: (params: any) => ({
        ...params,
        target: params.target.match(allowedProtocols)
          ? params.target
          : 'about:blank',
      }),
    },
  };
};
